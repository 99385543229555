import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, CircularProgress, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { memo, useState } from "react";
import MoveCandidate from "../../shared/components/MoveCandidate/MoveCandidate";
import Timeline from "../../shared/components/Timeline/Timeline";
import { usePermissions } from "../../utils/permission";
import CandidateExpeience from "./CandidateExpeience";
import CandidateResume from "./CandidateResume";
import { styles } from "./HireStyle";
import ViewCandidateWorkExperience from "./ViewCandidateWorkExperience";


const CandidateInfo = ({
    candidateId,
    candidateDetail,
    setShowCommentBox,
    showComment,
    setShowComment,
    showCommentBox,
    selectedStage,
    setSelectedStage,
    selectedCandidate,
    candidateDetailPending,
    cvDetails,
    isNotApplied
}) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const {Hire_EditCandidateStage,Hire_AddCandidateActivitycomment} = usePermissions()

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const candidateDetails = (candidate) => {
        return {
            fileName: "Download Resume",
            file: candidate?.resumeURL,
            joiningDate: candidate?.expectedJoiningDate,
            salaryPA: candidate?.salary,
            experience: candidate?.experienceLevel,
            jobTitle: candidate?.jobTitle,
        };
    };
    const classes = styles()

    return (
        <Grid container item xs={12} md={8}>
            <Box width={'100%'}
                sx={{
                    display: "flex",
                    backgroundColor: "#fffaea",
                    justifyContent: "space-between",
                }}>
                <Box
                    sx={{
                        display: "flex",
                        backgroundColor: "#fffaea",
                        justifyContent: "flex-start",
                        alignItems: 'center',
                        px: '1rem'
                    }}

                    gap={"1.5rem"}
                    height={'56px'}>

                    <Box>
                        <Typography variant="h6" component="div">
                            {selectedCandidate?.candidate?.label}
                        </Typography>
                        <Box
                            sx={{ display: "flex" }}
                            justifyContent={"space-between"}
                            alignContent={"space-between"}>
                            {isNotApplied && <Typography variant="caption" sx={{ mr: 4 }}>
                                {candidateDetail?.jobTitle}
                            </Typography>}
                            {candidateDetail?.expectedJoiningDate && (
                                <Typography variant="caption">
                                    {moment(candidateDetail?.expectedJoiningDate).format(
                                        "DD-MM-YYYY"
                                    )}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box alignItems={'center'} display={'flex'} gap={1}>
                    <Box position={'relative'}>

                        <CircularProgress value={selectedCandidate?.copilotReview.overall_relevancy_score} variant="determinate" thickness={1} />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant="caption"
                                component="div"
                            >{selectedCandidate?.copilotReview.overall_relevancy_score}%</Typography>
                        </Box>
                    </Box>

                    <Typography fontSize={'12px'}>Match</Typography>
                </Box>
                <Box display={"flex"}>
                    {candidateDetail?.disqualified ? (
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={classes.disqualifiedCon}>
                            <Typography
                                variant="h6"
                                className={classes.disqualifiedText}>
                                {"Disqualified"}
                            </Typography>
                            <IconButton
                                title="reverted"
                                disableRipple={true}
                                onClick={(e) => {
                                    e.stopPropagation();
                                }}>
                                <img
                                    className={classes.timelineIcon}
                                    src="/images/reverted.png"
                                    alt="icon"
                                />
                            </IconButton>
                            <IconButton
                                title="comments"
                                disableRipple={true}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowCommentBox(true);
                                }}>
                                <img
                                    className={classes.timelineIcon}
                                    src="/images/comment.png"
                                    alt="icon"
                                />
                            </IconButton>
                        </Box>
                    ) : (
                        <Box
                            display={"flex"}
                            alignItems={"center"}
                            className={classes.disqualifiedCon}>

                            {Hire_AddCandidateActivitycomment ? <IconButton
                                title="comments"
                                disableRipple={true}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowCommentBox(true);
                                }}>
                                <img
                                    className={classes.qualifiedTimelineIcon}
                                    src="/images/comment.png"
                                    alt="icon"
                                />
                            </IconButton> : null}
                           {Hire_EditCandidateStage ?  <IconButton
                                disableRipple={true}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleClick(e);
                                }}>
                                <MoreVertIcon />
                            </IconButton>: null}
                        </Box>
                    )}
                </Box>
            </Box>

            <Box width={'100%'} height={'calc(100vh - 350px)'} overflow={'auto'}>


                <MoveCandidate
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    selectedStage={selectedStage}
                    selectedCandidate={selectedCandidate}
                    setSelectedStage={setSelectedStage}
                    showCommentBox={showCommentBox}
                    setShowCommentBox={setShowCommentBox}></MoveCandidate>

                {isNotApplied && selectedCandidate && selectedCandidate?.status?.name?.toLowerCase() !== 'application' && <Timeline
                    showComment={showComment}
                    setShowComment={setShowComment}
                    selectedCandidate={selectedCandidate} />}


                {isNotApplied && <ViewCandidateWorkExperience
                    headerColor={"#ffffff"}
                    details={candidateDetails(candidateDetail)}
                    viewSkills={candidateDetail?.skills?.join(', ')}
                    isMemLoading={candidateDetailPending}
                />}

                {isNotApplied && <CandidateExpeience
                    headerColor={"#ffffff"}
                    details={candidateDetails(candidateDetail)}
                    isMemLoading={candidateDetailPending}
                    experience={candidateDetail?.employmentHistory}
                    totalExperience={candidateDetail?.yearsOfExperience}
                />}
                {isNotApplied && <CandidateResume headerColor={'#fff'} html={cvDetails?.html} />}
            </Box>

        </Grid>
    )
}

export default memo(CandidateInfo)