/**
 * params
 * @param {ParamsType} params parameters to be used in string replacement. it can be object of key value pair or an array of strings
 * @returns {string} string with replaced parameter which can used to display on screen.
 */

type ParamsType =
  | {
      [key: string]: string;
    }
  | string[];

declare global {
  interface String {
    params: (params?: ParamsType) => string;
  }
}

// eslint-disable-next-line
String.prototype.params = function (opts?: ParamsType) {
  if (!opts) {
    return this.toString();
  }
  return this.replace(/{([^{}]+)}/g, function (keyExpr, key) {
    return opts[key] || "";
  });
};

export const STRINGS = {
  verificationToEmail:
    "A verification mail has been sent to your Email account",
  linkToEmail:
    "Please click on the link which is just being sent to your email account to verify your email and continue the registration Process.The link will be valid for {hours} hours.",
  createNew: "Create a new account",
  SignUpNow: "Sign up now",
  ReadyToBuild: "Ready to build your Teamified team?",
  LogIn: "Log in",
  ForgotPassword: "Forgot password?",
  LogInEmail: "Log in using email address",
  ContinueBuildingTeam: "Continue Building Your Team",
  AlreadyRegistered: "Already Registered",
  LinkExpired: "Link Expired",
  RememberYourDetails: "If you remember your details you can login here",
  ResendVerificationLink: "Resend Verification Link",
  TeamifiedVerificationLink: "Teamified will resend the verification link",
  EnterEmailAddress: "Enter the email address associated with your account",
  EnterCompanyName: "Enter Your Company Name",
  IsActive: "Is active",
  CompanyDetails: "Company details",
  Client: 'Client',
  ListClients: "List of Clients",
  OpenModal: "Open Modal",
  AddNewClient: "Add new client",
  AddMemberList: "Add new member to your list",
  ListofCandidates: "List of candidates",
  ListOfMember: "List of team members",
  ClientUsers: "Client Users",
  PatonaUsers: "Teamified Users",
  TeamMember:"Team Member",
  CompanyLogo: "Company Logo",
  CompanyName: "Company name",
  ClientCode: "Client code",
  CompanyAddress: "Company address",
  Address: "Address",
  PresentAddress: "Present Address",
  PermanentAddress: "Permanent Address",
  Language: "Language",
  LinkedIn: "LinkedIn",
  Website: "Website",
  ACN: "ACN",
  AboutCompany: "About your company",
  Edit: "Edit",
  Country: "Country",
  State: "State",
  City: "City",
  PostalCode: "Postal Code",
  CompanyWebsite: "Company website",
  Cancel: "Cancel",
  Update: "Update",
  IndividualDetails: "Individual details",
  NewInvite: "Send new invitation",
  FirstName: "First Name",
  LastName: "Last Name",
  Email: "Email",
  Role: "Role",
  SaveAndInvite: "Save and send invite",
  ChangePassword: "Change Password",
  PasswordSuggestion: "Password Suggestion",
  PasswordSuggestion1: "Passwords must be at least 8 characters",
  PasswordSuggestion2: "Password must contain at least 1 lower case letter",
  PasswordSuggestion3: "Password must contain at least 1 upper case letter",
  PasswordSuggestion4: "Password must contain at least 1 number",
  PasswordSuggestion5: "Password must contain at least 1 special character",
  CurrentPassword: "Current password*",
  NewPassword: "New password*",
  ConfirmNewPassword: "Confirm new password*",
  ProfilePicture: "Profile picture",
  ImageSize: "Image size 100px 100px",
  Upload: "Upload",
  Owner: 'Owner',
  OwnerFirstName: "Owner first name*",
  OwnerLastName: "Owner last name*",
  PhoneNumber: "Phone Number",
  TimeZone: "Time zone",
  Designation: "Designation",
  Name: "Name",
  ListUsers: "List of users",
  SendNewInvite: "Send new invitation",
  EmailAddress: "Email Address",
  ResendInvite: "Resend Invite",
  Logo: "Logo",
  Save: "Save",
  AddClient: "Add Client",
  Candidates: "Candidates",
  Jobs: "Jobs",
  Satge: "Satge",
  HRName: "HR name",
  Action: "Action",
  Welcome: "Welcome {user} to Teamified",
  GetStarted:
    "To get started please provide your company and personal information. It will be used for your documents on Teamified.",
  Next: "Next",
  Clients: "Clients",
  Job: "Job",
  Location: "Location",
  Month: "Month",
  Months: "Months",
  ResetFilters: "Reset Filters",
  CandidateActivity: "Candidate’s Activity",
  OpenJobRequests: "Open Job Requests",
  Activity: "Activity",
  TotalNewRequest: "Job Requests",
  TotalClients: "Clients",
  TotalMembers: "Team Members",
  TotalNewJoinees: "New Joinees",
  WorkExperience: "Work Experience",
  WorkInformation: "Work information",
  JobTitle: "Job title",
  ExperienceLevel: "Experience level",
  Salary: "Salary",
  JoiningDate: "Joining date",
  Skills: "Skills",
  Resume: "Resume",
  RequestSummary: "Request Summary",
  NewRequest: "New request",
  RequestType: "Request type",
  DetailsOfRequest: "Details of request",
  RequestDate: "Request date:        {date}",
  ExpectedJoiningDate: "Expected joining date:        {date}",
  RequestBy: "Request by     {status}",
  PublishOnWorkable: "Publish on workable date:       {date}",
  RequestNumber: "Request no: {number}",
  dateRequested: `Request date -  {date}`,
  ExpectedjJoiningDate: "Expected joining date",
  ExpectedSalaryRange: "Expected salary range",
  NumberOfCandidates: "No of candidate required",
  Experience: "Experience level",
  EmploymentType: "Employment type",
  OtherRequirements: "Other requirements",
  AddNewMember: "Add new member",
  PersonalInformation: "Personal information",
  Image: "Image",
  PersonalNumber: "Personal Number",
  PersonalEmailId: "Personal Email Id",
  DateOfBirth: "Date of birth",
  MaritalStatus: "Marital status",
  WorkEmailId: "Work Email id",
  EmployeeId: "Employee id",
  Remove: "Remove",
  RemoveTeamMember: "Remove team member",
  ConfirmRemoveMember:
    "Are you sure you want to remove a team member from the list?",
  AssignToClient: "Assign to client",
  CurrentTeamMembers: "Current team members({count})",
  RemovedTeamMembers: "Removed team members({count})",
  LoadMore: "Load More",
  Company: "Company",
  SelectRole: "Select Role",
  AllAccess: "All access",
  SaveAndSendInvite: "Save and send invite",
  InvitationSent: "Your invite has been sent",
  EmailSentTo: "Your email has been send to",
  PleaseCheckAccount: "Please check your account",
  OK: "OK",
  NoRecordFound: "No Record found",
  clientSearchPlaceHolder: "Search by company name, name or location",
  hireSearchPlaceHolder: "Search by clients, job title or location",
  employeeType: "Employee type",
  MemberUpdateSuccess: "Member updated successfully",
  MemberAddSuccess: "Member added successfully",
  ErrorOnUpdte: "Error while updating the data. Please try after some time.",
  HireNow: "Hire now",
  CreatedAt: 'Created At',
  CreatingJob: "Creating new job request",
  ExpectedSalaryFrom: "Expected salary from",
  ExpectedSalaryTo: "Expected salary to",
  ExpectedJoiningDateLabel: "Expected Joining date",
  AboutTheRole: "About the role",
  OtherRequirement: "Other requirement",
  JobRequestAddSuccess: "New Job request posted successfully",
  JobRequestUpdateSuccess: "Job request has been updated successfully",
  RestoreJobRequestAddSuccess: "Restore Job request successfully",
  JobTitleDetails: "Job title and other details",
  CandidatesJoined: "Candidates joined",
  JobExperience: "Experience",
  ExpSalary: "Exp. Salary",
  ExpJoiningDate: "Exp. Joining date",
  Attachment: "Attachment",
  YetToPublish: "Yet to publish in workable",
  EditJob: "Edit job request",
  RowsPerPage: "Rows per page:",
  Timeline: "Timeline",
  AddComment: "Add a comment",
  ErrorOnComments:
    "Error while adding the comment. Please try after some time.",
  ErrorInMovingCandidate: "Error while move the candidate. Please try again.",
  CandidateMoved: "Candidate has moved successfully",
  ErrorInDisQualifiedCandidate:
    "Error while disqualified the candidate. Please try again.",
  CandidateDisQualified: "Candidate has been disqualified successfully",
  NoRecords: "No records found",
  EmergencyContact: "Emergency Contact Details",
  EmploymentHistory: "Employment history",
  Download: "Download",
  Users: "Users",
  Phone: "Phone",
  Requestraisedby: 'Request raised by',
  ApplyJob:'Apply now',
  Refer:'Refer now',
  Submit:'Submit',
  Filter:'Filter',
  ApplyLeave:'Apply Leave',
  TotalLeave:'Total Leave',
  AvailableLeave:"Available Leave",
  Session:"Session*"
};
