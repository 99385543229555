import { Grid, Typography } from "@mui/material"
import { memo, useCallback, useMemo, useState } from "react"
import CompleteTaskSubpage from "./CompleteTaskSubpage"
import DeleteTaskSubPage from "./DeleteTaskSubPage"
import TaskCommentsPage from "./TaskCommentsPage"
import TaskDetailsPage from "./TaskDetailsPage"
import TaskInfoSubpage from "./TaskInfoSubpage"


export type Action = {
    title: string
    onClick: () => void
    disabled?: boolean
    color?: 'primary' | 'secondary' | 'error' | 'warning'
}

const TaskAccordionDetails = ({ task }) => {
    const [page, setPage] = useState<number>(0)
    const actions: Array<Action> = useMemo(() => {
        return task.completedAction ?
            [
                { title: 'Comments', onClick: () => setPage(0), disabled: page === 0 },
                { title: 'View task details', onClick: () => setPage(1), disabled: page === 1 },
                { title: 'Delete', onClick: () => setPage(3), disabled: page === 3, color: 'error' }
            ] :
            [
                { title: 'Comments', onClick: () => setPage(0), disabled: page === 0 },
                { title: 'Edit Task', onClick: () => setPage(1), disabled: page === 1 },
                { title: 'Complete task', onClick: () => setPage(2), disabled: page === 2 },
                { title: 'Delete', onClick: () => setPage(3), disabled: page === 3, color: 'error' }


            ]
    }, [page, task])



    const goToComment = useCallback(()=>{
        setPage(0)
    },[])
    return <Grid>
        <TaskDetailsPage title={
            <>
                {page === 0 && <Grid container gap={2} alignItems={'center'}>
                    <Typography>Comments</Typography>
                </Grid>}
                {page === 1 && <Typography>Edit Task</Typography>}
                {page === 2 && <Typography>Complete task</Typography>}
                {page === 3 && <Typography>Delete task</Typography>}

            </>
        } actions={actions}>
            {page === 0 && <TaskCommentsPage taskId={task.id} />}
            {page === 1 && <TaskInfoSubpage task={task} ></TaskInfoSubpage>}
            {page === 2 && <CompleteTaskSubpage task={task} goToComment={goToComment}></CompleteTaskSubpage>}
            {page === 3 && <DeleteTaskSubPage task={task}></DeleteTaskSubPage>}
        </TaskDetailsPage>
    </Grid>
}

export default memo(TaskAccordionDetails)