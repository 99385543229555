import { memo, useCallback, useState } from "react"
import Layout from "../../shared/components/LayoutV2"
import { usePermissions } from "../../utils/permission"
import AllTaskList from "./AllTaskList"
import CreatedTasks from "./CreatedTasks"
import MyTaskList from "./MyTaskList"
import TasksHeader from "./TasksHeader"
import TaskSubheader from "./TaskSubHeader"




const TasksContainer = () => {
    const {Task_ListAllTask} = usePermissions()
    const [tabValue, setTabValue] = useState(0)   

    const [filters,setFilters] = useState<Record<string, string>>({})
    const [searchText, setSearchText]  = useState<string>('')

    const setFilterObject = useCallback((option: Record<string,string>) => {
        setFilters(prev => ({
            ...prev,
            ...option,
        }))
    },[])

    return <Layout
        header={<TasksHeader filters={filters} setFilters={setFilterObject} setSearchText={setSearchText}></TasksHeader>}
        subHeader={
            <TaskSubheader
                handleTabChange={(_, value) => {
                    setTabValue(value)
                }}
                tabValue={tabValue} />
        }
        body={
            <>
                <CreatedTasks tabValue={tabValue} filters={filters} searchText={searchText} />
                <MyTaskList tabValue={tabValue} filters={filters} searchText={searchText}/>
                {Task_ListAllTask && <AllTaskList tabValue={tabValue} filters={filters}  searchText={searchText}/>}
            </>
           
        }
    />



}


export default memo(TasksContainer)