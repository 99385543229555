import {
  Avatar,
  Box,
  Button,
  CardHeader,
  Grid,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { userAccess } from "../../../utils/CommonFunction";
import useGeocoding from "../../Attendance/useGeocoding";
import { addPunchInPuchOut } from "../../Attendance/attendance.helper";

const TeammemberDashboardHeader = () => {
  const [punchOutTime, setPunchOutTime] = useState<string>();
  const [punchInTime, setPunchInTime] = useState<string>();
  const [totalTime, setTotalTime] = useState(moment.duration());
  const [userLocation, setUserLocation] = useState();
  const dispatch = useDispatch();
  const { isLoading, user } = useSelector(
    //@ts-ignore
    (state) => state.user
  );
  const { companyName, empId, firstName, lastName, email } = userAccess();

  const { attendanceList } = useSelector(
    //@ts-ignore
    (state) => state.attendance
  );

  const startTime = useMemo(() => {
    return punchInTime
      ? moment(punchInTime, "hh:mm A").set({
          second: 0,
          millisecond: 0,
        })
      : null;
  }, [punchInTime]);

  useEffect(() => {
    const day = moment();
    let punchTime;
    Object.values(attendanceList).forEach((item: any) => {
      if (moment(item.firstIn, "DD-MM-YYYY").isSame(day, "day")) {
        const [hours, minutes] = item.totalHours.split(":").map(Number);
        const totalMinutes = hours * 60 + minutes;
        punchTime = moment(item.firstIn, "DD-MM-YYYY hh:mm A");
        if (totalMinutes > 0) {
          setPunchOutTime(item.totalHours);
        }
      }
    });
    if (punchTime) {
      //@ts-ignore
      setPunchInTime(moment(punchTime).format("hh:mm A"));
    }
  }, [attendanceList, punchOutTime]);

  useEffect(() => {
    if (!startTime) return;
    const updateTotalTime = () => {
      const now = moment();
      const duration = moment.duration(now.diff(startTime));
      setTotalTime(duration);
    };
    const intervalId = setInterval(updateTotalTime, 1000);
    return () => clearInterval(intervalId);
  }, [startTime]);

  const hours = useMemo(() => {
    const h = Math.floor(totalTime.asHours());
    return Math.max(h, 0);
  }, [totalTime]);

  const minutes = useMemo(() => {
    const m = Math.floor(totalTime.asMinutes() % 60);
    return Math.max(m, 0);
  }, [totalTime]);

  const progressPercent = useMemo(() => {
    const totalHours = hours + minutes / 60;
    const percent = (totalHours / 11) * 100;
    return Math.min(Math.floor(percent), 100);
  }, [hours, minutes]);

  const progressTime = useMemo(() => {
    if (punchOutTime) {
      return punchOutTime;
    }
    return `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
  }, [punchOutTime, hours, minutes]);

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          //@ts-ignore
          setUserLocation({ latitude, longitude });
        },
        (error) => {
          console.error("Error getting user location: ", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  }, []);

  useEffect(() => {
    getUserLocation();
  }, [getUserLocation]);

  const location = useGeocoding(
    //@ts-ignore
    userLocation?.latitude,
    //@ts-ignore
    userLocation?.longitude
  );

  const handlePunchInOut = () => {
    let currentTime = moment().format("DD-MMM-YYYY HH:mm:ss");
    let testObj = {};
    if (punchInTime) {
      testObj = {
        checkOut: currentTime,
        emailId: email,
        location: `${location?.county}, ${location?.city}, ${location?.state}`,
      };
      setPunchOutTime(currentTime);
    } else {
      testObj = {
        checkIn: currentTime,
        emailId: email,
        location: `${location?.county}, ${location?.city}, ${location?.state}`,
      };
      setPunchInTime(currentTime);
    }
    addPunchInPuchOut(dispatch, testObj);
  };

  return (
    <Grid
      item
      sm={12}
      xs={12}
      display="flex"
      justifyContent="space-between"
      mt={2}
    >
      <Grid sm={7.8} xs={12}>
        <Box
          sx={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <CardHeader
            sx={{ height: "10px" }}
            avatar={
              <Avatar
                sx={{ height: 85, width: 85, mb: 4 }}
                aria-label="recipe"
                alt={`${firstName}-${lastName}`}
                src={user?.profilePicture}
              />
            }
            title={`${companyName}-${empId}-${firstName} ${lastName}`}
            subheader={`${user.designation}`}
          />
          <Box display="flex" alignItems="center" mr={2}>
            {!punchOutTime && (
              <Typography fontSize="20px" fontWeight="600" width={80}>
                {progressTime}
              </Typography>
            )}
            {!punchOutTime && (
              <Button
                variant="outlined"
                color={punchInTime ? "error" : "success"}
                onClick={handlePunchInOut}
              >
                {punchInTime ? "Check out" : "Check in"}
              </Button>
            )}
          </Box>
        </Box>
      </Grid>

      <Grid sm={4.05} xs={12}>
        <Box sx={{ backgroundColor: "white" }}>
          <CardHeader
            sx={{ height: "10px" }}
            avatar={<Avatar aria-label="recipe">R</Avatar>}
            title={`${companyName}-${empId}`}
            subheader="Present today"
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default TeammemberDashboardHeader;
