import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../../services/service";

const BASE_URL_ONBOARDING = process.env.REACT_APP_ONBAORDING_URL


type GetUsersRequest = {
    pageNumber: number
    pageSize: number
    clientId: number
    searchValue: string
    roles: Array<string>
    disabled: boolean
}


type User = {
    userId: string
    tokenId: string
    userName: string
    email: string
    firstName: string
    lastName: string
    companyName: string
    companyId: number
    role: string
    country: string
    isRegistered: boolean
    isActive: boolean
    isInvitationNotAccepted: boolean
    isInvitationExpired: boolean
}

type UserProfile = {
    address: null
    companyId: number
    country: null | string
    designation: null | string
    firstName: null | string
    lastName: null | string
    phoneNumber: null | string
    profilePicture: null | string
    profilePictureFile: null | string
    role: string
    timeZone: null | string
    userId: string
}
type GetUsersResponse = {
    data: {
        paginationFilter: {
            pageNumber: number, pageSize: number, count: number
        }
        users: Array<User>
    }
    message: string
    status: number
    successful: boolean
}

type GetUserRequest = {
    userId: string
}

type GetUserResponse = {
    data: UserProfile
    message: string
    status: number
    successful: boolean
}

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: axiosBaseQuery({
        baseUrl: BASE_URL_ONBOARDING || '',
        
    }),
    
    endpoints: (builder) => ({
        getFilteredUsers: builder.query<GetUsersResponse, GetUsersRequest>({
            query: (req) => ({
                url: 'getFilteredUsers',
                method: 'POST',
                body: req
            })
        }),
        getProfile: builder.query<GetUserResponse, GetUserRequest>({
            query: (req) => ({
                url: 'GetProfile',
                method: 'POST',
                body: req
            })
        })
    })
})

export const { useGetFilteredUsersQuery, useGetProfileQuery } = userApi
