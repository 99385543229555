import React from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  Grid,
  useMediaQuery,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Typography,
  Modal,
  Button,
  ListItemText,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { inviteAdminInviteSchema } from "../../schemas/admin-invites";
import { clientAdmin } from "../../Constants";
import { STRINGS } from "../../Strings";
import { userAccess } from "../../utils/CommonFunction";
import {
  getAllRoles,
  getAllWorkClients,
  saveClient,
  saveSuccessFully,
} from "./User.helper";

const initialValues: any = {
  firstName: "",
  lastName: "",
  ClientName: "",
  email: "",
  roles: [],
  roleId: "",
  operation: "CLIENT_ADMIN_INVITE",
  clientID: "",
  IsAllAccess: true,
  createdBy: "",
  fetchRole: "",
};

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 210,
      boxShadow: "none",
    },
  },
};

type SendInvitesProps = {
  show: boolean;
  close: () => void;
  type: string;
  invitedEmail: (val: string) => void;
  invitationSent: () => void;
};

const SendInvites: React.FunctionComponent<SendInvitesProps> = (props) => {
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const style = {
    width: isMobile ? "70%" : "50%",
    overflowY: "scroll",
    height: isSelectOpen ? "72vh" : "auto", // Adjust height based on select box state
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
  };

  const alert = useAlert();
  const dispatch = useDispatch();
  const [selectedClients, setSelectedClients] = useState<any>("");
  const [formAction, setFormAction] = useState<any>("");
  const [companyName, setCompanyName] = useState<string>("");

  const { allClients, isClientUserAdded, clientAddingInProgress, roles } =
    useSelector(
      //@ts-ignore
      (state) => state.user
    );
  const { allWorkableClients } = useSelector(
    //@ts-ignore
    (state) => state.hire
  );

  useEffect(() => {
    getAllWorkClients(dispatch);
  }, []);

  useEffect(() => {
    getAllRoles(dispatch);
  }, []);

  useEffect(() => {
    if (isClientUserAdded) {
      props.close();
      saveSuccessFully(dispatch);
      formAction.resetForm();
      props.invitationSent();
    }
  }, [isClientUserAdded]);

  useEffect(() => {
    if (userAccess().client) {
      initialValues.clientID = userAccess().ClientId.toString();
      setFieldValue("clientID", userAccess().ClientId.toString());
      setCompanyName(userAccess().companyName);
    } else if (props.type !== clientAdmin) {
      initialValues.clientID = "0";
      setFieldValue("clientID", "0");
    } else {
      setFieldValue("clientID", "");
    }
  }, [props.type]);

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: inviteAdminInviteSchema,
    onSubmit: (values, action) => {
      values.ClientName = companyName;
      values.clientID = `${values.clientID}`;
      values.createdBy = userAccess().userId;
      values.fetchRole = props.type;
      values.operation=values.roles.join('_')
      props.invitedEmail(values.email);
      saveClient(values, dispatch);
      setFormAction(action);
    },
  });

  return (
    <>
      <Modal
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={props.show}
        onClose={props.close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h4" component="h2" mb={2}>
            {STRINGS.SendNewInvite}
          </Typography>

          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ pointerEvents: clientAddingInProgress ? "none" : "auto" }}
          >
            <TextField
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              margin="normal"
              fullWidth
              id="firstName"
              label={STRINGS.FirstName}
              name="firstName"
              variant="outlined"
              autoComplete="off"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              margin="normal"
              fullWidth
              id="lastName"
              label={STRINGS.LastName}
              name="lastName"
              variant="outlined"
              autoComplete="off"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
              <Grid container sx={{ mt: 2 }}>
                <FormControl fullWidth>
                  <InputLabel>{STRINGS.Company}</InputLabel>
                  <Select
                    error={Boolean(touched.clientID && errors.clientID)}
                    value={selectedClients}
                    id="clientID"
                    name="clientID"
                    label={STRINGS.Company}
                    onOpen={() => setIsSelectOpen(true)}
                    onClose={() => setIsSelectOpen(false)}
                    onChange={(e) => {
                      setSelectedClients(e.target.value);
                      setFieldValue("clientID", e.target.value);
                    }}
                  >
                    <MenuItem value={0} key={0}>
                      {"All"}
                    </MenuItem>
                    {allWorkableClients &&
                      allWorkableClients?.map((e, key) => {
                        return (
                          <MenuItem
                            value={e.id}
                            onClick={(event) => {
                              event.stopPropagation();
                              setCompanyName(e.name);
                            }}
                            key={key}
                          >
                            {e.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div
                    style={{
                      marginTop: 10,
                      marginLeft: 5,
                      color: "#D14343",
                    }}
                  >
                    {touched.clientID && errors.clientID
                      ? errors.clientID
                      : null}
                  </div>
                </FormControl>
              </Grid>
            <TextField
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              margin="normal"
              fullWidth
              id="email"
              label={STRINGS.EmailAddress}
              name="email"
              variant="outlined"
              autoComplete="off"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Grid container sx={{ mt: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="Role">{STRINGS.SelectRole}</InputLabel>
                <Select
                  multiple
                  renderValue={(selectedIds: []) =>
                    selectedIds
                      .map((selectedId: any) => {
                        const selectedRole = roles.find(
                          (role: any) => role.name === selectedId
                        );
                        return selectedRole ? selectedRole.name : "";
                      })
                      .join(", ")
                  }
                  error={Boolean(touched.roles && errors.roles)}
                  fullWidth
                  id="roles"
                  label={STRINGS.SelectRole}
                  MenuProps={MenuProps}
                  name="roles"
                  variant="outlined"
                  autoComplete="off"
                  value={values.roles}
                  onOpen={() => setIsSelectOpen(true)}
                  onClose={() => setIsSelectOpen(false)}
                  onChange={(e) => {
                    setFieldValue("roles", e.target.value);
                  }}
                >
                  {roles?.map((role, key) => (
                    <MenuItem
                      key={key}
                      value={role.name}
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#fff",
                          "&:hover": {
                            backgroundColor: "#fff",
                          },
                        },
                      }}
                    >
                      <Checkbox checked={values.roles.includes(role.name)} />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Select>
                <div
                  style={{
                    marginTop: 10,
                    marginLeft: 5,
                    color: "#D14343",
                  }}
                >
                  {touched.role && errors.role ? errors.role : null}
                </div>
              </FormControl>
            </Grid>
            <Box display="flex" justifyContent="flex-end">
              <Button variant="text" sx={{ mt: 2 }} onClick={props.close}>
                {STRINGS.Cancel}
              </Button>
              <Button
                disabled={!dirty}
                type="submit"
                variant="contained"
                sx={{
                  mt: 2,
                }}
              >
                {clientAddingInProgress && (
                  <CircularProgress size={18} color="warning" />
                )}
                {!clientAddingInProgress && STRINGS.SaveAndSendInvite}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SendInvites;
