import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../services/service";
const BASE_URL_RECRUITLY = process.env.REACT_APP_RECRUITLY_URL;

type Candidate = {
    id: string
}

type CandidateListResponse = {
    data: {
        data: Array<Candidate>
        message?: String
        success: boolean
        totalCount: number
        totalValue: number
    },
    message: string
    successfull: boolean,
    status: number
}


type CandidateListRequest = {
    JobId: string
    JobPipeLineCode: string
    PageNumber: number
    PageSize: number
}


export const candidateListApi = createApi({
    reducerPath: 'candidateList',
    baseQuery: axiosBaseQuery({
        baseUrl: BASE_URL_RECRUITLY || '',
    }),
    endpoints: (builder) => ({
        getCandidateList: builder.query<CandidateListResponse, CandidateListRequest>({
            query: (request) => ({
                url: 'getQualifiedJobCandidateListPerStage',
                method: 'POST',
                body: request
            }),
            serializeQueryArgs: ({ queryArgs, endpointName }) => {
                return `${endpointName}?jobId=${queryArgs.JobId}&pipelineCode=${queryArgs.JobPipeLineCode}`

            },
            onQueryStarted(arg, api) {
                if (arg.PageNumber === 0)
                    api.updateCachedData((candidateResponse) => {
                        candidateResponse.data.data = []
                    })
            },


            merge: (currentCache, response) => {


                return {
                    ...currentCache,
                    data: {
                        ...currentCache.data,
                        data: currentCache.data.data.concat(response.data.data || [])
                    }
                }
            },
            forceRefetch({ currentArg, previousArg }) {
                return currentArg?.JobId !== previousArg?.JobId || currentArg?.JobPipeLineCode !== previousArg?.JobPipeLineCode || currentArg?.PageNumber !== previousArg?.JobPipeLineCode
            },

        }),

    })
})

export const { useGetCandidateListQuery } = candidateListApi