// AllPerformanceTableMobile.tsx
import StarIcon from "@mui/icons-material/Star";
import {
  Box,
  Button,
  Paper,
  Rating,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import SkeletonLoaderMobile from "../common/loader/SkeletonLoaderMobile";
import CommentModal from "./CommentModal";
import { usePermissions } from "../../../utils/permission";

const PerformanceOverviewMobile = ({
  loadMoreData,
  pageNumber,
  setFeedback,
  feedback,
}) => {
  const {
    data = [],
    isLoading,
  } = useSelector(
    //@ts-ignore
    (state) => state.performance.allEmployees
  );

  const [showCommentModal, setShowCommentModal] = useState(false);
  const { Performance_AnnualFeedBack } =usePermissions()
  
  const [editingEmployeeDetails, setEditingEmployeeDetails] =
    useState<any>(null);

  const isDisabled = (employee) => {
    return (
      employee.clientId == 0 || employee.isLastMonthFeedbackSubmitted === true
    );
  };

  const handleOpenModal = (emp, currentComment = "") => {
    setShowCommentModal(true);
    // Store the current employee ID that is being edited to update the correct one later
    setEditingEmployeeDetails(emp);
  };

  const handleCloseModal = () => {
    setShowCommentModal(false);
  };

  const handleSaveComment = (empId, comment) => {
    setFeedback((prev) => ({
      ...prev,
      [empId]: {
        ...prev[empId],
        comment,
      },
    }));
  };

  const handleRatingChange = (value, emp) => {
    setFeedback((prev) => ({
      ...prev,
      [emp.id]: {
        ...prev[emp.id],
        rating: value,
      },
    }));
  };

  const onCommentButtonClick = (event, emp) => {
    event.preventDefault();

    handleOpenModal(emp);
  };

  const getComment = (item) => {
    if (item.clientId == 0) {
      return null;
    }

    const existingComment = feedback[item.id]?.comment;

    if (existingComment) {
      return (
        <Typography variant="body2" mt={2}>
          {existingComment}
        </Typography>
      );
    }

    if (item.isLastMonthFeedbackSubmitted === true && !item.comment) {
      return (
        <Typography variant="body2" mt={2} fontStyle="italic">
          No Comment
        </Typography>
      );
    }

    if (item.comment) {
      return (
        <Typography variant="body2" mt={2}>
          {item.comment}
        </Typography>
      );
    }

    return (
      <Button
        variant="text"
        sx={{ mt: 2, p: 0 }}
        onClick={(event) => onCommentButtonClick(event, item)}
      >
        Comment
      </Button>
    );
  };

  
  if (isLoading && pageNumber === 1) {
    return <SkeletonLoaderMobile />;
  }

  return (
    <>
      <Box className="allPerformanceTableMobile" pb={12}>
        {data?.map((item, index) => (
          <Link
            to={Performance_AnnualFeedBack ? `/performance/${item.id}` :'/performance'}
            className="allPerformanceTableMobile-link"
            key={index}
          >
            <Paper
              key={index}
              elevation={1}
              className="allPerformanceTableMobile-paper"
              sx={{ p: 2, mb: 2 }}
            >
              <div className="allPerformanceTableMobile-item">
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography variant="subtitle1">{item.name}</Typography>
                  <Rating
                    value={
                      isDisabled(item)
                        ? item.lastScore
                        : feedback[item.id]?.rating || 0
                    }
                    emptyIcon={
                      isDisabled(item) ? (
                        <StarIcon fontSize="medium" />
                      ) : undefined
                    }
                    readOnly={isDisabled(item)}
                    onChange={(_, newValue) =>
                      handleRatingChange(newValue, item)
                    }
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  />
                </Box>

                {getComment(item)}
              </div>
            </Paper>
          </Link>
        ))}

        <Box display="flex" justifyContent="center">
          {isLoading && (
            <PulseLoader color="#002DFF" loading={true} size={15} />
          )}
          {!isLoading && (
            <Button variant="outlined" onClick={loadMoreData}>
              Load More
            </Button>
          )}
        </Box>
      </Box>

      <CommentModal
        open={showCommentModal}
        onClose={handleCloseModal}
        onSave={handleSaveComment}
        employeeDetails={editingEmployeeDetails || {}}
      />
      
    </>
  );
};

export default PerformanceOverviewMobile;
