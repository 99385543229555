import { Collapse, Grid, Typography } from "@mui/material";
import { memo } from "react";
import { TransitionGroup } from 'react-transition-group';
import TaskAccordion from "./TaskAccordion";


const TaskTable = ({ tasksList, refetchTasks }) => {
    return (
        <Grid container direction={'column'}>
            <Grid container item sx={{
                backgroundColor: '#fff',
                boxShadow: '0px 4px 8px 0px #0000000D',
                height: '54px'
            }}
                gap={1}
                px={6}
                py={2}
                mb={2}
            >
                <Grid item xs={3}>
                    <Typography variant="h5">Title</Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h5">Created on</Typography>

                </Grid>
                <Grid item xs={2}>
                    <Typography variant="h5">Assigned to</Typography>
                </Grid>
                <Grid item xs={5}></Grid>

            </Grid>
            <Grid container item >
                <TransitionGroup style={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    flex: 1,
                    gap: "16px"
                }}>
                    {tasksList.map((task: any) => (
                        <Collapse key={task.id}>
                            <TaskAccordion task={task} refetchTasks={refetchTasks}></TaskAccordion>
                        </Collapse>
                    ))}
                </TransitionGroup>
            </Grid>
        </Grid>
    )
}

export default memo(TaskTable)