import _ from "lodash";
import * as Yup from "yup";
import { checkEmailExist, checkEmployIdExist } from "../services/People";
import { userAccess } from "../utils/CommonFunction";

export const addMemberSchema = Yup.object().shape({
  EmailAddress: Yup.string()
    .email()
    .test(
      "Cann't use Patona email ID",
      "Cann't use Patona email ID",
      function (value) {
        if (!_.isEmpty(value)) {
          return containsDeviceId(value);
        } else {
          return true;
        }
      }
    )
    .test("Unique Email", "Email already in use", function (value) {
      if (!_.isEmpty(value)) {
        return new Promise((resolve, reject) => {
          checkEmailExist({ emailAddress: value }).then((res) => {
            resolve(!res?.data);
          });
        });
      } else {
        return true;
      }
    })
    .required("Please enter your email"),
  EmployeeId: Yup.string()
    .test("Unique Employ", "Employ id already in use", function (value) {
      return new Promise((resolve, reject) => {
        checkEmployIdExist({ employeeId: value }).then((res) => {
          resolve(!res?.data);
        });
      });
    })
    .required("Please enter employee id"),
  ClientId: Yup.string().required("Please select client"),
  PersonalMobileNumber: Yup.string().matches(/^[0-9]+$/, "Must only contain number")
  .min(8, 'Must be greater than 8 digits')
  .max(14, 'Must be less than 14 digits')
  .required('Required'),
  personalEmailID:Yup.string()
  .email("Must be valid email address")
});

const containsDeviceId = async (value) => {
  if (
    userAccess().client &&
    (/@teamified.com.au\s*$/.test(value) || /@teamified.com\s*$/.test(value))
  ) {
    return false;
  }
  return true;
};
