import {
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Typography,
  Box,
} from "@mui/material";
import { useEffect, useState, useCallback } from "react";
import { TabPanel } from "../../shared/components/TabPanel/TabPanel";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate, useParams } from "react-router-dom";
import { fetchJobDetailById } from "../../services/Opportunities";
import { useAlert } from "react-alert";
import React from "react";

const tabsStyle = {
  "&.MuiTab-root": {
    fontSize: "18px",
    fontFamily: "Plus Jakarta Sans",
    fontWeight: "400",
    borderBottom: "2px solid #CCD3D5",
  },
};

const boxStyle = {
  backgroundColor: "#fff",
  minHeight: "40vh",
  pt: 2,
};

const OpportunityDetails = () => {
  const [tabValue, setValue] = useState(0);
  const { id } = useParams();
  const alert = useAlert();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [jobDetail, setJobDetail] = useState<any>({});

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getJobData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await fetchJobDetailById({ Id: id });
      if (response?.status === 200) {
        const data = response.data;
        setJobDetail({
          headerData: {
            "Client Name": data.company.label || "NA",
            "Contract period": data.contract || "NA",
            "Expected joining date": data.startDate || "NA",
            "Posted on": data.createdOnDate || "NA",
          },
          title: `${data.title || "NA"} (${data.experience.name || "NA"})`,
          discription: data.description
            .split("\n")
            .filter((item) => !item.includes("<p>"))
            .join("")
            .match(/<ul>[\s\S]*?<\/ul>/g),
        });
      }
    } catch (error) {
      alert.error("Failed to fetch job details:");
    } finally {
      setLoading(false);
    }
  }, [id, alert]);

  useEffect(() => {
    getJobData();
  }, [getJobData]);

  if (loading) return <DetailSkeleton />;

  return (
    <Grid>
      <Box m={3}>
        <Box height="40px" display="flex" alignItems="center" mb={1}>
          <KeyboardArrowLeftIcon onClick={() => navigate("/opportunities")} />
          <Typography>{jobDetail.title}</Typography>
        </Box>
        <Tabs
          value={tabValue}
          variant="fullWidth"
          onChange={handleChange}
          sx={{ mb: 1, backgroundColor: "#FFFFFF" }}
        >
          <Tab sx={tabsStyle} label="Requirement details" />
          <Tab sx={tabsStyle} label="Applied stage" />
        </Tabs>
      </Box>

      <Box m={1}>
        <TabPanel value={tabValue} index={0}>
          <Grid display="flex" justifyContent="space-between" bgcolor="white" p={2}>
            {jobDetail.headerData &&
              Object.keys(jobDetail.headerData).map((key) => (
                <Box key={key}>
                  <Typography lineHeight={2} fontWeight={900}>
                    {key}
                  </Typography>
                  <Typography lineHeight={2}>
                    {jobDetail.headerData[key]}
                  </Typography>
                </Box>
              ))}
          </Grid>

          <Grid container spacing={1} pt={1}>
            <Grid item xs={12} md={6}>
              <Box sx={boxStyle}>
                <Typography lineHeight={2} fontWeight={900} ml={1}>
                  Requirement and skills
                </Typography>
                {jobDetail?.discription?.[0] && (
                  <div dangerouslySetInnerHTML={{ __html: jobDetail.discription[0] }} />
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={boxStyle}>
                <Typography lineHeight={2} fontWeight={900} ml={1}>
                  Roles and responsibility
                </Typography>
                {jobDetail?.discription?.[1] && (
                  <div dangerouslySetInnerHTML={{ __html: jobDetail.discription[1] }} />
                )}
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Grid>
  );
};

export default OpportunityDetails;



const DetailSkeleton = React.memo(() => (
  <Box m={3}>
    <Skeleton variant="rectangular" width={40} height={40} />
    <Skeleton variant="text" width={200} height={30} />

    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
      <Skeleton variant="rectangular" width="48%" height={40} />
      <Skeleton variant="rectangular" width="48%" height={40} />
    </Box>

    <Box mt={3}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
          <Box sx={{ backgroundColor: "#fff", minHeight: "50vh", p: 2 }}>
            <Skeleton variant="text" width="80%" height={30} />
            <Skeleton variant="rectangular" width="100%" height="40vh" />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ backgroundColor: "#fff", minHeight: "50vh", p: 2 }}>
            <Skeleton variant="text" width="80%" height={30} />
            <Skeleton variant="rectangular" width="100%" height="40vh" />
          </Box>
        </Grid>
      </Grid>
    </Box>
  </Box>
));
