import { Grid } from "@mui/material";
import AttendaceContainer from "./DashboardAttendance/AttendaceContainer";
import LeaveContainer from "./DashboardLeave/LeaveContainer";
import OpportunityContainer from "./DashboardOpportunity/OpportunityContainer";
import TeammemberDashboardHeader from "./TeammemberDashboardHeader/TeammemberDashboardHeader";


const TeammemberDashboard = () => {
  return (
    <Grid container spacing={2}>
      <TeammemberDashboardHeader />
      <AttendaceContainer />
      <LeaveContainer />
      <OpportunityContainer />
    </Grid>
  );
};

export default TeammemberDashboard;
