import { Button, Grid, Popover } from "@mui/material"
import { FC, memo, useCallback, useState } from "react"
import { MenuOptions } from "./TasksHeader"


type Option = Record<string, string>

type Props = {
    options: Array<MenuOptions>
    open: boolean
    anchorEl: HTMLButtonElement | null
    handleClose: () => void
    parent?: string
    activeId?: string
    changeOption: (option: Option) => void
    filters: Record<string, string>
}





const TaskHeaderFilterMenu: FC<Props> = ({ open, options, anchorEl, handleClose, changeOption = () => null, filters }) => {
    const id = open ? 'simple-popover' : undefined;

    const [selected, setSelected] = useState<number>(0)
    const changeSelected = useCallback((val: number) => {
        setSelected(val)
    }, [])

    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            disableScrollLock

            sx={{
                mt: 1,

            }}

            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                horizontal: 'center',
                vertical: 'top'
            }}
        >
            <Grid container item flexWrap={'nowrap'} minWidth={'300px'} minHeight={'250px'} py={2} gap={2}>
                <Grid container direction={'column'} >
                    {options.map((option, index) => (
                        <Grid container sx={{
                            backgroundColor: selected === index ? "#FAF9F9" : '#FAF9F900',
                            color: selected === index ? '#A16AE8' : '#000000',
                            "&:hover": {
                                backgroundColor: '#FAF9F9'
                            }
                        }}>
                            <Grid sx={{
                                width: '4px',
                                backgroundColor: selected === index ? '#A16AE8' : '#A16AE800',
                                borderTopRightRadius: '4px',
                                borderBottomRightRadius: '4px'

                            }}></Grid>
                            <Button variant="text" sx={{
                                justifyContent: 'flex-start',
                                "&:hover": {
                                    backgroundColor: "transparent"
                                }

                            }} color={'inherit'} key={option.id} onClick={() => changeSelected(index)}>{option.title}</Button>
                        </Grid>
                    ))}
                </Grid>
                <Grid container direction={'column'}>{
                    options[selected].options?.map((subOption, index) => {
                        const selectedSubOption = filters[options[selected].id] === subOption.id
                        return (
                            <Grid container sx={{
                                backgroundColor: selectedSubOption ? "#FAF9F9" : '#FAF9F900',
                                color: selectedSubOption ? '#A16AE8' : '#000000',
                                "&:hover": {
                                    backgroundColor: '#FAF9F9'
                                }
                            }}>
                                <Grid sx={{
                                    width: '4px',
                                    backgroundColor: selectedSubOption ? '#A16AE8' : '#A16AE800',
                                    borderTopRightRadius: '4px',
                                    borderBottomRightRadius: '4px'

                                }}></Grid>
                                <Button variant="text" sx={{
                                    justifyContent: 'flex-start',
                                    "&:hover": {
                                        backgroundColor: "transparent"
                                    }

                                }} color={'inherit'} key={subOption.id} onClick={() => {
                                    if(filters[options[selected].id] === subOption.id){
                                        changeOption({[options[selected].id]: ''})
                                    }else {
                                        changeOption({[options[selected].id] : subOption.id})
                                        handleClose()
                                    }
                                }}>{subOption.title}</Button>
                            </Grid>
                        )
                    })
                }</Grid>
            </Grid>
        </Popover>
    )
}



export default memo(TaskHeaderFilterMenu)