import { useStyles } from "./styles";
import { useLocation, Link } from "react-router-dom";

//styles
import { Box, Card, Typography, Container } from "@mui/material";
import RenderBottomInfo from "./renderBottomInfo";

const Info = () => {
  const location: Location | unknown | any = useLocation();
  const classes = useStyles();
  const { title, subTitle1, source } = location?.state || {};

  const renderImage = () => {
    if (source === "Already Registered") {
      return <img src="/images/notepad-icon.svg" alt="icon" />;
    } else if (source === "Link Expired") {
      return <img src="/images/expiry-icon.svg" alt="icon" />;
    } else {
      return <img src="/images/mail-icon.svg" alt="icon" />;
    }
  };

  return (
    <>
      <Container maxWidth="md">
        <Card sx={{ maxWidth: 1800, minHeight: 500, boxShadow: 3 }}>
          <Box className={classes.info_header}>
            <Link to="/">
              <img src="/images/Teamified-Full-Logo-White.svg" alt="Teamified logo" />
            </Link>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: 500,
              margin: "auto",
              textAlign: "center",
            }}>
            <Box sx={{ mb: 2, mt: 5 }}>{renderImage()}</Box>

            <Box>
              <Typography variant="h4" gutterBottom mt={3}>
                {title}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" mt={2}>
                {subTitle1}
              </Typography>
            </Box>
          </Box>
          <RenderBottomInfo source={source} />
        </Card>
      </Container>
    </>
  );
};

export default Info;
