import { Box, Button, Grid } from "@mui/material"
import { Form, Formik } from "formik"
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react"
import { useAlert } from "react-alert"
import { useSelector } from "react-redux"
import * as yup from "yup"
import { TeamifiedTask, useAddTaskMutation, useUpdateTaskMutation } from "../../redux/Task/TaskApi"
import { useGetFilteredUsersQuery } from "../../redux/User/userApi"
import FormikInput from "../../shared/components/FormikInput"
import { EmployerRoles, TeamifiedRoles, userAccess } from "../../utils/CommonFunction"
import { usePermissions } from "../../utils/permission"

const OPTIONS_REVERSE_MAP = {
    1: "account_manager",
    2: "client",
    3: "individual"
}

const validationSchema = yup.object({
    Title: yup.string().required("Task title is required"),
    Summary: yup.string().required("Summary is required"),
    Priority: yup.string().required("Please select a priority"),
    AssignTo: yup.string().required('Please select Assignee')

});

const OPTIONS_ADMIN = [
    { name: "Client", value: 2 },
    { name: 'Individual', value: 3 }]
const OPTIONS_CLIENT_ADMIN = [
    { name: "Account Manager", value: 1 },
    { name: 'Individual', value: 3 }
]



type Props = {
    closeModal: () => void
    task?: TeamifiedTask
}

const AddNewTaskModal: FC<Props> = ({ closeModal, task }) => {
    let defaultInitialValues = useMemo(() => {
        if (task)
            return {
                TaskType: task.taskType,
                Title: task.title,
                Summary: task.summary,
                ClientId: task.clientId,
                client: task.clientId,
                Priority: task.priority,
                CreatedAt: task.createdAt,
                AssignTo: task.assigneeUser ? 3 : 2,
                AssigneeUser: task.assigneeUser
            }

        return {
            TaskType: "individualTask",
            Title: "",
            Summary: "",
            ClientId: "",
            Priority: "",
            CreatedAt: "",
            AssignTo: 1,
        }
    }, [task])

    const [initialValues, setInitialValues] = useState(defaultInitialValues)
    const { Task_AddTask } = usePermissions()
    const [addTaskApi, { isLoading: addTaskLoading, isError: addTaskError, isSuccess: addTaskSuccess, error }] = useAddTaskMutation()
    const [updateTaskApi, { isLoading: updateTaskLoading, isError: updateTaskError, isSuccess: updateTaskSuccess, error: updateError }] = useUpdateTaskMutation()
    const { allWorkableClients, isLoading } = useSelector(
        //@ts-ignore
        (state) => state.hire
    );
    const resetToDefaultValues = useCallback(()=>{
        if(task){
            setInitialValues(defaultInitialValues)
            setAssignToType(task.assigneeUser ? 3 : 2)
        }
    },[defaultInitialValues,task]);

    const resetOrCancel = useCallback((resetForm: () => void) => () => {
        if (!task) {
            closeModal?.();
        }
        resetToDefaultValues()
        resetForm()
    }, [task, resetToDefaultValues, closeModal])

    useEffect(() => {
        if (task) {
            resetToDefaultValues()
        }
    }, [task, resetToDefaultValues])

    const { data: userList } = useGetFilteredUsersQuery({
        clientId: userAccess().ClientId,
        disabled: false,
        pageNumber: 0,
        pageSize: 50,
        roles: userAccess().client ? EmployerRoles : TeamifiedRoles,
        searchValue: ''
    }, {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true
    })
    const alert = useAlert()
    const [assignToType, setAssignToType] = useState(userAccess().client ? 1 : 2);


    useEffect(() => {
        if (addTaskSuccess) {
            closeModal()
            alert.success('Task added successfully')
        }
    }, [addTaskSuccess, alert, closeModal])

    useEffect(() => {
        if (addTaskError && error) {
            alert.error('Error adding task ')
        }
    }, [addTaskError, alert, error])

    useEffect(() => {
        if (updateTaskSuccess) {
            alert.success('Task updated successfully')
        }
    }, [updateTaskSuccess, alert])

    useEffect(() => {
        if (updateTaskError && updateError) {
            alert.error('Error adding task ')
        }
    }, [updateTaskError, alert, updateError])

    const postAddTask = useCallback(async (values) => {
        const request = {
            Title: values.Title,
            Summary: values.Summary,
            Priority: values.Priority,
            TaskType: values.TaskType,
            AssigneeRole: OPTIONS_REVERSE_MAP[assignToType] === 'client' ? EmployerRoles.join() : OPTIONS_REVERSE_MAP[assignToType] === 'account_manager' ? TeamifiedRoles.join() : userAccess().role,
            AssigneeUser: OPTIONS_REVERSE_MAP[assignToType] === 'client' ? null : OPTIONS_REVERSE_MAP[assignToType] === 'account_manager' ? null : (values.AssigneeUser || userAccess().userId),
            ClientId: assignToType === 1 ? 999 : values.clientId,
            CreatedBy: userAccess().userId,
            creatorUserEmail: userAccess().email,
            assigneeUserEmail: userList?.data?.users?.find(user => user.userId === values.AssigneeUser)?.email || '',
            target: OPTIONS_REVERSE_MAP[assignToType]

        }
        addTaskApi(request)
    }, [addTaskApi, assignToType, userList])

    const updateTask = useCallback(async (values) => {
        if (task) {
            const request = {
                ...task,
                title: values.Title,
                summary: values.Summary,
                priority: values.Priority,
                taskType: values.TaskType,
                assigneeRole: OPTIONS_REVERSE_MAP[assignToType] === 'client' ? EmployerRoles.join() : OPTIONS_REVERSE_MAP[assignToType] === 'account_manager' ? TeamifiedRoles.join() : userAccess().role,
                assigneeUser: OPTIONS_REVERSE_MAP[assignToType] === 'client' ? null : OPTIONS_REVERSE_MAP[assignToType] === 'account_manager' ? null : (values.AssigneeUser || userAccess().userId),
                clientId: assignToType === 1 ? 999 : values.clientId,
                createdBy: userAccess().userId,
                creatorUserEmail: userAccess().email,
                assigneeUserEmail: userList?.data?.users?.find(user => user.userId === values.AssigneeUser)?.email || '',
                target: OPTIONS_REVERSE_MAP[assignToType]

            }
            updateTaskApi(request)
        }

    }, [assignToType, task, updateTaskApi, userList])


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(data, { resetForm }) => {
                if (task) {
                    updateTask(data)
                } else {
                    postAddTask(data).then(()=>resetForm())
                }
            }}
        >
            {(formikProps) => (
                <Form style={{
                    flex: 1
                }}>
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} md={12}>
                            <FormikInput
                                label="Task*"
                                name="Title"
                                formikProps={formikProps}
                                options={undefined}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <FormikInput
                                label="Describe the task*"
                                name="Summary"
                                formikProps={formikProps}
                                options={undefined}
                                multiline
                                rows={3}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormikInput
                                label="Assign to*"
                                name="AssignTo"
                                className="common-select-dropdown"
                                formikProps={formikProps}
                                schemaChange
                                schemaOnChangeFun={(value) => {
                                    setAssignToType(value)
                                }}
                                options={
                                    userAccess().client ? OPTIONS_CLIENT_ADMIN : OPTIONS_ADMIN
                                }
                                inputType="select"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormikInput
                                label="Priority*"
                                name="Priority"
                                className="common-select-dropdown"
                                formikProps={formikProps}
                                options={[
                                    { name: "Low", value: 'Low' },
                                    { name: "Medium", value: 'Medium' },
                                    { name: "Urgent", value: "Urgent" },

                                ]}
                                inputType="select"
                                fullWidth
                            />
                        </Grid>
                        {assignToType === 2 && <Grid item xs={12} sm={4}>
                            <FormikInput
                                label="Client*"
                                name="client"
                                className="common-select-dropdown"
                                formikProps={formikProps}
                                options={allWorkableClients.map((item) => {
                                    return { name: item.name, value: item.id };
                                })}
                                schemaChange
                                schemaOnChangeFun={(value) => {
                                    formikProps.setFieldValue("clientId", value);
                                }}
                                inputType="select"
                                fullWidth
                            />
                        </Grid>}

                        {Task_AddTask && assignToType === 3 && <Grid item xs={12} sm={4}>
                            <FormikInput
                                label="Individual*"
                                name="AssigneeUser"
                                className="common-select-dropdown"
                                formikProps={formikProps}
                                options={userList?.data.users.map((item) => {
                                    return { name: item.userName, value: item.userId };
                                })}
                                schemaChange
                                schemaOnChangeFun={(value) => {
                                    formikProps.setFieldValue("AssigneeUser", value);
                                }}
                                inputType="select"
                                fullWidth
                            />
                        </Grid>}
                    </Grid>
                    <Box
                        display="flex"
                        justifyContent="flex-end"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <Button
                            variant="text"
                            sx={{ mt: 2 }}
                            onClick={resetOrCancel(formikProps.resetForm)}
                        >
                            {task? 'Reset' : 'Cancel'}
                        </Button>
                        <Button
                            type="submit"
                            disabled={isLoading || addTaskLoading || updateTaskLoading}
                            variant="contained"
                            sx={{ mt: 2 }}
                        >
                            {task ? 'Update task' : 'Add task'}
                        </Button>
                    </Box>
                </Form>

            )}
        </Formik>
    )
}

export default memo(AddNewTaskModal)