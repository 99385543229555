import { rowPerPage } from "../Constants";
import api from "../services/BaseApi";
const BASE_URL = process.env.REACT_APP_RECRUITLY_URL;
const BASE_URL_JOB_OPPORTUNITY = process.env.REACT_APP_BASE_API_URL;

const getOpportunitiesForTeammember =
  BASE_URL + "getOppurtunitiesForTeammember";
const getJobRequestDetails = BASE_URL + "getJobRequestDetails";
const referForOppurtunities = BASE_URL_JOB_OPPORTUNITY + "referForOppurtunities";
const applyForOppurtunities = BASE_URL_JOB_OPPORTUNITY + "applyForOppurtunities";

export const opportunities = async (payload) => {
  try {
    let request: any = {
      PageNumber: 1,
      PageSize: '6',
    };

    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getOpportunitiesForTeammember, request);
    return res;
  } catch (error) {
    console.log(error, "===== getOpportunitiesForTeammember list API Error");
    //return error;
  }
};

export const fetchJobDetailById = async (payload) => {
  try {
    let request: any = {
      Id: "0",
    };
    if (payload) {
      request = { ...request, ...payload };
    }
    const res = await api.post(getJobRequestDetails, request);
    return res;
  } catch (error) {
    console.log(error, "===== getOpportunitiesForTeammember list API Error");
    //return error;
  }
};

export const referForJob = async (payload) => {
  try {
    const res = await api.post(referForOppurtunities, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error) {
    console.error("referForOppurtunities Error:", error);
    return error;
  }
};

export const applyForJob = async (payload) => {
  try {
    const res = await api.post(applyForOppurtunities, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return res;
  } catch (error) {
    console.error("applyForOppurtunities Error:", error);
    return error;
  }
};
