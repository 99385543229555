import {
  Checkbox,
  FormControlLabel,
  Paper,
  Rating,
  Snackbar,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import StarIcon from "@mui/icons-material/Star";
import { GridColumnHeaderParams } from "@mui/x-data-grid";
import moment from "moment";
import { sagaActions } from "../../../redux/sagaActions";
import CustomizedDataGrid from "../../../shared/components/CustomizedDataGrid";
import { userAccess } from "../../../utils/CommonFunction";
import CustomCommentsHeader from "../common/CustomCommentsHeader";
import CustomRatingHeader from "../common/CustomRatingHeader";
import SubmissionModal from "../emailMultipleFeedback/SubmissionModal";
import { usePermissions } from "../../../utils/permission";

const AllPerformanceTable = ({
  handlePageChange,
  handleSort,
  handlePagesizeChange,
}) => {
  const [bulkRating, setBulkRating] = useState(0);
  const [feedback, setFeedback] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [ratingMissingRows, setRatingMissingRows] = useState([]);
  const { Performance_ViewTeamMemberHistory } =usePermissions()
  const dispatch = useDispatch();

  const selected = Object.keys(feedback);

  const {
    data = [],
    isLoading,
    error,
    pageNumber,
    pageSize,
    total,
  } = useSelector(
    //@ts-ignore
    (state) => state.performance.allEmployees
  );

  const navigate: any = useNavigate();

  const handleRedirectPerformanceDetails = (employeeId) => {
    console.log("handle redirect perfomratn cdetils");
    navigate(`/performance/${employeeId}`, {
      state: { pageSize, pageNumber },
    });
  };

  let columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div>
            {params.value}
            <div>
              <Typography variant="caption" color="textSecondary">
                {params.row.jobTitle}
              </Typography>
            </div>
          </div>
        );
      },
    },
    {
      field: "lastScore",
      align: "center",
      headerName: "Last Score",
      width: 110,
    },
    {
      field: "rating",
      headerName: "Rating",
      sortable: false,
      disableColumnMenu: true,
      minWidth: 150,
      renderHeader: (params: GridColumnHeaderParams) => {
        const selected = Object.keys(feedback);
        return (
          <CustomRatingHeader
            setBulkRating={handleBulkRatingChange}
            bulkRating={bulkRating}
            selected={selected}
          />
        );
      },
      renderCell: (params: any) => {
        const isSelected = isRowSelectable(params);

        const ratingValue =
          feedback[params.id]?.rating || params.row.lastScore || 0;

        return (
          <Rating
            name={`rating-${params.id}`}
            value={ratingValue || 0}
            onChange={(event, newValue) => {
              setFeedback((previousFeedback) => ({
                ...previousFeedback,
                [params.id]: {
                  ...previousFeedback[params.id],
                  rating: newValue,
                  isShareOn: true,
                },
              }));
            }}
            onClick={(event) => {
              event.stopPropagation();
            }}
            readOnly={!isSelected}
            emptyIcon={isSelected ? undefined : <StarIcon fontSize="medium" />}
          />
        );
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      minWidth: 200,
      renderCell: (params) => {
        // @ts-ignore
        const isSelected = isRowSelectable(params);

        if(params.row.isLastMonthFeedbackSubmitted === true && !params.row.comment) {
          return (
            <Typography variant="caption" color="textSecondary" fontStyle="italic">
              No comment
            </Typography>
          );
        }

        if (params.row?.comment) {
          return params.row?.comment;
        }

        if (isSelected) {
          return (
            <TextareaAutosize
              minRows={3}
              placeholder="Comment here"
              style={{
                width: "100%",
                border: "1px solid #ccc",
                borderRadius: "4px",
                height: 42,
                resize: "none",
              }}
              // @ts-ignore
              value={feedback[params.id]?.comment || ""}
              onChange={(event) => {
                setFeedback({
                  ...feedback,
                  [params.id]: {
                    ...feedback[params.id],
                    comment: event.target.value,
                  },
                });
              }}
            />
          );
        }
      },
    },
  ];

  const viewColumn: any = {
    field: "view",
    headerName: "",
    sortable: false,
    disableColumnMenu: true,
    width: 200,
    align: "center",
    renderHeader: (params: GridColumnHeaderParams) => {
      const selected = Object.keys(feedback);
      if (selected.length === 0) return null;
      return (
        <CustomCommentsHeader
          onSubmit={onSubmit}
          handleOnCancel={handleOnCancel}
        />
      );
    },
    renderCell: (params) => {
      return (
        <Typography
          variant="caption"
          color="#002DFF"
          sx={{ cursor: "pointer" }}
          onClick={() =>
            handleRedirectPerformanceDetails(params.row.employeeId)
          }
        >
          View
        </Typography>
      );
    },
  };

  const shareColumn: any = {
    field: "share",
    headerName: "",
    sortable: false,
    disableColumnMenu: true,
    width: 190,
    renderCell: (params) => {
      const isEditable = isRowSelectable(params) && feedback[params.id];
      const isShareOn = feedback[params.id]?.isShareOn || false;

      if (!isEditable) return null;

      return (
        <FormControlLabel
          control={
            <Checkbox
              checked={isShareOn}
              onChange={(event) => {
                setFeedback((previousFeedback) => ({
                  ...previousFeedback,
                  [params.id]: {
                    ...previousFeedback[params.id],
                    isShareOn: event?.target.checked,
                  },
                }));
              }}
              name="shareFeedback"
              color="primary"
            />
          }
          label="Share with team member?"
          sx={{
            "& .MuiFormControlLabel-label": {
              fontSize: "12px",
            },
          }}
        />
      );
    },
  };

  if (selected.length > 0) {
    columns.push(shareColumn);
  }

  Performance_ViewTeamMemberHistory && columns.push(viewColumn);

  const isRowSelectable = (params) => {
    return (
      params.row.isLastMonthFeedbackSubmitted === false &&
      params.row.clientId !== 0
    );
  };

  const handleOnCancel = () => {
    setFeedback([]);
  };

  const onSubmit = () => {
    let _ratingMissingRows: any = [];

    for (const feedbackEntry of Object.entries(feedback)) {
      const [employeeId, feedbackValue] = feedbackEntry as any;
      if (
        !feedbackValue.rating &&
        (feedbackValue.comment || feedbackValue.isShareOn)
      ) {
        _ratingMissingRows.push(employeeId);
      }
    }

    if (_ratingMissingRows.length > 0) {
      setRatingMissingRows(_ratingMissingRows);
      return;
    }

    setRatingMissingRows([]);

    setShowModal(true);
    const { userId, firstName, lastName } = userAccess();
    const previousMonthStartDate = moment()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

    const payload = Object.entries(feedback)?.map(
      ([employeeId, feedbackValue]: any) => {
        const employee = data.find(
          (e) => e.employeeId.toString() === employeeId
        );
        return {
          clientId: employee.clientId,
          userId,
          employeeId: parseInt(employeeId, 10),
          employeeName: employee.name,
          score: feedbackValue.rating,
          date: previousMonthStartDate,
          from: `${firstName} ${lastName}`,
          comment: feedbackValue.comment || "",
          isShareOn: feedbackValue.isShareOn || false,
        };
      }
    );

    dispatch({ type: sagaActions.SUBMIT_BULK_FEEDBACK, payload });
  };

  const handleBulkRatingChange = (rating: number) => {
    let newFeedback = { ...feedback };
    const pendingRows = data.filter((row: any) => {
      return !row.isLastMonthFeedbackSubmitted && row.clientId !== 0;
    });
    pendingRows.forEach((row: any) => {
      newFeedback[row.id] = {
        ...newFeedback[row.id],
        rating,
        isShareOn: true,
      };
    });

    setFeedback(newFeedback);
  };

  const onModalClose = () => {
    handlePageChange(pageNumber);
    setShowModal(false);
    setFeedback({});
  };

  const { submitBulkFeedbackInfo } = useSelector(
    //@ts-ignore
    (state) => state.performance.multipleEmployees
  );

  return (
    <Paper sx={{ height: "100%", width: "100%", padding: 0 }}>
      <CustomizedDataGrid
        rows={data}
        columns={columns}
        isDataLoading={isLoading}
        totalRowCount={total}
        pageNumber={pageNumber}
        handlePageChange={handlePageChange}
        onPageSizeChange={handlePagesizeChange}
        isRowSelectable={isRowSelectable}
        pageSize={pageSize}
        onSortModalChange={handleSort}
        rowHeight={70}
        highlightRows={ratingMissingRows}
        pagination
      />

      {ratingMissingRows.length > 0 && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          open={true}
          autoHideDuration={6000}
          message="Please rate all the commented employees"
          sx={{
            "& .MuiSnackbarContent-root": {
              backgroundColor: "red",
            },
          }}
        />
      )}

      <SubmissionModal
        showModal={showModal}
        onSuccessClose={onModalClose}
        onCancel={() => setShowModal(false)}
        submitBulkFeedbackInfo={submitBulkFeedbackInfo}
      />
    </Paper>
  );
};

export default AllPerformanceTable;
