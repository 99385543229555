import React, { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  InputLabel,
  FormControl,
  OutlinedInput,
  Skeleton,
} from "@mui/material";
import { getUserRoles, updateUserRole } from "../../services/OnBoarding";
import { getAllRoles } from "./User.helper";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 270,
      boxShadow: "none",
    },
  },
};

const baseModalStyle = {
  width: "50%",
  overflowY: "none",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const EditUserRoles = ({ show, close, id }) => {
  const [selected, setSelected] = useState<any>([]);
  const [selectOpen, setSelectOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();

  const { roles } = useSelector(
    //@ts-ignore
    (state) => state.user
  );

  const getUserRoleForEdit = async () => {
    setLoading(true);
    if (show) {
      const getRoles = await getUserRoles(id);
      if (getRoles?.status === 200) {
        setSelected(getRoles.data);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (show) {
      getUserRoleForEdit();
      getAllRoles(dispatch);
    }
  }, [show]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelected(
      typeof value === "string"
        ? value
            .split(",")
            .map((name) => roles.find((role) => role.name === name))
        : value.map((name) => roles.find((role) => role.name === name))
    );
  };

  const handleCheckboxChange = (event, optionName) => {
    if (event.target.checked) {
      const newRole = roles.find((role) => role.name === optionName);
      setSelected([...selected, newRole]);
    } else {
      setSelected(selected.filter((role) => role.name !== optionName));
    }
  };

  const handleSubmitRoles = async () => {
    const newRoles = selected.map((item) => item.name);

    const payload = {
      userId: id,
      roleName: newRoles,
    };

    try {
      const response = await updateUserRole(payload);
      if (response?.status === 200) {
        alert.success("Role added successfully");
        close();
      } else {
        alert.error("Failed to update roles");
      }
    } catch (error) {
      alert.error("Error occurred while updating roles");
    }
  };

  const modalStyle = {
    ...baseModalStyle,
    height: selectOpen ? "60vh" : "30vh",
  };

  return (
    <Modal
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={show}
      onClose={close}
      aria-labelledby="edit-role-modal"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <h2>Edit Role</h2>
        {loading ? (
          <Skeleton variant="rounded" width="100%" height={56} />
        ) : (
          <FormControl fullWidth>
            <InputLabel id="demo-multiple-checkbox-label">Roles</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selected.map((role) => role.name)}
              onChange={handleChange}
              input={<OutlinedInput label="Roles" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              onOpen={() => setSelectOpen(true)}
              onClose={() => setSelectOpen(false)}
            >
              {roles?.map((option: any) => (
                <MenuItem
                  key={option?.name}
                  value={option?.name}
                  sx={{
                    "&.Mui-selected": {
                      backgroundColor: "#fff",
                      "&:hover": {
                        backgroundColor: "#fff",
                      },
                    },
                  }}
                >
                  <Checkbox
                    checked={selected.some((role) => role.name === option.name)}
                    onChange={(event) =>
                      handleCheckboxChange(event, option.name)
                    }
                  />
                  <ListItemText primary={option?.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
          <Button onClick={close}>Cancel</Button>
          <Button variant="contained" onClick={handleSubmitRoles}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default EditUserRoles;
