import * as amplitude from '@amplitude/analytics-browser';
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./index.scss";
import { persistedStore, store } from "./redux";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],

  // Ignore certain errors
  ignoreErrors: [
    "Network request failed",
  ],

  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,

  // Session Replay
  replaysSessionSampleRate: 0.01, // This sets the sample rate at 1%.
  replaysOnErrorSampleRate: 0.1,  // Change the sample rate to 10% when sampling sessions where errors occur.

  // Disable on localhost
  enabled: process.env.REACT_APP_ENVIRONMENT !== 'localhost',
});

const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;

if (amplitudeApiKey) {
  amplitude.init(amplitudeApiKey, {
    autocapture: {
      elementInteractions: true
    }
  });
} else {
  console.error('Amplitude API key is not defined');
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div>Loading...</div>} persistor={persistedStore}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
