import { Box, Button, CircularProgress, Grid, IconButton, LinearProgress, Paper, styled, TextField, Typography } from "@mui/material";
import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { clearUploadProgress, documents, useCreateDocumentMutation, useGenerateUploadLinkMutation, useLazyDownloadDocumentQuery, useUploadToAzureMutation } from "../../redux/Documents/documentSlice";
import { userAccess } from "../../utils/CommonFunction";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});



const UploadDocument = ({ documentName, id = "", type, userName, url, uploadDone = () => {}, roleCanView="", roleCanEdit="",roleCanDelete="", isInline=false,sx={} , disableUpload =  false}) => {

    const [editableDocumentName, setEditableDocumentName] = useState<string>('');
    const [getLink, { data, isSuccess, isLoading }] = useGenerateUploadLinkMutation()
    const [downloadDocument, {isFetching: downloadDocLoading}] = useLazyDownloadDocumentQuery()
    const onClick = async () => {
        const documentUrl = url.split('/')
        const result = await downloadDocument({
            fileName: documentUrl[documentUrl.length-1]
        })
       if(result.data?.link){
        window.open(result.data.link,'_blank')
       }
    }    

    const [files, setFiles] = useState<File | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const { uploadProgress } = useSelector(documents)
    const dispatch = useDispatch()
    const docString = `${documentName}-${id}`
    const [uploadFile, { isLoading: uploadIsLoading, }] = useUploadToAzureMutation()
    const alert = useAlert()

    const [createDocumentApi, { isSuccess: createDocumentSuccess, isLoading: createDocumentLoading, isError: createDocumentError }] = useCreateDocumentMutation()


    useEffect(() => {
        if (!files) {
            dispatch(clearUploadProgress(docString))
        }
    }, [dispatch, docString, files])

    useEffect(() => {
        if (createDocumentError) {
            alert.error('Error uploading document')
        }
    }, [alert, createDocumentError])

    useEffect(() => {
        if(createDocumentSuccess && !documentName){
            uploadDone()
        }

    },[createDocumentSuccess, documentName, uploadDone])

    const fileUploadProgress = uploadProgress[docString] || 0
    const generateLink = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            getLink({
                fileName: e.target.files[0].name,
                id,
            })
            setFiles(e.target.files[0])
        }
    }, [getLink, id])

    const uploadToAzure = useCallback((link: string) => {
        if (files) {
            uploadFile({
                link,
                file: files,
                fileName: documentName,
                id: id
            })
        }
    }, [documentName, files, id, uploadFile])

    useEffect(() => {
        if (isSuccess && data) {
            uploadToAzure(data.link)
        }
    }, [isSuccess, data, uploadToAzure]);

    const clearInput = () => {
        if (inputRef.current) {
            inputRef.current.value = ''
        }
        setFiles(null)
        dispatch(clearUploadProgress(docString))
    }

    const createDocument = useCallback(() => {
        if (files && data?.link) {
            createDocumentApi({
                fileType: files?.type,
                ownerType: 'Client_Admin',
                ownerId: id,
                ownerClientId: userAccess().ClientId,
                createdBy: userAccess().userId,
                createdAt: new Date(),
                userId: id,
                title: `${documentName || editableDocumentName}-${userName}`,
                url: data.link.split('?')[0],
                type: type,
                roleCanView: roleCanView,
                roleCanEdit: roleCanEdit,
                roleCanDelete: roleCanDelete,
                userName: userName
            })
        }
    }, [createDocumentApi, data, documentName, editableDocumentName, files, id, roleCanDelete, roleCanEdit, roleCanView, type, userName])

    return <Grid item width={'100%'} position={'relative'}>
        <Paper elevation={isInline ? 0 : 1} sx={{
            justifyContent: 'space-between',
            px: isInline ? 0 : 2,
            display: 'flex',
            alignItems: 'center',
            py: isInline ? 0 : 0.5,
            minHeight: '58px'
        }}>
            {documentName ? <Typography variant="body1" sx={sx}>
                {documentName}
            </Typography> : <TextField 
            variant="outlined" 
            value={editableDocumentName} 
            onChange={(e) => setEditableDocumentName(e.target.value)}
            size="small"
            
            placeholder="Document Name"
            ></TextField>}
            {files && !url && <Box gap={1} display={'flex'} alignItems={'center'}>
                <Typography variant="caption">{files.name}</Typography>
                <IconButton
                    size="small"
                    onClick={clearInput}
                    disabled={uploadIsLoading}>
                    <img
                        src="/images/delete.svg"
                        alt="icon"
                    />
                </IconButton>

                <Button
                    variant="text"
                    size="small"
                    disabled={createDocumentLoading}
                    onClick={createDocument}
                >
                    {createDocumentLoading ? <CircularProgress size={20} /> : 'Save'}

                </Button>
            </Box>}
            {!files && !url && <Button
                component="label"
                role={undefined}
                variant="text"
                size="small"
                disabled={isLoading || disableUpload}
                tabIndex={-1}
            >
                {isLoading ? <CircularProgress size={20} /> : 'Add document'}
                <VisuallyHiddenInput
                    type="file"
                    ref={inputRef}
                    onChange={generateLink}
                />
            </Button>}
            {url && <Button onClick={onClick} variant="text" size="small">
                {downloadDocLoading ? <CircularProgress size={20}></CircularProgress>: 'Download'}
                </Button>}
            {fileUploadProgress > 0 && !createDocumentSuccess && !isInline &&
                <LinearProgress variant="determinate" value={fileUploadProgress} sx={{
                    borderEndStartRadius: 10,
                    borderEndEndRadius: 10,
                    position: 'absolute',
                    left: '8px',
                    bottom: 0,
                    width: 'calc(100% - 8px)',
                    height: '4px'
                }}></LinearProgress>}
                
        </Paper>

    </Grid>
}

export default UploadDocument