import { Menu, MenuItem } from "@mui/material";
import React from "react";

const mainMenu = [
  { name: "Latest", id: true },
  { name: "Period", id: "custom" },
  { name: "Oldest", id: false },
];
const subMenu = ["all", "this week", "this month", "quarterly", "annual"];

const OpportunityFilter = ({
  open,
  anchorEl,
  handleClose,
  handlePeriodClick,
  handleMainMenuItemClick,
  handlePeriodMenuItemClick,
  activeMenu,
  activePeriodOption,
}) => (
  <Menu
    id="basic-menu"
    anchorEl={anchorEl}
    open={open}
    onClose={handleClose}
    MenuListProps={{ "aria-labelledby": "basic-button" }}
  >
    <div style={{ display: "flex" }}>
      <div>
        {mainMenu.map((item:any) => (
          <MenuItem
            key={item.id}
            selected={activeMenu === item.id}
            onClick={() =>
              item.name === "Period"
                ? handlePeriodClick()
                : handleMainMenuItemClick(item.id)
            }
            sx={{
              borderLeft: activeMenu === item.id ? "3px solid #A16AE8" : "none",
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </div>
      {activeMenu === "custom" && (
        <div>
          {subMenu.map((subItem) => (
            <MenuItem
              key={subItem}
              selected={activePeriodOption === subItem}
              onClick={() => handlePeriodMenuItemClick(subItem)}
            >
              {subItem}
            </MenuItem>
          ))}
        </div>
      )}
    </div>
  </Menu>
);

export default OpportunityFilter;
