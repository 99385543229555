import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";
import Moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import DataGrid from "../../../shared/components/DataGrid/DataGrid";
import { AddIcon } from "../../../shared/components/Icons";
import TableSkeleton from "../../../shared/components/Skeletons/TableSkeleton";
import { getTimesheetMonthlyList } from "../../TimeSheets/Timesheet.helper";
import TeamMemberTimesheetMobileGrid from "../MobileGrid/TeamMemberTimesheetMobileGrid";
import NoRecordFound from "../NoRecordFound/NoRecordFound";
import { useStyles } from "../style";
import {
  getTeamMemberTimeSheetDetails,
  teamMemberTimesheetColoumn,
  teamMemberTimesheetRow,
} from "../TeamMemberTimesheet.helper";

const TeamMemberTimesheet = ({
  timeSheetView,
  handleLogTimeOpenClose,
  setLogTimeDate,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const calRef = useRef<any>();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const classes = useStyles();
  const [calenderData, setCalenderData] = useState<any>([]);
  const [weeklyView, setWeeklyView] = useState<number>(0);
  const [monthlyView, setMonthlyView] = useState<number>(0);
  const [timesheetName, setTimesheetName] = useState("");

  const {
    isTimesheetDetailLoading,
    timesheetDetail,
    isTimesheetDetailListLoading,
    timesheetMonthlyList,
  } = useSelector(
    //@ts-ignore
    (state) => state.timesheet
  );
  const { userInfo } = useSelector(
    //@ts-ignore
    (state) => state.login
  );

  useEffect(() => {
    const calenderList = timesheetMonthlyList?.map((data) => {
      if (data?.hours != null) {
        return {
          ...data,
          date: Moment(data.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
          textColor: "#000",
          color: data?.hours ? "#FEEFCE" : "#FDE0D0",
          title: data?.hours ? data?.hours + ` hrs` : "",
          description: data?.desc ? data?.desc : "No description",
        };
      } else {
        return {
          ...data,
          date: Moment(data.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
          textColor: "#000",
          color: data?.hours ? "#FEEFCE" : "#FDE0D0",
          description: data?.desc ? data?.desc : "No description",
          icon: <AddIcon id="in-icon" className={classes.icon} />,
        };
      }
    });
    setCalenderData(calenderList);
  }, [timesheetMonthlyList]);

  useEffect(() => {
    getTeamMemberTimeSheetDetails(dispatch, {
      zohoId: userInfo.zohoId,
    });
  }, [userInfo.zohoId, dispatch]);

  useEffect(() => {
    if (timeSheetView === "Calendar") {
      setMonthlyView(0);
      const curDate = new Date();
      const curYear = curDate.getFullYear();
      const curMonth = curDate.getMonth() + 1;
      const curFullMonth = curDate.toLocaleString("default", {
        month: "long",
      });
      const timesheetName = `${curFullMonth} - ${curYear}`;
      setTimesheetName(timesheetName);
      getTimesheetMonthlyList(dispatch, {
        zohoID: userInfo.zohoId,
        year: curYear,
        month: curMonth,
      });
    }
    if (timeSheetView === "List") {
      getTeamMemberTimeSheetDetails(dispatch, {
        zohoId: userInfo.zohoId,
      });
    }
  }, [timeSheetView, dispatch, userInfo.zohoId]);

  const handlePrev = (monthlyView) => {
    calRef.current?.getApi()?.prev();
    const curDate = new Date();
    const daysMinus = curDate.getMonth() + monthlyView;
    curDate.setMonth(daysMinus);
    const curYear = curDate.getFullYear();
    const curMonth = curDate.getMonth() + 1;
    const curFullMonth = curDate.toLocaleString("default", { month: "long" });
    const timesheetName = `${curFullMonth} - ${curYear}`;
    setTimesheetName(timesheetName);
    getTimesheetMonthlyList(dispatch, {
      zohoID: userInfo.zohoId,
      year: curYear,
      month: curMonth,
    });
  };

  const handleNext = (monthlyView) => {
    calRef.current?.getApi()?.next();
    const curDate = new Date();
    const daysMinus = curDate.getMonth() + monthlyView;
    curDate.setMonth(daysMinus);
    const curYear = curDate.getFullYear();
    const curMonth = curDate.getMonth() + 1;
    const curFullMonth = curDate.toLocaleString("default", { month: "long" });
    const timesheetName = `${curFullMonth} - ${curYear}`;
    setTimesheetName(timesheetName);
    getTimesheetMonthlyList(dispatch, {
      zohoID: userInfo.zohoId,
      year: curYear,
      month: curMonth,
    });
  };

  const handleCellClick = (cellData) => {
    navigate("/timesheets/timesheet-details", {
      state: { date: cellData.date, id: cellData.id },
    });
  };

  return (
    <>
      <Grid container component="main" p={1}>
        {isTimesheetDetailLoading ? (
          <Grid container xs={12} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              <TableSkeleton />
            </Grid>
          </Grid>
        ) : (
          <Grid container xs={12} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              {timeSheetView === "Calendar" ? (
                <Card sx={{ mt: 1 }}>
                  <CardContent sx={{ display: "flex", width: "100%" }}>
                    <Grid container xs={12} spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={7}
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          alignContent: "center",
                          alignItems: "center",
                          mr: 2,
                        }}
                      >
                        <IconButton
                          onClick={() => {
                            if (timeSheetView === "Calendar") {
                              setMonthlyView(monthlyView - 1);
                              handlePrev(monthlyView - 1);
                            } else {
                              setWeeklyView(weeklyView - 1);
                            }
                          }}
                        >
                          <ArrowBackIosIcon sx={{ ml: 1, mr: -2 }} />
                        </IconButton>
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "normal", ml: 1 }}
                        >
                          {timesheetName}
                        </Typography>
                        <IconButton
                          disabled={
                            timeSheetView === "Calendar" && monthlyView === 0
                              ? true
                              : false
                          }
                          onClick={() => {
                            if (timeSheetView === "Calendar") {
                              setMonthlyView(monthlyView + 1);
                              handleNext(monthlyView + 1);
                            } else {
                              setWeeklyView(weeklyView + 1);
                            }
                          }}
                        >
                          <ArrowForwardIosIcon sx={{ mr: 1 }} />
                        </IconButton>
                      </Grid>
                      <Grid container xs={12} sx={{ ml: 2, mr: 3, mt: 2 }}>
                        <Grid
                          item
                          xs={12}
                          sx={{ backgroundColor: "#FFF", mt: 2 }}
                          className={classes.fullCalender}
                        >
                          <FullCalendar
                            ref={calRef}
                            height={500}
                            headerToolbar={{
                              left: "",
                              center: "",
                              right: "",
                            }}
                            eventClick={(info) => {
                              setLogTimeDate(
                                dayjs(info.event.start).format("MM-DD-YYYY")
                              );
                              handleLogTimeOpenClose();
                            }}
                            eventContent={(eventInfo) => {
                              return (
                                <div>
                                  <span style={{ float: "right" }}>
                                    {eventInfo.event.title}
                                  </span>
                                  {eventInfo.event.extendedProps.icon}
                                </div>
                              );
                            }}
                            weekends={false}
                            events={calenderData}
                            themeSystem="Simplex"
                            plugins={[dayGridPlugin]}
                            initialView="dayGridMonth"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              ) : (
                <>
                  {timeSheetView === "List" &&
                    (timesheetDetail.length > 0 ? (
                      <>
                        {!isMobile ? (
                          <DataGrid
                            header={teamMemberTimesheetColoumn}
                            row={teamMemberTimesheetRow(timesheetDetail)}
                            cellClick={handleCellClick}
                          />
                        ) : (
                          <TeamMemberTimesheetMobileGrid
                            data={teamMemberTimesheetRow(timesheetDetail)}
                          />
                        )}
                      </>
                    ) : (
                      <NoRecordFound />
                    ))}
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default TeamMemberTimesheet;
