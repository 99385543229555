import { useMemo } from "react";
import { TeamifiedTask } from "../../redux/Task/TaskApi";

export const useFilteredTaskData = (data: Array<TeamifiedTask>, filters: Record<string,string>, searchText: string) => {
    return useMemo(() => {
        let copyData = [...data]
        if(filters['status'] === 'complete'){
             copyData = copyData.filter(task => task.completedAction !== null)
        }
        if(filters['status'] === 'pending'){
            copyData = copyData.filter(task => task.completedAction === null)
        }

        if(filters['priority'] === 'low'){
            copyData = copyData.filter(task => task.priority.toLowerCase() === 'low')
        }
        if(filters['priority'] === 'medium'){
            copyData = copyData.filter(task => task.priority.toLowerCase() === 'medium')
        }
        if(filters['priority'] === 'urgent'){
            copyData = copyData.filter(task => task.priority.toLowerCase() === 'urgent')
        }
        if(searchText){
            copyData = copyData.filter(task => task.title.toLowerCase().includes(searchText.toLowerCase()) || task.summary.toLowerCase().includes(searchText.toLowerCase()))
        }
        return copyData

    },[data, filters, searchText])
    
}