import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Moment from "moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import _ from "lodash";
import {usePermissions} from '../../utils/permission'

import RowSkeleton from "../../shared/components/Skeletons/RowSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import TextSkeleton from "../../shared/components/Skeletons/TextSkeleton";
import { getWeekStartAndEndDate } from "../../utils/CommonFunction";
import {
  getTimeSheetDetails,
  getTimesheetMonthlyList,
  getTimesheetWeeklyList,
  timesheetAction,
} from "./Timesheet.helper";
import { useAlert } from "react-alert";

type ApprovalDetailsProps = {};

const TimesheetDetails: React.FunctionComponent<ApprovalDetailsProps> = (
  props
) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const calRef = useRef<any>();
  const {Timesheet_ApproveAndRejectTimesheet} = usePermissions()
  
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isMobile ? "70%" : "50%",
    bgcolor: "background.paper",
    border: "1px solid #000",
    p: 4,
    boxShadow: "1px 4px 20px rgba(132, 132, 132, 0.2)",
    borderRadius: "10px",
  };
  const [showReason, setShowReason] = useState(false);
  const [reason, setReason] = useState("");
  const [showRejectReason, setShowRejectReason] = useState(false);
  const [showErrorReason, setShowErrorReason] = useState(false);
  const [calenderData, setCalenderData] = useState<any>([]);
  const [timesheetList, setTimesheetList] = useState<any>([]);
  const [weeklyView, setWeeklyView] = useState<number>(0);
  const [monthlyView, setMonthlyView] = useState<number>(0);
  const [timeSheetView, setTimeSheetView] = useState("Current");
  const [timesheetName, setTimesheetName] = useState("");
  const [timesheetDescription, setTimesheetDescription] = useState("");

  const {
    isTimesheetDetailLoading,
    timesheetDetail,
    isTimesheetDetailListLoading,
    timesheetWeeklyList,
    timesheetMonthlyList,
    timesheetActionPending,
    timesheetActionFail,
  } = useSelector(
    //@ts-ignore
    (state) => state.timesheet
  );

  const alert = useAlert()

  useEffect(() => {
    const calenderList = timesheetMonthlyList?.map((data) => {
      return {
        ...data,
        date: Moment(data.date, "MM/DD/YYYY").format("YYYY-MM-DD"),
        textColor: "#000",
        color: data?.hours ? "#FEEFCE" : "#FDE0D0",
        title: data?.hours ? data?.hours + ` hrs` : "00:00 hr",
        description: data?.desc ? data?.desc : "No description",
      };
    });
    setCalenderData(calenderList);
  }, [timesheetMonthlyList]);

  useEffect(() => {
    if(timesheetActionFail){
      alert.error('Action Failed. Please try after some time.')
    }
  },[timesheetActionFail])

  useEffect(
    useCallback(() => {
      if (timesheetDetail?.tsArrRecords?.length > 0) {
        setTimesheetList(timesheetDetail?.tsArrRecords);
      }
      setTimesheetName(timesheetDetail?.timesheetName);
    }, [timesheetDetail?.tsArrRecords]),
    [timesheetDetail?.tsArrRecords]
  );

  useEffect(
    useCallback(() => {
      if (timesheetWeeklyList) {
        setTimesheetList(timesheetWeeklyList);
      }
    }, [timesheetWeeklyList]),
    [timesheetWeeklyList]
  );

  useEffect(
    useCallback(() => {
         getTimeSheetDetails(dispatch, { timesheetRecordId: id });
    }, [id]),
    [id]
  );

  useEffect(
    useCallback(() => {
      if (timeSheetView === "Weekly") {
        setWeeklyView(0);
        const curDate = new Date();
        const { start, end } = getWeekStartAndEndDate(curDate);
        const fromDate = start
          ? Moment(new Date(start)).format("YYYY-MM-DD")
          : "";

        const endDate = start ? Moment(new Date(end)).format("YYYY-MM-DD") : "";
        const startDate = start
          ? Moment(new Date(start)).format("DD-MM-YYYY")
          : "";

        const enDate = start ? Moment(new Date(end)).format("DD-MM-YYYY") : "";
        const timesheetName = `${startDate} to ${enDate}`;
        setTimesheetName(timesheetName);
        getTimesheetWeeklyList(dispatch, {
          zohoID:timesheetDetail?.timesheetRecordOwner?.zohoID,
          fromDate: fromDate,
          toDate: endDate,
        });
      }

      if (timeSheetView === "Monthly") {
        setMonthlyView(0);
        const curDate = new Date();
        const curYear = curDate.getFullYear();
        const curMonth = curDate.getMonth() + 1;
        const curFullMonth = curDate.toLocaleString("default", {
          month: "long",
        });
        const timesheetName = `${curFullMonth} - ${curYear}`;
        setTimesheetName(timesheetName);
        getTimesheetMonthlyList(dispatch, {
          zohoID: timesheetDetail?.timesheetRecordOwner?.zohoID,
          year: curYear,
          month: curMonth,
        });
      }
      if (timeSheetView === "Current") {
        const timesheetName = timesheetDetail?.timesheetName;
        setTimesheetName(timesheetName);
        getTimeSheetDetails(dispatch, { timesheetRecordId: id });
      }
    }, [timeSheetView]),
    [timeSheetView]
  );

  useEffect(() => {
    if (timeSheetView === "Weekly") {
      var d = new Date();
      const daysMinus = d.getDate() + weeklyView * 7;
      d.setDate(daysMinus);

      const { start, end } = getWeekStartAndEndDate(d);
      const fromDate = start
        ? Moment(new Date(start)).format("YYYY-MM-DD")
        : "";

      const endDate = start ? Moment(new Date(end)).format("YYYY-MM-DD") : "";

      const startDate = start
        ? Moment(new Date(start)).format("DD-MM-YYYY")
        : "";

      const enDate = start ? Moment(new Date(end)).format("DD-MM-YYYY") : "";
      const timesheetName = `${startDate} to ${enDate}`;
      setTimesheetName(timesheetName);
      getTimesheetWeeklyList(dispatch, {
        zohoID: timesheetDetail?.timesheetRecordOwner?.zohoID,
        fromDate: fromDate,
        toDate: endDate,
      });
    }
  }, [weeklyView]);

  const handlePrev = (monthlyView) => {
    calRef.current?.getApi()?.prev();
    const curDate = new Date();
    const daysMinus = curDate.getMonth() + monthlyView;
    curDate.setMonth(daysMinus);
    const curYear = curDate.getFullYear();
    const curMonth = curDate.getMonth() + 1;
    const curFullMonth = curDate.toLocaleString("default", { month: "long" });
    const timesheetName = `${curFullMonth} - ${curYear}`;
    setTimesheetName(timesheetName);
    getTimesheetMonthlyList(dispatch, {
      zohoID: timesheetDetail?.timesheetRecordOwner?.zohoID,
      year: curYear,
      month: curMonth,
    });
  };

  const handleNext = (monthlyView) => {
    calRef.current?.getApi()?.next();
    const curDate = new Date();
    const daysMinus = curDate.getMonth() + monthlyView;
    curDate.setMonth(daysMinus);
    const curYear = curDate.getFullYear();
    const curMonth = curDate.getMonth() + 1;
    const curFullMonth = curDate.toLocaleString("default", { month: "long" });
    const timesheetName = `${curFullMonth} - ${curYear}`;
    setTimesheetName(timesheetName);
    getTimesheetMonthlyList(dispatch, {
      zohoID: timesheetDetail?.timesheetRecordOwner?.zohoID,
      year: curYear,
      month: curMonth,
    });
  };

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          <Box mt={1} mb={2} sx={{ backgroundColor: "#CCD5FC" }}>
            <Grid container spacing={2} alignItems="center">
              {isMobile ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", p: 2 }}
                    alignItems={"center"}>
                    <Link to={"/timesheets"} style={{ color: "#000" }}>
                      <ArrowBackIosIcon sx={{ ml: 2, mr: 1 }} />
                    </Link>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{
                        display: "inline-flex",
                        mx: 1,
                        width: 32,
                        height: 32,
                      }}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {timesheetDetail?.timesheetRecordOwner?.name}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "normal" }}>
                        {`${Moment(
                          timesheetDetail?.fromDate,
                          "MM/DD/YYYY"
                        ).format("ll (ddd)")} to ${Moment(
                          timesheetDetail?.toDate,
                          "MM/DD/YYYY"
                        ).format("ll (ddd)")}`}
                      </Typography>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ display: "flex", p: 2 }}
                    alignItems={"center"}>
                    <Link to={"/timesheets"} style={{ color: "#000" }}>
                      <ArrowBackIosIcon sx={{ ml: 2, mr: 1 }} />
                    </Link>
                    {isTimesheetDetailLoading ? (
                      <Grid item xs={6}>
                        <TextSkeleton />
                      </Grid>
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "normal" }}>
                        {timesheetDetail?.timesheetRecordOwner?.name}
                      </Typography>
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ display: "flex", p: 2 }}
                    justifyContent="flex-end"
                  >
                    {isTimesheetDetailLoading ? (
                      <Grid item xs={6}>
                        <TextSkeleton />
                      </Grid>
                    ) : (
                      <Typography
                        variant="h6"
                        sx={{ fontWeight: "normal", mr: 2 }}
                      >
                        {`Project Manager: ${timesheetDetail?.timesheetApproverInfo?.name}`}
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {isTimesheetDetailLoading ? (
            <Grid container xs={12} sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={12}>
                <RowSkeleton rowHeight={150} />
              </Grid>
            </Grid>
          ) : (
            <Grid container xs={12} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <Card>
                  <CardContent sx={{ display: "flex", width: "100%" }}>
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={12} sm={6} md={5} sx={{ mt: -4 }}>
                        <Grid container xs={12}>
                          <Grid item xs={12} sm={6} md={6}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                sx={{
                                  ml: -2,
                                  textAlign: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src="/images/time.png"
                                  style={{
                                    width: "100%",
                                    textAlign: "center",
                                    alignItems: "center",
                                  }}
                                  alt="time"
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={8}
                                sx={{ mt: 3, ml: 2 }}
                              >
                                <Box>
                                  <Typography
                                    variant="body2"
                                    sx={{ fontWeight: "600" }}
                                  >
                                    {"Total Hours"}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography variant="h6">
                                    {timesheetDetail.totalHours}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ mb: 1 }}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                              <Grid item xs={12} sm={6} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: "600" }}
                                >
                                  {`Started on `}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="caption">
                                  {timesheetDetail?.fromDate
                                    ? Moment(timesheetDetail?.fromDate).format(
                                        "YYYY-MM-DD"
                                      )
                                    : ""}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                              <Grid item xs={12} sm={6} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ fontWeight: "600" }}
                                >
                                  {"Project name"}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Typography variant="caption">
                                  {timesheetDetail?.projectName}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={6} md={7} sx={{ mt: -1 }}>
                        <Grid container xs={12}>
                          <Grid item xs={12} sx={{ display: "flex", mb: 2 }}>
                            <Grid container xs={12} sx={{ display: "flex" }}>
                              <Grid item xs={12} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ mb: 2, fontWeight: "600" }}
                                >
                                  {"Client"}
                                </Typography>
                                <Typography variant="caption">
                                  {timesheetDetail?.client}
                                </Typography>
                              </Grid>
                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ width: "1px", height: 60, mr: 5 }}
                              />
                              <Grid item xs={12} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ mb: 2, fontWeight: "600" }}
                                >
                                  {"Approver"}
                                </Typography>
                                <Typography variant="caption">
                                  {timesheetDetail?.timesheetApproverInfo?.name}
                                </Typography>
                              </Grid>

                              <Divider
                                orientation="vertical"
                                flexItem
                                sx={{ width: "1px", height: 60, mr: 5 }}
                              />

                              <Grid item xs={12} md={3}>
                                <Typography
                                  variant="body2"
                                  sx={{ mb: 2, fontWeight: "600" }}
                                >
                                  {"Billing status"}
                                </Typography>
                                <Typography variant="caption">
                                  {timesheetDetail?.status}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={12} sx={{ display: "flex" }}>
                            <Grid item xs={12} sm={6} md={2}>
                              <Typography variant="caption">
                                {"Description"}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={10} sx={{ pr: 3 }}>
                              <Typography variant="caption">
                                {timesheetDetail?.description}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
          {isTimesheetDetailLoading ? (
            <Grid container xs={12} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <TableSkeleton />
              </Grid>
            </Grid>
          ) : (
            <Grid container xs={12} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <Card>
                  <CardContent sx={{ display: "flex", width: "100%" }}>
                    <Grid container xs={12} spacing={2}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={7}
                        sx={{
                          display: "flex",
                          textAlign: "center",
                          alignContent: "center",
                          alignItems: "center",
                          mr: 2,
                        }}
                      >
                        {(timeSheetView === "Monthly" ||
                          timeSheetView === "Weekly") && (
                          <IconButton
                            onClick={() => {
                              if (timeSheetView === "Monthly") {
                                setMonthlyView(monthlyView - 1);
                                handlePrev(monthlyView - 1);
                              } else {
                                setWeeklyView(weeklyView - 1);
                              }
                            }}
                          >
                            <ArrowBackIosIcon sx={{ ml: 1, mr: -2 }} />
                          </IconButton>
                        )}
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "normal", ml: 1 }}
                        >
                          {timesheetName}
                        </Typography>
                        {(timeSheetView === "Monthly" ||
                          timeSheetView === "Weekly") && (
                          <IconButton
                            disabled={
                              timeSheetView === "Monthly" && monthlyView === 0
                                ? true
                                : timeSheetView === "Weekly" && weeklyView === 0
                                ? true
                                : false
                            }
                            onClick={() => {
                              if (timeSheetView === "Monthly") {
                                setMonthlyView(monthlyView + 1);
                                handleNext(monthlyView + 1);
                              } else {
                                setWeeklyView(weeklyView + 1);
                              }
                            }}
                          >
                            <ArrowForwardIosIcon sx={{ mr: 1 }} />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={{
                          display: "flex",
                        }}
                      >
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={timeSheetView}
                            onChange={(e) => {
                              setTimeSheetView(e.target.value);
                            }}
                          >
                            <FormControlLabel
                              value="Current"
                              control={<Radio />}
                              label="Current"
                            />
                            <FormControlLabel
                              value="Weekly"
                              control={<Radio />}
                              label="View Weekly"
                            />
                            <FormControlLabel
                              value="Monthly"
                              control={<Radio />}
                              label="View Monthly"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>

                      <Grid container xs={12} sx={{ ml: 2, mr: 3, mt: 2 }}>
                        {(timeSheetView === "Current" ||
                          timeSheetView === "Weekly") &&
                          (timesheetList.length > 0 ? (
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                              >
                                <TableBody>
                                  {timesheetList?.map((row) => (
                                    <TableRow
                                      key={row.workDate}
                                      sx={{
                                        td: {
                                          border: 1,
                                          borderColor: "#FFD96A",
                                        },
                                      }}
                                    >
                                      <TableCell
                                        colSpan={2}
                                        sx={{
                                          borderColor: "#FFD96A",
                                        }}
                                      >
                                        {`${row.day} I ${
                                          row?.workDate
                                            ? Moment(row?.workDate).format(
                                                "YYYY-MM-DD"
                                              )
                                            : ""
                                        }`}
                                      </TableCell>
                                      <TableCell
                                        colSpan={6}
                                        sx={{
                                          borderColor: "#FFD96A",
                                        }}
                                      >
                                        {row.desc}
                                      </TableCell>
                                      <TableCell
                                        colSpan={2}
                                        sx={{ borderColor: "#FFD96A" }}
                                      >
                                        {row.hours}
                                        {parseInt(row.hours) > 1
                                          ? ` hrs`
                                          : ` hr`}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          ) : (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                justifyItems: "center",
                                alignItems: "center",
                                justifyContent: "center",
                                justifySelf: "center",
                                alignContent: "center",
                                alignSelf: "center",
                                textAlign: "center",
                              }}
                            >
                              <Typography
                                variant="h6"
                                sx={{
                                  justifyItems: "center",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  justifySelf: "center",
                                  alignContent: "center",
                                  alignSelf: "center",
                                  textAlign: "center",
                                }}
                              >
                                {"No Record found"}
                              </Typography>
                            </Box>
                          ))}
                        {timeSheetView === "Monthly" && (
                          <Grid
                            item
                            xs={12}
                            sx={{ backgroundColor: "#FFF", mt: 2 }}
                          >
                            <FullCalendar
                              ref={calRef}
                              height={500}
                              headerToolbar={{
                                left: "",
                                center: "",
                                right: "",
                              }}
                              eventClick={(e) => {
                                setTimesheetDescription(
                                  e.event.extendedProps.description
                                );
                                setShowReason(true);
                              }}
                              events={calenderData}
                              themeSystem="Simplex"
                              plugins={[dayGridPlugin]}
                              initialView="dayGridMonth"
                            />
                          </Grid>
                        )}
                      </Grid>
                      {timesheetActionPending &&
                        timesheetDetail?.status === "Pending" &&
                        timeSheetView === "Current" && (
                          <RowSkeleton rowHeight={50} />
                        )}
                      {!timesheetActionPending &&
                        timesheetDetail?.status === "Pending" &&
                        timeSheetView === "Current" && (
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              pb: 1,
                              mt: 2,
                              mr: 3,
                              justifyContent: "flex-end",
                            }}
                          >
                            {Timesheet_ApproveAndRejectTimesheet && <>
                            
                            <Button
                              variant="contained"
                              sx={{
                                marginRight: 5,
                                backgroundColor: "#00E08C",
                              }}
                              onClick={() => {
                                const request = {
                                  timesheetID: id,
                                  status: "1",
                                  remark: "",
                                  id: id,
                                  client: timesheetDetail?.client,
                                };
                                timesheetAction(dispatch, request);
                              }}
                            >
                              {"Accept"}
                            </Button>
                            <Button
                              variant="contained"
                              sx={{ backgroundColor: "#E61A1A" }}
                              onClick={() => setShowRejectReason(true)}
                            >
                              {"Reject"}
                            </Button>
                            </>}
                          </Box>
                        )}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </Container>
        <Modal
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={showReason}
          onClose={() => {
            setShowReason(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              mb={2}
            >
              Description
            </Typography>

            <Typography variant="caption">{timesheetDescription}</Typography>

            <Box
              sx={{
                display: "flex",
                pb: 1,
                pt: 2,
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="text"
                sx={{ marginRight: 5 }}
                onClick={() => setShowReason(false)}
              >
                {"Cancel"}
              </Button>
            </Box>
          </Box>
        </Modal>

        <Modal
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          open={showRejectReason}
          onClose={() => {
            setShowRejectReason(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              mb={2}
            >
              Mention reason
            </Typography>
            <TextField
              margin="normal"
              fullWidth
              multiline
              label={`Enter Reason`}
              name="reason"
              variant="outlined"
              autoComplete="off"
              value={reason}
              onChange={(e) => {
                setReason(e.target.value);
                setShowErrorReason(false);
              }}
              inputProps={{
                style: {
                  minHeight: 150,
                  maxHeight: 150,
                },
              }}
            />
            {showErrorReason && (
              <Typography variant="caption" sx={{ color: "red" }}>
                {"Please enter the reason"}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                pb: 1,
                pt: 2,
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="text"
                sx={{ marginRight: 5 }}
                onClick={() => setShowRejectReason(false)}
              >
                {"Cancel"}
              </Button>
              <Button
                variant="contained"
                sx={{ backgroundColor: "#E61A1A" }}
                onClick={() => {
                  if (!_.isEmpty(reason)) {
                    const request = {
                      timesheetID: id,
                      status: "0",
                      remark: reason,
                      id: id,
                      client: timesheetDetail?.client,
                    };
                    timesheetAction(dispatch, request);
                    setShowRejectReason(false);
                  } else {
                    setShowErrorReason(true);
                  }
                }}
              >
                {"Reject and save reason"}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Grid>
    </>
  );
};

export default TimesheetDetails;
