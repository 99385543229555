import { Grid, Typography } from "@mui/material"
import moment from "moment"
import { memo } from "react"
import AssigneeUser from "./Components/AssigneeUser"
import PriorityBadge from "./Components/PriorityBadge"
import TaskButton from "./Components/TaskButton"

const TaskRowHeaderDetails = ({ task, refetchTasks }) => {
    const actions = task.actionsJson
    let parsedActions: any = []

    if (actions) {
        parsedActions = JSON.parse(actions)
    }
    let action1: any = null;
    let action2: any = null;

    if (Array.isArray(parsedActions) && parsedActions.length) {
        action1 = parsedActions[0]
        action2 = parsedActions[1]
    }
    else if (typeof parsedActions === 'object' && Object.keys(parsedActions).length > 0) {
        action1 = parsedActions[0]
    }


    return (
        <Grid container item alignItems={'center'} px={1} spacing={2}>
            <Grid item xs={3}>
                <Typography variant="h5">{task.title}</Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography variant="body1">{moment(task.createdAt ? task.createdAt : undefined).format('DD-MM-YYYY')}</Typography>

            </Grid>
            <Grid item xs={2}>
                <AssigneeUser assigneeUser={task.assigneeUser} handleReporterClick={() => {
                }} />
            </Grid>
            <Grid item xs={1}>
                <PriorityBadge badgeType={task.priority.toLowerCase()} />
            </Grid>
            {!task.completedAction ? <Grid container item xs={4} gap={1} justifyContent={'flex-end'}>
                {action1 && <TaskButton task={task} action={action1} />}
                {action2 && <TaskButton task={task} action={action2} />}

            </Grid>:<Grid container item xs={4} gap={1} justifyContent={'flex-end'}>
                <Typography color={'#A16AE8'} fontWeight={500} variant="body1">Task Completed</Typography>
            </Grid> }
            
        </Grid>
    )
}

export default memo(TaskRowHeaderDetails)