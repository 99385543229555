import { Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useGetCandidateListQuery } from "../../redux/Hire/candidateSlice";
import {
  clearCandidateSuccess,
  commentAddedSuccessfully
} from "../../redux/Hire/hireSlice";
import api from "../../services/BaseApi";
import { STRINGS } from "../../Strings";
import CandidateDocuments from "./CandidateDocuments";
import CandidateInfo from "./CandidateInfo";
import CandidateInterviewDetails from "./CandidateInterviewDetails";
import CandidateScrollingList from "./CandidateScrollingList";
import {
  getCandidateDataByApiId,
  getHireCandidateStagingHelper
} from "./Hire.helper";
const CandidateListBody = ({
  jobId,
  selectedStage,
  setSelectedStage,
  tabValue,
}) => {
  //@ts-ignore
  const {
    candidateDetailPending,
    candidateDetail,
    isCommentAdded,
    moveCandidate,
    moveCandidateError,
    hireJobsDetail
  } = useSelector(
    //@ts-ignore
    (state) => state.hire
  );



  const dispatch = useDispatch();
  const alert = useAlert();
  const [selectedCandidate, setSelectedCandidate] = useState<any>(null);
  const [showComment, setShowComment] = useState(false);
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [selectedPage, setSelectedPage] = useState(0);
  const [candidateCVLoading, setCandidateCVLoading] = useState(false);
  const [cvDetails, setCVDetails] = useState<any>(null);
  const isNotApplied = selectedStage?.code !== 'APPLIED'

  const {data: candidateListData, isLoading, isError, isSuccess, isFetching, refetch: candidateListRefetch, isUninitialized} = useGetCandidateListQuery({
    JobId: jobId,
    JobPipeLineCode: selectedStage?.code,
    PageNumber: selectedPage,
    PageSize: 10,
  },{
    skip: !selectedStage,
    refetchOnMountOrArgChange: true
  })


useEffect(() => {
  if(selectedStage?.code){
    setSelectedPage(0)
  }
},[selectedStage])







  useEffect(() => {
    if (selectedCandidate?.candidate.id && isNotApplied) {
      fetchCandidateResume()
    }
  }, [selectedCandidate?.candidate.id])


  const fetchCandidateResume = async () => {
    setCandidateCVLoading(true);
    try {
      const response = await api.post(`${process.env.REACT_APP_RECRUITLY_URL}getCandidateCVDetails`, {
        CandidateId: selectedCandidate?.candidate.id
      })
      setCVDetails(response.data)

    }
    catch (e) {
      console.log(e)
    }
    setCandidateCVLoading(false);

  }

  const strip = useCallback((html) => {
    var doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }, [])
  const showDetails = (candidate) => {
    setSelectedCandidate(candidate);
    setShowComment(false);
    setShowCommentBox(false);
  };


  useEffect(() => {
    if (moveCandidate) {
      getHireCandidateStagingHelper(jobId, dispatch);
      if(!isUninitialized){
        candidateListRefetch()

      }
      dispatch(clearCandidateSuccess())
    }
  }, [moveCandidate]);

  useEffect(() => {
    if (moveCandidateError) {
      alert.error(moveCandidateError);
      
      dispatch(clearCandidateSuccess());
    }
  }, [moveCandidateError]);

  useEffect(() => {
    if (selectedCandidate && selectedStage?.code !== "APPLIED") {
      getCandidateDataByApiId(
        {
          CandidateRef: selectedCandidate.candidate.reference,
        },
        dispatch
      );
    }
  }, [selectedCandidate]);

  useEffect(() => {
    if (isCommentAdded) {
      alert.success("Comment has been posted successfully");
      dispatch(commentAddedSuccessfully());
    }
  }, [isCommentAdded]);

    useEffect(() => {
    if (isSuccess && candidateListData.data.data.length) {
      setSelectedCandidate(candidateListData.data.data[0]);
    }
  }, [candidateListData]);


  const onScrollCandidateList = (event) => {
    if (!isLoading) {
      var element = event.target;
      if (
        Math.ceil(element.scrollHeight) - Math.ceil(element.scrollTop) ===
        Math.ceil(element.clientHeight)
      ) {
        if ((candidateListData?.data?.data.length || 0) < (candidateListData?.data.totalCount || 0)) {
          setSelectedPage((selectedPage) => selectedPage + 1);
        }
      }
    }
  };

  const internalCode = hireJobsDetail?.udfList?.find(el => el.key.label === 'Internal Code')?.value.textValue







  return isSuccess && (candidateListData?.data.data || []).length > 0 ? (
    <>
      <Grid container display={"flex"} spacing={1}>
        <CandidateScrollingList
          candidates={(candidateListData?.data.data || [])}
          isCandidateListLoading={isLoading}
          moreCandidateListLoading={isFetching}
          showCandidate={showDetails}
          selectedCandidate={selectedCandidate}
          onScrollCandidateList={onScrollCandidateList}
        />
        {tabValue === 1 && (
          <CandidateInterviewDetails
            cvDetails={cvDetails}
            selectedStage={selectedStage}
            jobId={jobId}
            setSelectedStage={setSelectedStage}
            candidateId={selectedCandidate?.candidate?.id}
            jobDescription={strip(hireJobsDetail.description)}
            internalCode={internalCode}
            candidateEmail={selectedCandidate?.candidate.email}
          />
        ) } 
        {tabValue === 0 && (
          <CandidateInfo
            candidateId={selectedCandidate?.candidate.id}
            setShowCommentBox={setShowCommentBox}
            candidateDetail={candidateDetail}
            selectedCandidate={selectedCandidate}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            candidateDetailPending={candidateDetailPending}
            showComment={showComment}
            showCommentBox={showCommentBox}
            setShowComment={setShowComment}
            cvDetails={cvDetails}
            isNotApplied={isNotApplied}
          />
        )}
        {
          tabValue === 2 && (
            <CandidateDocuments candidateId={selectedCandidate?.candidate.id} candidateName={selectedCandidate?.candidate.label}></CandidateDocuments>
          )
        }
      </Grid>
     
    </>
  ) : (
    <Grid
      spacing={"10px"}
      alignItems="center"
      mt={"2rem"}
      display={"flex"}
      justifyContent={"center"}
    >
      <Typography variant="h5">{STRINGS.NoRecordFound}</Typography>
    </Grid>
  );
};

export default CandidateListBody;
