import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginSchema } from "../../../schemas";
import { useStyles } from "./styles";

import { useDispatch, useSelector } from "react-redux";

//styles
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { STRINGS } from "../../../Strings";
import { clearError } from "../../../redux/Login/loginSlice";
import { isValidRedirect } from "../../../utils/CommonFunction";
import { loginAction } from "./Login.helper";

const Login = () => {
  const initialValues = {
    Email: "",
    Password: "",
  };
  const dispatch = useDispatch();
  //@ts-ignore
  const { isAuth, error } = useSelector((state) => state.login);
  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const classes = useStyles();
  const alert = useAlert();

  const navigate = useNavigate();
  const location = useLocation();
  const redirectPath = location.state?.from || "/dashboard";

  useEffect(() => {
    const form = document.getElementById("myForm");
    setTimeout(() => {
      //@ts-ignore
      form.reset();
    }, 1000);
  }, []);

  useEffect(() => {
    if (isAuth && error === "") {
      setIsLoginLoading(false);
      if (isValidRedirect(redirectPath)) {
        navigate(redirectPath, { replace: true });
      } else {
        navigate("/dashboard", { replace: true });
      }
    }
  },
    [isAuth]
  );
  useEffect(() => {
    if (!isAuth && error !== "") {
      alert.error(error);
      setIsLoginLoading(false);
      dispatch(clearError());
    }
  },
    [error]
  );

  const {
    values,
    errors,
    touched,
    dirty,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: loginSchema,

    onSubmit: (valu̥es, action) => {
      setIsLoginLoading(true);
      loginAction(valu̥es, dispatch);
      action.resetForm();
    },
  });

  return (
    <Container maxWidth="xl" disableGutters={true}>
      <Grid
        container
        component="main"
        spacing={0}
        direction="row"
        alignItems={{
          xs: "flex-start",
          // sm: "center",
        }}
        justifyContent="center"
        bgcolor={{
          xs: "#fff",
        }}
        padding={{
          xs: "1rem 1rem",
          sm: "0",
        }}
        height={{ xs: "100vh" }}
      >
        <Grid
          container
          item
          xs={12}
          sm={4}
          md={6}
          flexDirection={"column"}
          wrap={"nowrap"}
          height={{
            xs: "auto",
            sm: "100%",
          }}>
          <Grid item flex={1}>
            <Box component='img'
              src="/images/Teamified-Logo-Black.svg"
              className="logo_rbl"
              sx={{
                maxWidth: '-webkit-fill-available',
                padding: {
                  md: '24px 0px 0px 43px',
                  sm: '24px 0px'
                }
              }}
              alt="Teamified logo"
            />
          </Grid>
          <Grid
            item
            flex={11}
            sx={{
              display: { xs: "none", sm: "block" },
              textAlign: "center",
              background: 'url("/images/login-banner.svg")',
              border: "2rem solid #fff",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}></Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          className={classes.right_column}
          padding={{
            xs: "2rem",
          }}
          height={{
            xs: "auto",
            sm: "100%",
          }}
          borderRadius={{
            xs: "1rem",
            sm: 0,
          }}>
          <Box>
            <Box>
              <Typography variant="h3" align="center" mb={3}>
                {STRINGS.ContinueBuildingTeam}
              </Typography>
            </Box>
            <Box>
              <form
                id="myForm"
                style={{ display: "block", maxWidth: "480px", margin: "auto" }}
                noValidate
                onSubmit={handleSubmit}
                autoComplete="off">
                <TextField
                  error={Boolean(touched.Email && errors.Email)}
                  helperText={touched.Email && errors.Email}
                  margin="normal"
                  fullWidth
                  id="Email"
                  label="Email"
                  name="Email"
                  variant="outlined"
                  autoComplete="off"
                  value={values.Email || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoginLoading}
                />
                <TextField
                  error={Boolean(touched.Password && errors.Password)}
                  helperText={touched.Password && errors.Password}
                  margin="normal"
                  fullWidth
                  type="password"
                  id="Password"
                  label="Password"
                  name="Password"
                  variant="outlined"
                  autoComplete="off"
                  value={values.Password || ""}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={isLoginLoading}
                />
                <Typography variant="body2" align="right" mb={3}>
                  <Link to="/forgot-password">{STRINGS.ForgotPassword}</Link>
                </Typography>
                <Button
                  disabled={!dirty}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                  sx={{ mt: 2, fontWeight: 500 }}>
                  {isLoginLoading && (
                    <CircularProgress size={18} color="warning" />
                  )}
                  {!isLoginLoading && STRINGS.LogIn}
                </Button>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
