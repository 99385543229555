import { Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import Moment from "moment";
import { dateFormat, rowPerPage } from "../../Constants";
import { People } from "../../Types/People/People";
import {
  memberAdded,
  memberAddedUpdateError,
} from "../../redux/People/peopleSlice";
import { sagaActions } from "../../redux/sagaActions";
import { userAccess } from "../../utils/CommonFunction";

export const getZohoDesignations = async (dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.FETCH_ZOHO_DESIGNATION });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getAllMemberList = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      load: 0,
      isDeleted: false,
    };
    dispatch({ type: sagaActions.FETCH_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getLeaveRecord = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.GET_APPROVAL_DATA, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getMemberTimesheetRecord = async (
  payload,
  dispatch: Dispatch<any>
) => {
  try {
    dispatch({ type: sagaActions.MEMBER_TIMESHEET_ACTION, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getMemberInvoiceRecord = async (
  payload,
  dispatch: Dispatch<any>
) => {
  try {
    dispatch({ type: sagaActions.MEMBER_INVOICE_ACTION, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getAllDeleteMemberList = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      load: 0,
      isDeleted: true,
    };
    dispatch({ type: sagaActions.FETCH_DELETE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const filterMemberList = async (
  clientId,
  tab,
  dispatch: Dispatch<any>
) => {
  try {
    const payload = {
      employeeType: clientId === "All" ? "" : clientId,
      isDeleted: tab === 0 ? false : true,
    };

    tab === 0
      ? dispatch({ type: sagaActions.FETCH_MEMBER, payload: payload })
      : dispatch({ type: sagaActions.FETCH_DELETE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getSelectPageDeleteMemberList = async (
  page,
  dispatch: Dispatch<any>,
  SearchValue = "",
  tab = 0,
  sort = false,
  columnName = "",
  pageSize = rowPerPage
) => {
  try {
    const payload = {
      pageNumber: page,
      searchValue: SearchValue,
      isDeleted: tab ? true : false,
      isDesc: sort,
      columnName: columnName,
      pageSize,
      load: 0,
    };
    tab === 0
      ? dispatch({ type: sagaActions.FETCH_MEMBER, payload: payload })
      : dispatch({ type: sagaActions.FETCH_DELETE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getSelectPageRowList = async (
  pageSize = rowPerPage,
  dispatch: Dispatch<any>,
  tab = 0
) => {
  try {
    const payload = {
      load: 0,
      pageSize,
      isDeleted: tab ? true : false,
    };
    tab === 0
      ? dispatch({ type: sagaActions.FETCH_MEMBER, payload: payload })
      : dispatch({ type: sagaActions.FETCH_DELETE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const saveMember = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.SAVE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const disableMember = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.DISABLE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const enableMember = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.ENABLE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const updateMember = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.UPDATE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const removeMember = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.REMOVE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const restoreMember = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.RESTORE_MEMBER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const saveSuccessFully = (dispatch: Dispatch<any>) => {
  dispatch(memberAdded(false));
};

export const resetMemberError = (dispatch: Dispatch<any>) => {
  dispatch(memberAddedUpdateError(false));
};

export const getAllSkills = async (dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.GET_SKILLS });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getMemberById = async (
  id,
  patonaMember = true,
  dispatch: Dispatch<any>
) => {
  try {
    const payload = {
      id: id,
      isPatonaTeamMember: patonaMember,
      removedReason: "",
      removedDescription: "",
    };
    dispatch({ type: sagaActions.GET_MEMBER_BY_ID, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const memberGridHeader = (client) => {
  const { isTeamMember } = userAccess();
  const columns = [
    {
      id: 1,
      name: "Name",
      soryKey: "Name",
      type: "heading",
      sortable: true,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Email Id",
      soryKey: "JobTitle",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Job title",
      soryKey: "JobTitle",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: client ? "Employment type" : "Assigned to",
      soryKey: client ? "employmenttype" : "Client",
      type: "heading",
      display: !isTeamMember,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "salary P.A.",
      soryKey: "Salary",
      type: "heading",
      display: !isTeamMember,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 6,
      name: "Started date",
      soryKey: "JoiningDate",
      type: "heading",
      display: !isTeamMember,
      sortable: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 7,
      name: "",
      type: "heading",
      display: !isTeamMember,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const memberGridRow = (rowValue: Array<People>, client) => {
  const { isTeamMember } = userAccess();
  const row = rowValue
    .filter((filterMember) => !filterMember.isDeleted)
    ?.map((rowV) => {
      let columns: any = [];
      columns.push(
        {
          id: rowV.teamMemberId,
          name: rowV.firstName ? rowV.firstName + " " + rowV.lastName : "-",
          imageName: rowV.profilePhotoURL ? rowV.profilePhotoURL : "-",
          imageUrl: rowV.profilePhotoURL
            ? imageReturnFromZoho(rowV.profilePhotoURL)
            : "",
          type: "title",
          experience: false,
          display: true,
          avatar: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.teamMemberId,
          name: rowV?.emailAddress ? rowV?.emailAddress : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
        },
        {
          id: rowV.teamMemberId,
          name: rowV?.jobTitle ? rowV?.jobTitle : "-",
          type: "text",
          experience:
            !_.isNull(rowV?.experience) && rowV?.experience > 0 ? true : false,
          display: true,
          experienceLevel: rowV?.experience,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.teamMemberId,
          name: client
            ? rowV.employeeType
              ? rowV.employeeType
              : "-"
            : rowV.companyName
            ? rowV.companyName
            : "-",
          type: "text",
          experience: false,
          display: !isTeamMember,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.teamMemberId,
          name: rowV?.salaryPA ? `$${rowV?.salaryPA}` : "-",
          type: "text",
          experience: false,
          display: !isTeamMember,
          editable: true,
          editableType: "number",
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.teamMemberId,
          name: rowV?.joiningDate
            ? Moment(rowV?.joiningDate).format(dateFormat)
            : "-",
          type: "text",
          experience: false,
          display: !isTeamMember,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.teamMemberId,
          name: "",
          type: "action",
          experience: false,
          display: !isTeamMember,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
          peopleId: rowV?.teamMemberId,
          disable: rowV?.isDisabled,
          isPatonaTeamMember: rowV?.isPatonaTeamMember,
        }
      );
      return { ...rowV, columns, details: rowV };
    });

  return row;
};

export const memberDeletedGridHeader = () => {
  const columns = [
    {
      id: 1,
      name: "Name",
      soryKey: "Name",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Email Id",
      soryKey: "JobTitle",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Job title",
      soryKey: "JobTitle",
      display: true,
      type: "heading",
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Assigned to client",
      soryKey: "Client",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "salary P.A.",
      soryKey: "Salary",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 6,
      name: "joining date",
      soryKey: "JoiningDate",
      type: "heading",
      display: true,
      sortable: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 7,
      name: "",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const deletedMemberGridRow = (rowValue: Array<People>) => {
  const row = rowValue
    .filter((filterMember) => filterMember.isDeleted)
    ?.map((rowV) => {
      let columns: any = [];
      columns.push(
        {
          id: rowV.teamMemberId,
          name: rowV.firstName ? rowV.firstName + " " + rowV.lastName : "-",
          imageName: rowV.profilePhotoURL ? rowV.profilePhotoURL : "-",
          imageUrl: rowV.profilePhotoURL ? rowV.profilePhotoURL : "-",
          type: "title",
          experience: false,
          display: true,
          avatar: true,
          sortable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
          dblClick: false,
        },
        {
          id: rowV.teamMemberId,
          name: rowV?.emailAddress ? rowV?.emailAddress : "-",
          type: "text",
          experience: false,
          display: true,
          editable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
        },
        {
          id: rowV.teamMemberId,
          name: rowV?.jobTitle ? rowV?.jobTitle : "-",
          type: "text",
          experience: true,
          display: true,
          experienceLevel: rowV?.experience,
          sortable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
          dblClick: false,
        },
        {
          id: rowV.teamMemberId,
          name: rowV.companyName ? rowV.companyName : "-",
          type: "text",
          experience: false,
          display: true,
          sortable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
          dblClick: false,
        },
        {
          id: rowV.teamMemberId,
          name: rowV?.salaryPA ? `$${rowV?.salaryPA}` : "-",
          type: "text",
          experience: false,
          display: true,
          sortable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
          dblClick: true,
        },
        {
          id: rowV.teamMemberId,
          name: rowV?.joiningDate
            ? Moment(rowV?.joiningDate).format(dateFormat)
            : "-",
          type: "text",
          experience: false,
          display: true,
          sortable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
          dblClick: false,
        },
        {
          id: rowV.teamMemberId,
          name: "",
          type: "action",
          experience: false,
          display: true,
          sortable: false,
          color: "#000000",
          fontSize: 12,
          fontWeight: "400",
          textTransform: "capitalize",
          peopleId: rowV?.teamMemberId,
          dblClick: false,
        }
      );
      return { ...rowV, columns, details: rowV };
    });

  return row;
};

export const getBase64Download = (file: string, b64: Blob) => {
  const fileExtension = file.split(".").pop();
  let data = ``;
  switch (fileExtension) {
    case "docx":
      data = `data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,`;
      break;
    case "doc":
      data = `data:application/msword;base64,`;
      break;
    case "pdf":
      data = `data:application/pdf;base64,`;
      break;
  }
  const linkSource = data + b64;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = file;
  downloadLink.click();
};

export const fileSet = (
  e,
  setFileName,
  setFile,
  alert,
  setIsResumeUpdated?
) => {
  e.preventDefault();
  let reader = new FileReader();
  let file = e.target.files[0];
  if (!/(\.doc|\.docx|\.pdf)$/i.test(file.name)) {
    alert.error("Expected file type .doc,.docx, .pdf");
    return false;
  }
  reader.onloadend = () => {
    setFileName(file.name);
    if (!!reader) {
      // @ts-ignore
      const base64String = reader?.result
        // @ts-ignore
        ?.replace("data:", "")
        ?.replace(/^.+,/, "");
      setFile(base64String);
      setIsResumeUpdated(true);
    }
  };
  reader.readAsDataURL(file);
};

export const scrollToTop = (setShowfloat) => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  setShowfloat(false);
};

export const patonaMember = (value, setIsPatonaMember) => {
  setIsPatonaMember((value) => value);
};

export const currentlyWorkingHeader = () => {
  const columns = [
    {
      id: 1,
      name: "Job request",
      soryKey: "jobrequest",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Client",
      soryKey: "client",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Start date",
      soryKey: "startdate",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Employment type",
      soryKey: "employmenttype",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const currentlyWorkingRow = (rowValue: Array<People>) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.teamMemberId,
        name: rowV.jobTitle ? rowV.jobTitle : "-",
        type: "text",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.teamMemberId,
        name: rowV?.companyName ? rowV?.companyName : "-",
        type: "text",
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.teamMemberId,
        name: rowV?.joiningDate
          ? Moment(rowV?.joiningDate).format(dateFormat)
          : "-",
        type: "text",
        experience: true,
        display: true,
        experienceLevel: rowV?.experience,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.teamMemberId,
        name: rowV.employeeType ? rowV.employeeType : "-",
        type: "text",
        experience: false,
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      }
    );
    return { ...rowV, columns };
  });

  return row;
};

export const employHistoryHeader = () => {
  const columns = [
    {
      id: 1,
      name: "Job title",
      soryKey: "jobtitle",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Company",
      soryKey: "company",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Start date",
      soryKey: "startdate",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "End date",
      soryKey: "enddate",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const employHistoryRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.id,
        name: rowV.jobtitle ? rowV.jobtitle : "-",
        type: "text",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV?.employer ? rowV?.employer : "-",
        type: "text",
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV?.fromDate ? Moment(rowV?.fromDate).format(dateFormat) : "-",
        type: "text",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.id,
        name: rowV.todate ? Moment(rowV?.todate).format(dateFormat) : "-",
        type: "text",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      }
    );
    return { ...rowV, columns };
  });

  return row;
};

export const leaveRecordsHeader = () => {
  const columns = [
    {
      id: 1,
      name: "Type of leave",
      soryKey: "typeofleave",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Created leave",
      soryKey: "createdleaves",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Leave taken",
      soryKey: "leavestaken",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Closing balance",
      soryKey: "closingbalance",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "Upcoming leave",
      soryKey: "upcomingleave",
      type: "heading",
      display: true,
      sortable: false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const leaveRecordRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.color,
        name: rowV.name ? rowV.name : "-",
        type: "text",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.color,
        name: rowV?.creditedBalance ? rowV?.creditedBalance : "-",
        type: "text",
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.color,
        name: rowV?.leavesTaken ? rowV?.leavesTaken : "-",
        type: "text",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.color,
        name: rowV.closingBalance ? rowV.closingBalance : "-",
        type: "text",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.color,
        list: rowV.bookedLeaves?.map(({ date, status }) => ({
          name: date,
          value: status,
        })),
        colSpan: 2,
        type: "objectarray",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      }
    );
    return { ...rowV, columns };
  });

  return row;
};

export const emergencyContactHeader = () => {
  const columns = [
    {
      id: 1,
      name: "Number",
      soryKey: "number",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Name",
      soryKey: "name",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Relationship",
      soryKey: "relationship",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const emergencyContactRow = (rowValue: Array<any>) => {
  const row = rowValue?.map((rowV) => {
    let columns: any = [];
    columns.push(
      {
        id: rowV.contact_Number,
        name: rowV.contact_Number ? rowV.contact_Number : "-",
        type: "text",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.contact_Number,
        name: rowV?.emergency_Contact_Name ? rowV?.emergency_Contact_Name : "-",
        type: "text",
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.contact_Number,
        name: rowV?.relationship ? rowV?.relationship : "-",
        type: "text",
        display: true,
        sortable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      }
    );
    return { ...rowV, columns };
  });

  return row;
};

export const updateKeys = (
  key,
  updatationKeyValuesList,
  setUpdatationKeyValuesList
) => {
  if (updatationKeyValuesList && !updatationKeyValuesList.includes(key)) {
    updatationKeyValuesList.push(key);
    setUpdatationKeyValuesList(updatationKeyValuesList);
  }
};

export const imageReturnFromZoho = (url = "") => {
  if(!url) return
  const fileUrl = `https://people.zoho.in/patona/viewPhoto?filename=`;
  const zohoFileUrl = new URL(url);
  const urlParams = new URLSearchParams(zohoFileUrl.search);
  const paramValue = urlParams.get("filename");
  const paramFs = urlParams.get("fs");
  if (!_.isEmpty(paramValue)) {
    return fileUrl + paramValue;
  }
  if (!_.isEmpty(paramFs)) {
    return url;
  }
  return "";
};
