import React from 'react'
import Box from "@mui/material/Box";

import "./styles.scss";

const LogoutSpinner = () => {
  return (
    <div id="spinner">
      <Box
        component='img'
        alt='loading....'
        src="/images/Teamified-Logo-White.svg"
        sx={{ width: '225px' }}
      />
      <h3>Loading....</h3>
    </div>
  )
}

export default LogoutSpinner