import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import { usePermissions } from "../../../../utils/permission";

function performanceTabAriaProps(index: number) {
  return {
    id: `performance-tab-${index}`,
    "aria-controls": `performance-tabpanel-${index}`,
  };
}
const PerformanceTab = ({ handleTabChange, tabValue }) => {
  const { Performance_AnnualFeedBack } = usePermissions();
  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          background: "#fff",
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          marginTop: "12px",
        }}
        className="performance-custom-tabs"
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="basic tabs example"
        >
          {Performance_AnnualFeedBack && (
            <Tab label="Annual feedback" {...performanceTabAriaProps(0)} />
          )}
          <Tab
            label=" Feedback to team member"
            {...performanceTabAriaProps(1)}
          />
          <Tab
            style={{ pointerEvents: "auto" }}
            label={
              <Tooltip title="Currently unavailable">
                <span>Feedback from team member</span>
              </Tooltip>
            }
            {...performanceTabAriaProps(2)}
            disabled
          />
        </Tabs>
      </Box>
    </Box>
  );
};

export default PerformanceTab;
