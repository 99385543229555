import { Grid, Skeleton, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useLazyGetSalaryMatrixQuery } from "../../../redux/Hire/hireApi";


const experienceLevelArray = ["Junior",
    "Mid Level",
    "Senior",
    "Leads"]


const SalaryMatrix = ({ storeSalaryData }) => {

    const { values } = useFormikContext<any>()

    const [getSalaryMatrixApi, { data: salaryMatrixData, isFetching: salaryMatrixLoading, isUninitialized: salaryMatrixUninitialized }] = useLazyGetSalaryMatrixQuery();


    useEffect(() => {
        if (values.title && values.location && values.experienceLevel) {
            getSalaryMatrixApi({
                title: values.title,
                experienceLevel: experienceLevelArray[values.experienceLevel - 1],
                region: values.location,
            })
        }
    }, [getSalaryMatrixApi, values.title, values.location, values.experienceLevel])

    useEffect(() => {
       storeSalaryData(salaryMatrixData?.data[0])
    },[salaryMatrixData, storeSalaryData])

    if (!values.title || !values.location || !values.experienceLevel || salaryMatrixUninitialized)
        return (
            <Grid container>
                <Typography variant="body2" color={"GrayText"}>Salary range as per the selected job title, experience level, employment type and location</Typography>
            </Grid>
        )


    return salaryMatrixData?.data.length ? (
        <Grid container>
            {salaryMatrixLoading ? <Skeleton width={"100%"}></Skeleton> : <Typography variant="body1" fontWeight={500}>
                As per job title, experience level, employment type and location the salary range is $AUD {salaryMatrixData?.data[0]?.minSalary} to $AUD {salaryMatrixData?.data[0]?.maxSalary}
            </Typography>}
        </Grid>) : (
        <Grid container>
            <Typography variant="body1" fontWeight={500}>Account manager will connect with you to discuss the salary range for this role</Typography>
        </Grid>
    )
}

export default SalaryMatrix