import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, CircularProgress, IconButton, TextField, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useAddSummaryMutation } from "../../../redux/Hire/interviewQuestionSlice";
import { usePermissions } from "../../../utils/permission";

const Summary = ({ handleClick, interviewData, isPreInterview, selectedStage }) => {
    const [editMode, setEditMode] = useState(false)
    const [draftSummaryValue, setDraftSummaryValue] = useState(interviewData?.draftSummary || '')
    const [addSummary, {isLoading, isSuccess} ] = useAddSummaryMutation()

    const interviewSummaryRecorded = typeof interviewData?.interviewSummary === 'string'
    const alert = useAlert()

    const {Hire_EditInterviewSummary, Hire_EditCandidateStage} = usePermissions()

 

    useEffect(() => {
        if(isSuccess){

            alert.success('Successfully updated summary')
            setEditMode(false)
        }
    },[isSuccess])

    useEffect(() => {
        if (interviewData?.draftSummary) {
            setDraftSummaryValue(interviewData?.draftSummary)
        }
    }, [interviewData])
    let summaryText = 'You will receive summary of the candidate after a call with the candidate'

    if (!interviewData?.draftSummary && !isPreInterview) {
        summaryText = 'Summary will be generated in few minutes.'
    } else if (interviewData?.draftSummary && !isPreInterview) {
        summaryText = interviewData?.draftSummary
    }

    if (interviewData?.interviewSummary && !isPreInterview) {
        summaryText = interviewData?.interviewSummary
    }

    const submitSummary = () => {
        if(interviewData?._id && draftSummaryValue){
            addSummary({
                interviewId: interviewData?._id,
                interviewSummary: draftSummaryValue
            })
        }
    }

    const cancelEditing = () => {
      setEditMode(false)
      if(interviewData?.draftSummary){
        setDraftSummaryValue(interviewData?.draftSummary)
      }

    }
    return (<>
        <Box className="interview-list-page-sub-header">
            <h3>Summary of the interview</h3>
            <Box className="icon-div-box">
                <Box>
                    {selectedStage.code === 'CV_SENT' && !interviewSummaryRecorded  && Hire_EditInterviewSummary ? <IconButton
                        disableRipple={true}
                        onClick={(e) => {
                            setEditMode(true)
                        }}>
                        <EditIcon />
                    </IconButton> : null}
                </Box>
                <Box>
                    {Hire_EditCandidateStage && <IconButton
                        disableRipple={true}
                        onClick={(e) => {
                            e.stopPropagation();
                            handleClick(e);
                        }}>
                        <MoreVertIcon />
                    </IconButton>}
                </Box>
            </Box>
        </Box>
        <Box className="summary-candiate-box" flexDirection={'column'} gap={1}>
            {editMode ? <TextField
                label="Summary"
                fullWidth
                value={draftSummaryValue}
                multiline
                onChange={(e) => {
                    setDraftSummaryValue(e.target.value)
                }}
            ></TextField> : <Typography variant="h3" whiteSpace='pre-line'>
                {summaryText}
            </Typography>}
            {editMode ? <Box justifyContent={'flex-end'} display={'flex'} width={'100%'}>
                <Button
                    variant="text"
                    size="small"
disabled={isLoading}
                    color="primary"
                    onClick={cancelEditing}
                    sx={{ marginRight: "10px" }}
                >
                    Cancel
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={isLoading}
                    onClick={submitSummary}
                >
                    {isLoading ? <CircularProgress size={20}></CircularProgress>: 'Submit'}
                </Button>
            </Box> : null}
        </Box>
    </>)

}

export default memo(Summary)