import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import FullCalendar from "@fullcalendar/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { userAccess } from "../../../utils/CommonFunction";
import { getAttendanceList } from "../attendance.helper";
import useStyles from "../style";

const TypographyFonts = {
  fontWeight: 700,
  fontSize: "20px",
};

const AttendanceCalender = ({ dashboard }: any) => {
  const dispatch = useDispatch();
  const calRef = useRef<any>();
  const classes = useStyles();
  const [calenderData, setCalenderData] = useState<any>([]);
  const [monthlyView, setMonthlyView] = useState<number>(0);
  const [timesheetName, setTimesheetName] = useState("");
  const { email } = userAccess();

  const { attendanceList } = useSelector(
    //@ts-ignore
    (state) => state.attendance
  );

  useEffect(() => {
    const newAttendanceData = Object.keys(attendanceList).map((item: any) => {
      return {
        ...attendanceList[item],
        date: item,
      };
    });
    const calenderList = newAttendanceData.map((data) => {
      const dateFormatted = moment(data.date, "YYYY-MM-DD").format(
        "YYYY-MM-DD"
      );
      const totalHours = data.totalHours
        ? data.totalHours + " hrs"
        : "00:00 hrs";
      const description = data.desc ? data.desc : "No description";
      const additionalTime = `${
        data.firstIn != "-" ? data.firstIn?.split(" ")[1] + "-" : "00:"
      }${data.lastOut != "-" ? data.lastOut?.split(" ")[1] : "00"} `;
      return {
        ...data,
        date: dateFormatted,
        textColor: "#000",
        color: "#fff",
        title: data?.status?.includes("Leave") ? "Leave" : data?.status,
        totalHours: totalHours,
        additionalTime: additionalTime,
        description: description,
      };
    });
    setCalenderData(calenderList);
  }, [attendanceList]);

  useEffect(() => {
    const curDate = new Date();
    const curYear = curDate.getFullYear();
    const curMonth = curDate.getMonth();

    // Calculate the first and last dates of the current month
    const sDate = moment()
      .year(curYear)
      .month(curMonth)
      .startOf("month")
      .format("DD-MM-YYYY");
    const eDate = moment()
      .year(curYear)
      .month(curMonth)
      .endOf("month")
      .format("DD-MM-YYYY");

    // Set the timesheet name
    const curFullMonth = curDate.toLocaleString("default", { month: "long" });
    const timesheetName = `${curFullMonth}`;
    setTimesheetName(timesheetName);

    // Fetch attendance data for the current month
    getAttendanceList(dispatch, { sDate, eDate, emailId: email });
  }, [dispatch, email]);

  const handlePrev = (monthlyView) => {
    calRef.current?.getApi()?.prev();
    const curDate = new Date();
    curDate.setMonth(curDate.getMonth() + monthlyView);
    const curYear = curDate.getFullYear();
    const curMonth = curDate.getMonth();

    // Calculate the first and last dates of the previous month
    const sDate = moment()
      .year(curYear)
      .month(curMonth)
      .startOf("month")
      .format("DD-MM-YYYY");
    const eDate = moment()
      .year(curYear)
      .month(curMonth)
      .endOf("month")
      .format("DD-MM-YYYY");

    // Set the timesheet name
    const curFullMonth = curDate.toLocaleString("default", { month: "long" });
    const timesheetName = `${curFullMonth}`;
    setTimesheetName(timesheetName);

    // Fetch attendance data for the previous month
    getAttendanceList(dispatch, { sDate, eDate, emailId: email });
  };

  const handleNext = (monthlyView) => {
    calRef.current?.getApi()?.next();
    const curDate = new Date();
    curDate.setMonth(curDate.getMonth() + monthlyView);
    const curYear = curDate.getFullYear();
    const curMonth = curDate.getMonth();

    // Calculate the first and last dates of the next month
    const sDate = moment()
      .year(curYear)
      .month(curMonth)
      .startOf("month")
      .format("DD-MM-YYYY");
    const eDate = moment()
      .year(curYear)
      .month(curMonth)
      .endOf("month")
      .format("DD-MM-YYYY");

    // Set the timesheet name
    const curFullMonth = curDate.toLocaleString("default", { month: "long" });
    const timesheetName = `${curFullMonth}`;
    setTimesheetName(timesheetName);

    // Fetch attendance data for the next month
    getAttendanceList(dispatch, { sDate, eDate, emailId: email });
  };

  const dayCellClassNames = (date) => {
    const foundEvent = calenderData.find(
      (event) => event.date === moment(date).format("YYYY-MM-DD")
    );
    if (foundEvent?.status?.includes("Leave")) {
      return "leave-day";
    }
    if (foundEvent?.status?.includes("Holiday")) {
      return "holiday-day";
    }
    if (foundEvent) {
      switch (foundEvent.status) {
        case "Present":
          return "present-day";
        case "Weekend":
          return "weekend-day";
        default:
          return "default-day";
      }
    }
    return "black";
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div
        className="title-main"
        style={{ opacity: eventInfo.event.title === "Weekend" ? 0.3 : 1 }}
      >
        <div className="present-title">
          <span>{eventInfo.event.title}</span>
        </div>
        {!eventInfo?.event?.extendedProps?.status?.includes("Holiday") && (
          <>
            <div className="duration">
              {eventInfo.event.extendedProps.additionalTime}
            </div>
            <div className="total-title">
              {eventInfo.event.extendedProps.totalHours}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <Grid container alignItems="center">
      {!dashboard && (
        <Grid
          item
          bgcolor="#fff"
          sm={12}
          xs={12}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mt={2}
          p={2}
        >
          <Typography {...TypographyFonts}>India Team</Typography>
          <Grid
            sx={{
              display: "flex",
              textAlign: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              onClick={() => {
                setMonthlyView(monthlyView - 1);
                handlePrev(monthlyView - 1);
              }}
            >
              <ArrowBackIosIcon sx={{ ml: 1, mr: -2 }} />
            </IconButton>
            <Typography
              variant="h6"
              sx={{ fontWeight: "normal", ml: 1, width: "120px" }}
            >
              {timesheetName}
            </Typography>
            <IconButton
              disabled={monthlyView === 0 ? true : false}
              onClick={() => {
                setMonthlyView(monthlyView + 1);
                handleNext(monthlyView + 1);
              }}
            >
              <ArrowForwardIosIcon sx={{ mr: 1 }} />
            </IconButton>
          </Grid>
          <Typography {...TypographyFonts}>
            {`${moment().format("DD dddd ")} | ${timesheetName}`}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        sx={{
          backgroundColor: "#FFF",
          mt: 2,
        }}
        className={classes.container}
      >
        <FullCalendar
          ref={calRef}
          height={600}
          headerToolbar={{
            left: "",
            center: "",
            right: "",
          }}
          events={calenderData}
          themeSystem="Simplex"
          plugins={[dayGridPlugin]}
          initialView="dayGridMonth"
          eventContent={renderEventContent}
          dayCellClassNames={({ date }) => dayCellClassNames(date)}
        />
      </Grid>
    </Grid>
  );
};

export default AttendanceCalender;
