import { useCallback } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Button, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import debounce from 'lodash/debounce';

const AccordionList = ({ questionsList, addNoteFun, disabled = false, isShortlistStage = false, isPostInterview = false }) => {

  const debouncedAddNote = useCallback(debounce(addNoteFun, 1000), [])



  return (
    <Box>
      {questionsList.map((item, index) => (

        <Accordion
          className="accordion-div"
          expanded={isShortlistStage || isPostInterview}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className="accordion-title"
          >
            <div>
              <Typography variant="h5">
                {item.question}
              </Typography>
            </div>
            {isShortlistStage  ? <div>
              {item.interviewNote && item.interviewNote !== "" ? (
                <Button onClick={() => {
                  addNoteFun(' ', item._id)
                }}>Remove note</Button>
              ) : (
                <Button onClick={(e) => {
                }}>Add note</Button>
              )}
            </div>: null}
          </AccordionSummary>
          <AccordionDetails>
            {!isPostInterview ? <Box className="answer-div">
              <Box className="hints-answer">
                <span>Hints for the answers</span>
                <Tooltip
                  title="You can look for the mentioned words and terms in candidate’s answer."
                  arrow
                >
                  <InfoOutlinedIcon />
                </Tooltip>
              </Box>
              <Typography>{item.hint}</Typography>
            </Box> : null}
            {isPostInterview ? <div><Typography variant="body2">{item.interviewNote ? item.interviewNote : 'No answer added'}</Typography></div> :<Box className="notes-div">
               <TextField label="Note"
                fullWidth
                multiline
                onChange={(e) => debouncedAddNote(e.target.value, item._id)}
                disabled={disabled}
                defaultValue={item.interviewNote} />
            </Box>}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default AccordionList;
