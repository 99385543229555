import { createApi } from "@reduxjs/toolkit/dist/query/react";
import axiosBaseQuery from "../../services/service";
const BASE_URL = process.env.REACT_APP_BASE_API_URL;


function providesList<R extends { id: string | number }[], T extends string>(
    resultsWithIds: R | undefined,
    tagType: T
  ) {
    return resultsWithIds
      ? [
          { type: tagType, id: 'LIST' },
          ...resultsWithIds.map(({ id }) => ({ type: tagType, id })),
        ]
      : [{ type: tagType, id: 'LIST' }]
  }
  

type GetTaskRoleRequest = {
    role: string
}

export type TeamifiedTask = {
    id: number
    taskType: 'individualTask' | 'workflowTask'
    title: string
    summary: string
    workflowActorId: string
    jobId: null | string
    candidateId: null | string
    clientId: number
    assigneeUser: null | string
    assignedAt: string
    actionsJson: string
    completedAction: null | string
    completedBy: null | string
    completedAt: null | string
    priority: "Low" | "Medium" | "Urgent"
    createdAt: string
    updatedAt: string
    dueDate: null | string
}


type GetTaskRoleResponse = {
    data: Array<TeamifiedTask>
    message: string
    successful: boolean
    status: number
}

type AddTaskRequest = {
    AssigneeRole: string
    AssigneeUser: null | string
    ClientId: number
    CreatedBy: string
    Priority: "Low" | "Medium" | "Urgent"
    Summary: string
    TaskType: 'individualTask'
    Title: string
    assigneeUserEmail: string
    creatorUserEmail: string
    target: string
}

type AddTaskResponse = {
    data: { succeeded: boolean, errors: Array<string> }
    message: string
    successful: boolean
    status: number
}


type AddTaskCommentRequest = {
    comment: string
    id: number
    taskId: number
    commentedBy: string
    commentedByEmail: string
}

type AddTaskCommentResponse = {
    data: { succeeded: boolean, errors: Array<string> }
    message: string
    successful: boolean
    status: number
}

type GetTaskByCreatorIdRequest = {
    createdBy: string
}


type DeleteTaskRequest = {
    taskId: number
}

type GetTaskByIdRequest = {
    taskId: number
}


type Comment = {
    "id": number
    "comment": string
    "commentedBy": string
    "commentedByEmail": string
    "taskId": number,
    "createdAt": string
    "updatedAt": null | string
}

type TaskExtraField = {
    "taskId": number
    "taskType": "individualTask" | 'workflowTask'
    "title": string
    "summary": string
    "jobId": null | string
    "candidateId": null | string
    "clientId": string
    "workflowActorId": null | string
    "assigneeUser": null | string
    "assigneeRole": string
    "assignedAt": string
    "actionsJson": string
    "completedAction": null | string
    "completedBy": null | string
    "completedAt": null | string
    "completedRemarks": null | string
    "priority": "Low" | "Medium" | "Urgent"
    "createdAt": string
    "createdBy": string
    "updatedAt": null | string
    "dueDate": null | string
    "target": string
    "creatorUserEmail": string
    "assigneeUserEmail": string
}

type GetTaskByIdResponse = {
    data: {
        comments: Array<Comment>
        task: TaskExtraField
    }
    message: string
    successful: boolean
    status: number
}

export const TaskApi = createApi({
    reducerPath: 'task',
    baseQuery: axiosBaseQuery({
        baseUrl: BASE_URL || '',
    }),
    tagTypes: ['TaskList', 'TaskComment'],
    endpoints: (builder) => ({
        getTasks: builder.query<GetTaskRoleResponse, GetTaskRoleRequest>({
            query: ({ role }) => ({
                url: '/GetAllTasksByRole',
                method: 'POST',
                body: {
                    role
                }
            }),
            providesTags: (result) => providesList(result?.data, 'TaskList'),
        }),
        addTask: builder.mutation<AddTaskResponse, AddTaskRequest>({
            query: (req) => ({
                url: 'addTask',
                method: 'POST',
                body: req
            }),
            invalidatesTags: ['TaskList']

        }),
        addTaskComment: builder.mutation<AddTaskCommentResponse, AddTaskCommentRequest>({
            query: (req) => ({
                url: 'addTaskComment',
                method: 'POST',
                body: req
            }),
            invalidatesTags: (result, error, arg) => [{type: 'TaskComment', id: arg.taskId }]

        }),
        getTaskByCreatorId: builder.query<GetTaskRoleResponse, GetTaskByCreatorIdRequest>({
            query: ({ createdBy }) => ({
                url: 'GetAllTasksByCreatorUserId',
                method: 'POST',
                body: {
                    createdBy
                },
            }),
            providesTags: (result) => providesList(result?.data, 'TaskList'),
        }),
        updateTask: builder.mutation<AddTaskCommentResponse, TeamifiedTask>({
            query: (req) => ({
                url: 'updateTask',
                body: req,
                method: 'POST'
            }),
            invalidatesTags: ['TaskList']
        }),
        deleteTask: builder.mutation<AddTaskCommentResponse, DeleteTaskRequest>({
            query: ({ taskId }) => ({
                url: 'deleteTask',
                body: { taskId },
                method: 'POST'
            }),
            invalidatesTags: ['TaskList']
        }),
        getTaskById: builder.query<GetTaskByIdResponse, GetTaskByIdRequest>({
            query: (req) => ({
                url: 'GetTaskById',
                method: 'POST',
                body: req
            }),
            providesTags: (result) => [{type: 'TaskComment', id: result?.data.task.taskId}]
        })
    })
})

export const {
    useAddTaskCommentMutation,
    useAddTaskMutation,
    useDeleteTaskMutation,
    useUpdateTaskMutation,
    useGetTaskByCreatorIdQuery,
    useGetTaskByIdQuery,
    useGetTasksQuery,
} = TaskApi