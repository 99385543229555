import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Avatar,
  Box,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Container } from "@mui/system";
import Moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";

import RowSkeleton from "../../../shared/components/Skeletons/RowSkeleton";
import TableSkeleton from "../../../shared/components/Skeletons/TableSkeleton";
import TextSkeleton from "../../../shared/components/Skeletons/TextSkeleton";
import { getTimeSheetDetails } from "../../TimeSheets/Timesheet.helper";
import DetailInfo from "../DetailInfo/DetailInfo";
import TimesheetTable from "../DetailTable/TimesheetTable";

const TeamMemberWeeklyView = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const receivedProps = location.state;
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const [timesheetList, setTimesheetList] = useState<any>([]);
  const { isTimesheetDetailLoading, timesheetDetail, timesheetWeeklyList } =
    useSelector(
      //@ts-ignore
      (state) => state.timesheet
    );

  useEffect(
    () => {
      if (timesheetDetail?.tsArrRecords?.length > 0) {
        setTimesheetList(timesheetDetail?.tsArrRecords);
      }
    },
    [timesheetDetail]
  );

  useEffect(() => {
    if (timesheetWeeklyList) {
      setTimesheetList(timesheetWeeklyList);
    }
  },
    [timesheetWeeklyList]
  );

  useEffect(
    () => {
      if (receivedProps.id) {
        getTimeSheetDetails(dispatch, { timesheetRecordId: receivedProps.id });
      }
    },
    [dispatch, receivedProps]
  );

  return (
    <>
      <Grid container component="main">
        <Container maxWidth="xl">
          <Box mt={2} mb={2} sx={{ backgroundColor: "#CCD5FC" }}>
            <Grid container spacing={2} alignItems="center">
              {isMobile ? (
                <>
                  <Grid
                    item
                    xs={12}
                    sx={{ display: "flex", p: 2 }}
                    alignItems={"center"}
                  >
                    <Link to={`/timesheets`} style={{ color: "#000" }}>
                      <ArrowBackIosIcon sx={{ ml: 2, mr: 1 }} />
                    </Link>
                    <Avatar
                      alt="Remy Sharp"
                      src="/static/images/avatar/1.jpg"
                      sx={{
                        display: "inline-flex",
                        mx: 1,
                        width: 32,
                        height: 32,
                      }}
                    />
                    <div style={{ marginLeft: 10 }}>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {receivedProps.date}
                      </Typography>
                      <Typography variant="body2" sx={{ fontWeight: "normal" }}>
                        {`${Moment(
                          timesheetDetail?.fromDate,
                          "MM/DD/YYYY"
                        ).format("ll (ddd)")} to ${Moment(
                          timesheetDetail?.toDate,
                          "MM/DD/YYYY"
                        ).format("ll (ddd)")}`}
                      </Typography>
                    </div>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    sx={{ display: "flex", p: 2 }}
                    alignItems={"center"}
                  >
                    <Link to={`/timesheets`} style={{ color: "#000" }}>
                      <ArrowBackIosIcon sx={{ ml: 2, mr: 1 }} />
                    </Link>
                    {isTimesheetDetailLoading ? (
                      <Grid item xs={6}>
                        <TextSkeleton />
                      </Grid>
                    ) : (
                      <Typography variant="h5" sx={{ fontWeight: "normal" }}>
                        {receivedProps.date}
                      </Typography>
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          {isTimesheetDetailLoading ? (
            <Grid container xs={12} sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={12}>
                <RowSkeleton rowHeight={150} />
              </Grid>
            </Grid>
          ) : (
            <DetailInfo timesheetDetail={timesheetDetail} />
          )}
          {isTimesheetDetailLoading ? (
            <Grid container xs={12} sx={{ mb: 2 }}>
              <Grid item xs={12}>
                <TableSkeleton />
              </Grid>
            </Grid>
          ) : (
            <TimesheetTable timesheetList={timesheetList} />
          )}
        </Container>
      </Grid>
    </>
  );
};

export default TeamMemberWeeklyView;
