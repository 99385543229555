import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import "./index.scss";

interface LayoutProps {
  header: React.ReactNode;
  subHeader?: React.ReactNode;
  body: React.ReactNode;
}

const Layout = ({ header, subHeader, body }: LayoutProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      maxWidth={false}
      disableGutters={isMobile}
      className="layout-container"
      sx={{
        display: 'flex !important'
      }}
    >
      {/* Header container that takes the size of its content */}
      <Box className="layout-header">
        <Box className="header">{header}</Box>
        {subHeader && <Box className="sub-header">{subHeader}</Box>}
      </Box>

      {/* Body container that takes the remaining space */}
      <Box className="layout-body">{body}</Box>
    </Container>
  );
};

export default Layout;
