import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";

import { STRINGS } from "../../../Strings";
import { userAccess } from "../../../utils/CommonFunction";
import { applyLeave } from "../TeamMemberApprovals.helper";

interface LeaveType {
  id: string;
  balanceCount: number;
}
interface Day {
  date: string;
  LeaveType?: string;
  leaveCount?: string;
  session?: string;
}

const ApplyLeave = ({ handleOpenClose, isMobile, classes }) => {
  const dispatch = useDispatch();
  const [selectedLeaveType, setSelectedLeaveType] = useState<{
    [key: number]: string;
  }>({});
  const [totalApplied, setTotalApplied] = useState<number>(0);
  const [availableLeave, setAvailableLeave] = useState<number | null>(null);
  const minDate = dayjs().subtract(0, "year").toDate();
  const validationSchema = yup.object({
    leaveId: yup.string().required("Leave Type is required"),
    dateFrom: yup.date().required("Leave dateFrom is required"),
    dateTo: yup.date().required("Leave dateTo is required"),
    reasonForLeave: yup.string().required("Reason for Leave is required"),
  });
  const { leaveTypes } = useSelector((state: any) => state.approval);
  const { zohoId } = userAccess();
  const formik = useFormik({
    initialValues: {
      leaveId: "",
      dateFrom: "",
      dateTo: "",
      reasonForLeave: "",
      days: [] as Day[],
    },
    validationSchema: validationSchema,
    validate: (values) => {
      let errors: { [key: string]: string } = {};
      values.days.forEach((item: Day) => {
        if (!item.LeaveType) {
          errors[item.date] = "Please select leave type";
        }
        if (item.LeaveType === "Half Day" && !item.leaveCount) {
          errors[item.date] = "Please select leave type";
        }
      });
      return errors;
    },
    onSubmit: (values, { resetForm }) => {
      let payload: any = {
        days: [] as Day[],
        leaveId: values.leaveId,
        reasonForLeave: values.reasonForLeave,
        dateFrom: dayjs(values.dateFrom).format("DD-MM-YYYY"),
        dateTo: dayjs(values.dateTo).format("DD-MM-YYYY"),
        zohoId: zohoId,
      };

      values.days.forEach((item: Day) => {
        if (item.LeaveType === "Full Day") {
          payload.days.push({
            date: item.date,
            leaveCount: "1",
          });
        } else {
          const session = item.leaveCount === "1st half" ? "1" : "2";
          payload.days.push({
            date: item.date,
            leaveCount: "0.5",
            session,
          });
        }
      });
      handleOpenClose();
      applyLeave(dispatch, payload);
    },
  });
  const handleCancel = () => {
    formik.resetForm();
    handleOpenClose();
    setTotalApplied(0);
    setAvailableLeave(null);
  };
  const handleTypes = () => {
    const from = dayjs(formik.values.dateFrom?.["$d"]);
    const to = dayjs(formik.values.dateTo?.["$d"]);
    let total: any = [];
    let types = {
      "Full Day": ["full day", "Session"],
      "Half Day": ["1st half", "2nd half"],
    };
    for (
      let date = from;
      date.isBefore(to) || date.isSame(to);
      date = date.add(1, "day")
    ) {
      if (
        date.format("dddd") !== "Saturday" &&
        date.format("dddd") !== "Sunday"
      ) {
        total.push({
          days: date.format("dddd"),
          date: date.format("DD-MM-YYYY"),
          types,
        });
        formik.setFieldValue("days", total);
      }
    }
  };

  const shouldDisableDate = (date: Date) => {
    const dayOfWeek = dayjs(date).day();
    return dayOfWeek === 0 || dayOfWeek === 6;
  };

  useEffect(() => {
    if (formik.values.dateFrom?.["$d"] && formik.values.dateTo["$d"]) {
      handleTypes();
    }
  }, [formik.values.dateFrom, formik.values.dateTo]);

  const handleLeaveTypeChange = (event, index) => {
    const newSelectedLeaveType = { ...selectedLeaveType };
    newSelectedLeaveType[index] = event.target.value;
    setSelectedLeaveType(newSelectedLeaveType);
  };

  const handleTypesObj = (date, type, innerType) => {
    formik.values.days.map((item: any, index) => {
      if (item.date === date && !innerType) {
        const updatedDay = {
          ...item,
          LeaveType: type,
        };
        formik.setFieldValue(`days[${index}]`, updatedDay);
      }

      if (item.date === date && innerType) {
        const updatedDay = {
          ...item,
          leaveCount: innerType,
        };
        formik.setFieldValue(`days[${index}]`, updatedDay);
      }
    });
  };

  const handleAvailableLeave = () => {
    const findLeave: any = leaveTypes.find(
      (items: LeaveType) => items?.id === formik.values.leaveId
    );
    const balanceCount = findLeave?.balanceCount;
    let totalCount = 0;
    formik.values.days.forEach((items: any) => {
      if (items?.LeaveType) {
        items?.LeaveType == "Full Day"
          ? (totalCount = totalCount + 1)
          : (totalCount = totalCount + 0.5);
      }
    });
    if (balanceCount !== undefined) {
      setAvailableLeave(balanceCount - totalCount);
      setTotalApplied(totalCount);
    }
  };

  useEffect(() => {
    handleAvailableLeave();
  }, [
    formik.values.leaveId,
    formik.values.dateTo,
    formik.values.reasonForLeave,
    formik.values.days,
  ]);

  return (
    <>
      <Typography
        id="modal-modal-title"
        variant="h4"
        component="h2"
        textAlign="center"
      >
        Apply Leave
      </Typography>
      <Box component="form" noValidate>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={12} md={12}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.leaveId && formik.errors.leaveId)}
            >
              <InputLabel id="leaveType" className={classes.blackColor}>
                Leave Type
              </InputLabel>
              <Select
                label="leaveType"
                name="leaveId"
                value={formik.values.leaveId}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              >
                {leaveTypes?.map((items: any) => (
                  <MenuItem value={items.id}>{items.name}</MenuItem>
                ))}
              </Select>
              {formik.touched.leaveId && formik.errors.leaveId ? (
                <FormHelperText>{formik.errors.leaveId}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} mt={1} mb={1}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.dateFrom && formik.errors.dateFrom)}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  className={classes.datePickerColor}
                  label="Leave dateFrom*"
                  inputFormat="DD-MM-YYYY"
                  minDate={minDate}
                  value={formik.values.dateFrom}
                  onChange={(date) => {
                    formik.setFieldValue("dateFrom", date);
                  }}
                  shouldDisableDate={shouldDisableDate}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.blackColor,
                          focused: classes.blackColor,
                        },
                      }}
                      sx={{
                        "& fieldset": {
                          borderColor: "#000",
                          borderRadius: "5px",
                        },
                        "&:focus-within fieldset": {
                          borderColor: "#000 !important",
                        },
                      }}
                      {...params}
                      error={false}
                    />
                  )}
                />
              </LocalizationProvider>
              {formik.touched.dateFrom && formik.errors.dateFrom ? (
                <FormHelperText>{formik.errors.dateFrom}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={6} mt={1} mb={1}>
            <FormControl
              fullWidth
              error={Boolean(formik.touched.dateTo && formik.errors.dateTo)}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Leave dateTo*"
                  inputFormat="DD-MM-YYYY"
                  minDate={minDate}
                  value={formik.values.dateTo}
                  onChange={(date) => {
                    formik.setFieldValue("dateTo", date);
                  }}
                  shouldDisableDate={shouldDisableDate}
                  renderInput={(params) => (
                    <TextField
                      InputLabelProps={{
                        classes: {
                          root: classes.datePickerColor,
                          focused: classes.blackColor,
                        },
                      }}
                      sx={{
                        "& fieldset": {
                          borderColor: "#000",
                          borderRadius: "5px",
                        },
                        "&:focus-within fieldset": {
                          borderColor: "#000 !important",
                        },
                      }}
                      {...params}
                      error={false}
                    />
                  )}
                />
              </LocalizationProvider>
              {formik.touched.dateTo && formik.errors.dateTo ? (
                <FormHelperText>{formik.errors.dateTo}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          {formik.values.days.length ? (
            <Box
              width={"98%"}
              ml={2}
              sx={{ backgroundColor: "#f1f1f1" }}
              pb={2}
              mt={1}
            >
              <Grid>
                {formik?.values?.days.map((items: any, index: number) => (
                  <Grid container spacing={2} justifyContent={"center"} mt={1}>
                    <Grid item xs={8} sm={8} md={2}>
                      <Typography>{items?.days}</Typography>
                      <Typography>{items?.date}</Typography>
                    </Grid>
                    <Grid item xs={8} sm={8} md={4}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          className={classes.blackColor}
                        >
                          {STRINGS.Session}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Session*"
                          value={selectedLeaveType[items.date] || ""}
                          className={classes.selectFieldSet}
                          onChange={(event) =>
                            handleLeaveTypeChange(event, items.date)
                          }
                        >
                          {Object.keys(items?.types).map((types) => (
                            <MenuItem
                              value={types}
                              onClick={() =>
                                handleTypesObj(items.date, types, "")
                              }
                            >
                              {types}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      {formik.errors[items.date] ? (
                        <FormHelperText error={formik.errors[items.date]}>
                          {formik.errors[items.date]}
                        </FormHelperText>
                      ) : null}
                    </Grid>
                    <Grid item xs={8} sm={8} md={4}>
                      <FormControl fullWidth>
                        <InputLabel
                          id="demo-simple-select-label"
                          className={classes.blackColor}
                        >
                          {STRINGS.Session}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          disabled={items.types[
                            selectedLeaveType[items.date]
                          ]?.includes("Session")}
                          className={classes.selectFieldSet}
                          label="Session*"
                        >
                          {items.types[selectedLeaveType[items.date]]?.map(
                            (innerSession) => (
                              <MenuItem
                                value={innerSession}
                                key={innerSession}
                                onClick={() =>
                                  handleTypesObj(items.date, "", innerSession)
                                }
                              >
                                {innerSession}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {availableLeave !== null && (
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  className={classes.leaveInfoHeading}
                  ml={4}
                  mt={1}
                >
                  <Typography
                    className={classes.leaveCountFonts}
                    flexGrow={1}
                    color={availableLeave > 0 ? "black" : "red"}
                  >
                    {STRINGS.AvailableLeave}
                  </Typography>
                  <Typography
                    className={classes.leaveCountFonts}
                    color={availableLeave > 0 ? "black" : "red"}
                  >
                    {availableLeave.toFixed(2)}
                  </Typography>
                </Grid>
              )}
              {totalApplied > 0 && (
                <Grid
                  item
                  xs={8}
                  sm={8}
                  md={8}
                  className={classes.leaveInfoHeading}
                  ml={4}
                  mt={1}
                >
                  <Typography className={classes.leaveCountFonts} flexGrow={1}>
                    {STRINGS.TotalLeave}
                  </Typography>
                  <Typography className={classes.leaveCountFonts}>
                    {totalApplied}
                  </Typography>
                </Grid>
              )}
            </Box>
          ) : null}
          <Grid item xs={12} sm={12} md={12}>
            <FormControl
              fullWidth
              error={Boolean(
                formik.touched.reasonForLeave && formik.errors.reasonForLeave
              )}
              className={classes.selectFieldSet}
            >
              <TextField
                id="reasonForLeave"
                name="reasonForLeave"
                label="Reason for Leave"
                multiline
                rows={5}
                variant="outlined"
                fullWidth
                value={formik.values.reasonForLeave}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={classes.textFieldBox}
                InputLabelProps={{
                  style: { color: "black" },
                }}
              />
              {formik.touched.reasonForLeave && formik.errors.reasonForLeave ? (
                <FormHelperText>{formik.errors.reasonForLeave}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                variant="text"
                sx={{ mt: 2 }}
                onClick={handleCancel}
              >
                {STRINGS.Cancel}
              </Button>
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => formik.handleSubmit()}
              >
                {STRINGS.Submit}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default ApplyLeave;
