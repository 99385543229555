import { useState } from "react";
import Layout from "../common/Layout";
import PerformanceDetailHeader from "./PerformanceDetailHeader";
import PerformanceGraph from "./PerformanceGraph";
import IndividualPerformanceTable from "./PerformanceList";
import "../Performance.scss";
import { Tab, Tabs } from "@mui/material";
import { TabPanel } from "../../../shared/components/TabPanel/TabPanel";
import { userAccess } from "../../../utils/CommonFunction";
import { usePermissions } from "../../../utils/permission";

const PerformanceDetail = () => {
  const [tabValue, setValue] = useState(0);
  const { Team_Member } = usePermissions();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <>
      <Layout
        header={Team_Member ? <></> : <PerformanceDetailHeader />}
        body={
          <>
            {!Team_Member && (
              <Tabs
                value={tabValue}
                variant="fullWidth"
                onChange={handleChange}
                sx={{ mb: 3, backgroundColor: "white", ml: 2, mr: 2 }}
              >
                <Tab
                  label="Feedback from client"
                  sx={{
                    fontFamily: "Plus Jakarta Sans",
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                />
              </Tabs>
            )}
            <TabPanel value={tabValue} index={0}>
              {!Team_Member && <PerformanceGraph />}
              <IndividualPerformanceTable />
            </TabPanel>
          </>
        }
      />
    </>
  );
};

export default PerformanceDetail;
