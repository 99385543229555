import { createSlice } from "@reduxjs/toolkit";
import { rowPerPage } from "../../Constants";

const initialState = {
  user: {},
  userList: [],
  allClients: [],
  isUserListLoading: false,
  userListError: "",
  isLoading: false,
  error: "",
  timeZone: {},
  isUserUpdated: false,
  userUpdateError: "",
  updateLoading: false,
  changePasswordLoading: false,
  passwordChangeSuccess: {},
  isPaging: false,
  page: 0,
  totalMember: 0,
  isClientUserAdded: false,
  clientAddingInProgress: false,
  isClientLoginFirstTime: false,
  isResendInProgress: false,
  isResendSent: false,
  userPageSize: rowPerPage,
  userCurrentPage: 0,
  rolesLoading:false,
  roles:[],
  rolesError:''
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setClientLoginFirstTime: (state) => {
      state.isClientLoginFirstTime = true;
    },
    setClientCompleteLoginFirstTime: (state) => {
      state.isClientLoginFirstTime = false;
    },
    successAllClients: (state, { payload }) => {
      state.allClients = payload.clients;
    },
    failAllClients: (state) => {
      state.allClients = [];
    },
    clientUserAdded: (state, { payload }) => {
      state.isClientUserAdded = payload;
    },
    clientUserAddedSuccessfully: (state) => {
      state.isClientUserAdded = false;
      state.clientAddingInProgress = false;
    },
    clientAddingInProgress: (state, { payload }) => {
      state.clientAddingInProgress = payload;
    },
    resendInProgress: (state, { payload }) => {
      state.isResendInProgress = payload;
    },
    resendSent: (state, { payload }) => {
      state.isResendSent = payload;
      state.isResendInProgress = false;
    },
    resendUpdated: (state) => {
      state.isResendSent = false;
    },
    paging: (state, { payload }) => {
      state.isPaging = payload.isPaging;
      state.page = payload.page;
      state.userPageSize = payload.pageSize;
      state.userCurrentPage = payload.currentPage;
    },
    userCount: (state, { payload }) => {
      state.totalMember = payload;
    },
    getUserPending: (state) => {
      state.isLoading = true;
    },
    getUserSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.user = payload;
      state.error = "";
    },
    getUserFail: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    getUserListPending: (state) => {
      state.isUserListLoading = true;
    },
    getUserListSuccess: (state, { payload }) => {
      state.isUserListLoading = false;
      state.userList = payload.users;
      state.userListError = "";
    },
    getUserListFail: (state, { payload }) => {
      state.isUserListLoading = false;
      state.userListError = payload;
    },
    clearUserList: (state) => {
      state.userList = [];
    },
    setTimeZone: (state, { payload }) => {
      state.timeZone = payload;
    },
    setTimeZoneFail: (state, { payload }) => {
      state.timeZone = payload;
    },
    userUpdated: (state, { payload }) => {
      state.isUserUpdated = payload;
      state.updateLoading = false;
    },
    userUpdatedFail: (state, { payload }) => {
      state.userUpdateError = payload;
      state.updateLoading = false;
    },
    userUpdatedDone: (state) => {
      state.isUserUpdated = false;
      state.userUpdateError = "";
      state.updateLoading = false;
    },
    getUpdatePending: (state) => {
      state.updateLoading = true;
    },
    passwordChangeSuccess: (state, { payload }) => {
      state.passwordChangeSuccess = payload;
    },
    passwordChangePending: (state, { payload }) => {
      state.changePasswordLoading = payload;
    },
    getRolesPending: (state) => {
      state.rolesLoading = true;
    },
    getRolesSuccess: (state, { payload }) => {
      state.rolesLoading = false;
      state.roles = payload;
      state.rolesError = "";
    },
    getRolesFail: (state, { payload }) => {
      state.rolesLoading = false;
      state.rolesError = payload;
    },
  },
});

export const {
  paging,
  userCount,
  getUserPending,
  getUserSuccess,
  getUserFail,
  setTimeZone,
  setTimeZoneFail,
  userUpdated,
  userUpdatedFail,
  getUpdatePending,
  passwordChangeSuccess,
  passwordChangePending,
  userUpdatedDone,
  getUserListSuccess,
  getUserListFail,
  clearUserList,
  getUserListPending,
  successAllClients,
  failAllClients,
  clientUserAdded,
  clientUserAddedSuccessfully,
  clientAddingInProgress,
  setClientLoginFirstTime,
  setClientCompleteLoginFirstTime,
  resendInProgress,
  resendSent,
  resendUpdated,
  getRolesPending,
  getRolesSuccess,
  getRolesFail
} = userSlice.actions;

export default userSlice.reducer;
