export const sagaActions = {
  FETCH_DATA_SAGA: "FETCH_DATA_SAGA",
  FETCH_MEMBER: "FETCH_MEMBER",
  FETCH_DELETE_MEMBER: "FETCH_DELETE_MEMBER",
  SAVE_MEMBER: "SAVE_MEMBER",
  GET_SKILLS: "GET_SKILLS",
  SEARCH_MEMBER_BY_NAME: "SEARCH_MEMBER_BY_NAME",
  GET_MEMBER_BY_ID: "GET_MEMBER_BY_ID",
  UPDATE_MEMBER: "UPDATE_MEMBER",
  REMOVE_MEMBER: "REMOVE_MEMBER",
  RESTORE_MEMBER: "RESTORE_MEMBER",
  DISABLE_MEMBER: "DISABLE_MEMBER",
  ENABLE_MEMBER: "ENABLE_MEMBER",
  GET_PROFILE: "GET_PROFILE",
  GET_TIMEZONE: "GET_TIMEZONE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  COMPANY_DETAILS: "COMPANY_DETAILS",
  UPDATE_COMPANY_DETAILS: "UPDATE_COMPANY_DETAILS",
  USERS_LIST: "USERS_LIST",
  COMPANY_LIST: "COMPANY_LIST",
  SAVE_USER_CLIENT: "SAVE_USER_CLIENT",
  RESEND_INVITE: "RESEND_INVITE",
  DISABLE_ENABLE_USER: "DISABLE_ENABLE_USER",
  FETCH_CLIENTS: "FETCH_CLIENTS",
  DISABLE_ENABLE_CLIENT: "DISABLE_ENABLE_CLIENT",
  JOBS_LIST: "JOBS_LIST",
  CANDIDATE_LIST: "CANDIDATE_LIST",
  MORE_CANDIDATE_LIST: "MORE_CANDIDATE_LIST",
  GET_ALL_WORKABLE_CLIENTS: "GET_ALL_WORKABLE_CLIENTS",
  JOBS_DETAILS: "JOBS_DETAILS",
  FETCH_DASHBOARD_DATA: "FETCH_DASHBOARD_DATA",
  FETCH_DASHBOARD_FILTER: "FETCH_DASHBOARD_FILTER",
  POST_JOB: "POST_JOB",
  JOB_DETAILS_BY_JOBID: "JOB_DETAILS_BY_JOBID",
  GET_HIRE_CANDIDATES_STAGING: "GET_HIRE_CANDIDATES_STAGING",
  CANCEL_JOB: "CANCEL_JOB",
  UPDATE_POST_JOB: "UPDATE_POST_JOB",
  RESTORE_JOB: "RESTORE_JOB",
  POST_COMMENT: "POST_COMMENT",
  CANDIDATE_DETAIL: "CANDIDATE_DETAIL",
  MOVE_CANDIDATE: "MOVE_CANDIDATE",
  DISQUALIFIED_CANDIDATE: "DISQUALIFIED_CANDIDATE",
  GET_APPOROVALS_LIST: "GET_APPOROVALS_LIST",
  GET_APPOROVALS_DETAIL: "GET_APPOROVALS_DETAIL",
  GET_CALENDER_APPOROVALS_LIST: "GET_CALENDER_APPOROVALS_LIST",
  GET_LEAVE_INFO: "GET_LEAVE_INFO",
  FETCH_DASHBOARD_CALENDAR: "FETCH_DASHBOARD_CALENDAR",
  GET_APPROVAL_DATA: "GET_APPROVAL_DATA",
  FETCH_PENDING_INVOICE: "FETCH_PENDING_INVOICE",
  FETCH_PAID_INVOICE: "FETCH_PAID_INVOICE",
  FETCH_INVOICE_DETAIL: "FETCH_INVOICE_DETAIL",
  FETCH_INVOICE_PREVIEW: "FETCH_INVOICE_PREVIEW",
  FETCH_INVOICE_DOWNLOAD: "FETCH_INVOICE_DOWNLOAD",
  FETCH_INVOICE_GRAPH: "FETCH_INVOICE_GRAPH",
  FETCH_ZOHO_DESIGNATION: "FETCH_ZOHO_DESIGNATION",
  CHAT_GPT_DESCRIPTION: "CHAT_GPT_DESCRIPTION",
  LEAVE_ACTION: "LEAVE_ACTION",
  FORGOT_PASSWORD_ACTION: "FORGOT_PASSWORD_ACTION",
  GET_TIMESHEETS_LIST: "GET_TIMESHEETS_LIST",
  GET_TIMESHEETS_DETAIL: "GET_TIMESHEETS_DETAIL",
  GET_TEAM_MEMBER_TIMESHEET: "GET_TEAM_MEMBER_TIMESHEET",
  GET_TIMESHEETS_WEEKLY_DETAIL: "GET_TIMESHEETS_WEEKLY_DETAIL",
  GET_TIMESHEETS_MONTHLY_DETAIL: "GET_TIMESHEETS_MONTHLY_DETAIL",
  TIMESHEET_ACTION: "TIMESHEET_ACTION",
  MEMBER_TIMESHEET_ACTION: "MEMBER_TIMESHEET_ACTION",
  MEMBER_INVOICE_ACTION: "MEMBER_INVOICE_ACTION",
  FETCH_CLIENTS_ADMINS: "FETCH_CLIENTS_ADMINS",
  FETCH_PUBLIC_HOLIDAY_LIST: "FETCH_PUBLIC_HOLIDAY_LIST",
  RESET_PUBLIC_HOLIDAY_LIST: "RESET_PUBLIC_HOLIDAY_LIST",
  // performance
  FETCH_ALL_ANNUAL_QUESIONS_PERFORMANCE:
    "FETCH_ALL_ANNUAL_QUESIONS_PERFORMANCE",
  FETCH_ANNUAL_FEEDBACK_DETAILS: "FETCH_ANNUAL_FEEDBACK_DETAILS",
  SUBMIT_ANNUAL_FEEDBACK: "SUBMIT_ANNUAL_FEEDBACK",
  FETCH_ALL_EMPLOYEES_PERFORMANCE: "FETCH_ALL_EMPLOYEES_PERFORMANCE",
  FETCH_ALL_EMPLOYEES_PERFORMANCE_MOBILE:
    "FETCH_ALL_EMPLOYEES_PERFORMANCE_MOBILE",
  FETCH_EMPLOYEE_PERFORMANCE: "FETCH_EMPLOYEE_PERFORMANCE",
  FETCH_EMPLOYEE_PERFORMANCE_GRAPH: "FETCH_EMPLOYEE_PERFORMANCE_GRAPH",
  FETCH_EMPLOYEE_PERFORMANCE_FEEDBACK: "FETCH_EMPLOYEE_PERFORMANCE_FEEDBACK",
  ADD_FEEDBACK: "ADD_FEEDBACK",
  RESET_SUBMIT_FEEDBACK_INFO: "RESET_SUBMIT_FEEDBACK_INFO",
  SEARCH_EMPLOYEE_BY_NAME: "SEARCH_EMPLOYEE_BY_NAME",
  GET_SUBMITTED_MONTHS: "GET_SUBMITTED_MONTHS",
  FETCH_PENDING_EMPLOYEES: "FETCH_PENDING_EMPLOYEES",
  SUBMIT_BULK_FEEDBACK: "SUBMIT_BULK_FEEDBACK",
  CLEAR_EMPLOYEE_PERFORMANCE: "CLEAR_EMPLOYEE_PERFORMANCE",
  GET_UPCOMING_PAST_LEAVE_LIST: "GET_UPCOMING_PAST_LEAVE_LIST",
  GET_TEAM_MEMBER_SHORT_LEAVE_INFO: "GET_TEAM_MEMBER_SHORT_LEAVE_INFO",
  GET_LEAVE_TYPE_TO_APPLY_LEAVES: "GET_LEAVE_TYPE_TO_APPLY_LEAVES",
  APPLY_LEAVES: "APPLY_LEAVES",
  GET_OPPORTUNITIES_FOR_TEAMMEMBER: "GET_OPPORTUNITIES_FOR_TEAMMEMBER",
  GET_CLIENT_LIST: "GET_CLIENT_LIST",
  GET_PROJECT_LIST: "GET_PROJECT_LIST",
  GET_TASK_LIST: "GET_TASK_LIST",
  GET_DETAIL_BY_ID: "GET_DETAIL_BY_ID",
  ADD_TIME_LOG: "ADD_TIME_LOG",
  GET_WEEKLY_TIME_LOG: "GET_WEEKLY_TIME_LOG",
  ADD_TIME_SHEET: "ADD_TIME_SHEET",
  GET_ATTENDANCE: "GET_ATTENDANCE",
  ADD_CHECK_IN_CHECK_OUT: "ADD_CHECK_IN_CHECK_OUT",
  GET_ALL_ROLES: "GET_ALL_ROLES",
  GET_USER_BY_USER_ID: "GET_USER_BY_USER_ID",
};
