import { createSlice } from "@reduxjs/toolkit";
import { rowPerPage } from "../../Constants";

const initialState = {
  isDeletedLoading: false,
  isMemLoading: false,
  isMemberTimesheetLoading: false,
  isMemberInvoiceLoading: false,
  isMemberAdded: false,
  memberAddUpdateError: false,
  memberPageSize: rowPerPage,
  memberCurrentPage: 0,
  deletedMemberPageSize: rowPerPage,
  deletedMemberCurrentPage: 0,
  error: "",
  deletedMember: [],
  members: [],
  skills: [],
  isLeaveRecordPending: false,
  leaveRecords: [],
  isPaging: false,
  isDeletedPaging: false,
  page: 0,
  deletedPage: 0,
  totalMember: 0,
  totalDeletedMember: 0,
  id: "",
  details: [],
  leaveRecordError: false,
  teamMemberDesignations: [],
  membersTimesheet: [],
  membersInvoives: [],
  membersTimesheetError: "",
  membersInvoicesError: "",
};

const peopleSlice = createSlice({
  name: "people",
  initialState,
  reducers: {
    designationSuccess: (state, { payload }) => {
      state.teamMemberDesignations = payload;
    },
    designationFail: (state) => {
      state.teamMemberDesignations = [];
    },
    deletedPending: (state) => {
      state.isDeletedLoading = true;
    },
    memberPending: (state) => {
      state.isMemLoading = true;
      state.details = []
    },
    memberTimesheetPending: (state) => {
      state.isMemberTimesheetLoading = true;
    },
    memberTimesheetSuccess: (state, { payload }) => {
      state.isMemberTimesheetLoading = false;
      state.membersTimesheet = payload;
    },
    memberTimesheetFail: (state, { payload }) => {
      state.isMemberTimesheetLoading = false;
      state.membersTimesheetError = payload;
    },
    memberInvoicePending: (state) => {
      state.isMemberInvoiceLoading = true;
    },
    memberInvoiceSuccess: (state, { payload }) => {
      state.isMemberInvoiceLoading = false;
      state.membersInvoives = payload;
    },
    memberInvoiceFail: (state, { payload }) => {
      state.isMemberInvoiceLoading = false;
      state.membersInvoicesError = payload;
    },
    deletedSuccess: (state, { payload }) => {
      state.isDeletedLoading = false;
      state.deletedMember = payload.teamMembers;
    },
    memberSuccess: (state, { payload }) => {
      state.isMemLoading = false;
      state.members = payload.teamMembers;
    },
    deletedFail: (state, { payload }) => {
      state.isDeletedLoading = false;
      state.error = payload;
    },
    memberFail: (state, { payload }) => {
      state.isMemLoading = false;
      state.error = payload;
    },
    updateSkills: (state, { payload }) => {
      state.skills = payload;
    },
    memberAdded: (state, { payload }) => {
      state.isMemberAdded = payload;
    },
    memberAddedUpdateError: (state, { payload }) => {
      state.memberAddUpdateError = payload;
    },
    paging: (state, { payload }) => {
      state.isPaging = payload.isPaging;
      state.page = payload.page;
      state.memberPageSize = payload.pageSize;
      state.memberCurrentPage = payload.currentPage;
    },
    memberCount: (state, { payload }) => {
      state.totalMember = payload;
    },
    deletedpaging: (state, { payload }) => {
      state.isDeletedPaging = payload.isPaging;
      state.deletedPage = payload.page;
      state.deletedMemberPageSize = payload.pageSize;
      state.deletedMemberCurrentPage = payload.currentPage;
    },
    deletedMemberCount: (state, { payload }) => {
      state.totalDeletedMember = payload;
    },
    editMember: (state, { payload }) => {
      state.id = payload;
      state.memberAddUpdateError = false;
    },
    memberDetails: (state, { payload }) => {
      state.details = payload;
      state.isMemLoading = false;
    },
    clearMemberList: (state) => {
      state.deletedMember = [];
      state.members = [];
    },
    isLeaveRecordPending: (state) => {
      state.isLeaveRecordPending = true;
    },
    leaveRecordSuccess: (state, { payload }) => {
      state.isLeaveRecordPending = false;
      state.leaveRecords = payload;
    },
    leaveRecordFail: (state, { payload }) => {
      state.isLeaveRecordPending = false;
      state.leaveRecordError = payload;
    },
    clearLeaveRecord: (state) => {
      state.isLeaveRecordPending = false;
      state.leaveRecords = [];
      state.leaveRecordError = false;
    },
  },
});

const { reducer, actions } = peopleSlice;

export const {
  memberInvoicePending,
  memberTimesheetPending,
  clearLeaveRecord,
  leaveRecordFail,
  leaveRecordSuccess,
  isLeaveRecordPending,
  clearMemberList,
  deletedPending,
  memberPending,
  deletedSuccess,
  memberSuccess,
  deletedFail,
  memberFail,
  updateSkills,
  memberAdded,
  memberAddedUpdateError,
  paging,
  memberCount,
  editMember,
  memberDetails,
  deletedpaging,
  deletedMemberCount,
  designationSuccess,
  designationFail,
  memberInvoiceFail,
  memberInvoiceSuccess,
  memberTimesheetFail,
  memberTimesheetSuccess,
} = actions;

export default reducer;
