import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { sagaActions } from "../../../redux/sagaActions";
import IOSSwitch from "../../../shared/components/Switch/IOSSwitch";
import { usePermissions } from "../../../utils/permission";
import SelectBox from "../../../shared/components/SelectBox/SelectBox";

const filter = [
  { id: "quaterly", name: "Quaterly" },
  { id: "current year", name: "Current year" },
  { id: "last Year", name: "Last Year" },
];

const PerformanceFilter = ({ annaulFeedbackFlag, handleSwithControl }) => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const { Team_Member } = usePermissions();
  const [period, setPeriod] = useState("");

  const initialStartDate = moment()
    .subtract(12, "months")
    .startOf("month")
    .toDate();
  const initialEndDate = moment().endOf("month").toDate();

  const [feedbackType, setFeedbackType] = useState(0);
  const [feedbackFromDate, setFeedbackFromDate] = useState(initialStartDate);
  const [feedbackToDate, setFeedbackToDate] = useState(initialEndDate);

  useEffect(() => {
    dispatch({
      type: sagaActions.FETCH_EMPLOYEE_PERFORMANCE,
      payload: {
        employeeId: id,
        feedbackFrom: initialStartDate,
        feedbackTo: initialEndDate,
        feedbackType: annaulFeedbackFlag ? 4 : 0,
      },
    });
  }, [annaulFeedbackFlag]);

  const handleFeedbackTypeChange = (event: any) => {
    setFeedbackType(event.target.value);
  };

  const handleFromDateChange = (date: Date | null) => {
    if (date !== null) {
      setFeedbackFromDate(date);
    }
  };

  const handleToDateChange = (date: Date | null) => {
    if (date !== null) {
      setFeedbackToDate(date);
    }
  };

  const handleFilter = () => {
    dispatch({
      type: sagaActions.FETCH_EMPLOYEE_PERFORMANCE,
      payload: {
        employeeId: id,
        feedbackFrom: feedbackFromDate,
        feedbackTo: feedbackToDate,
        feedbackType,
      },
    });
  };

  const handleReset = () => {
    setFeedbackType(0);
    setFeedbackFromDate(initialStartDate);
    setFeedbackToDate(initialEndDate);

    dispatch({
      type: sagaActions.FETCH_EMPLOYEE_PERFORMANCE,
      payload: {
        employeeId: id,
        feedbackFrom: initialStartDate,
        feedbackTo: initialEndDate,
        feedbackType: 0,
      },
    });
  };

  const handleChange = (e) => {
    setPeriod(e.target.value);
    const dateObj = {
      Quaterly: {
        feedbackFrom: moment().startOf("quarter").toISOString(),
        feedbackTo: moment().endOf("quarter").toISOString(),
      },
      "Current year": {
        feedbackFrom: moment().startOf("year").toISOString(),
        feedbackTo: moment().endOf("year").toISOString(),
      },
      "Last Year": {
        feedbackFrom: moment()
          .subtract(1, "year")
          .startOf("year")
          .toISOString(),
        feedbackTo: moment().subtract(1, "year").endOf("year").toISOString(),
      },
    };

    dispatch({
      type: sagaActions.FETCH_EMPLOYEE_PERFORMANCE,
      payload: {
        employeeId: id,
        feedbackType: 0,
        ...dateObj[e.target.value],
      },
    });
  };

  return (
    <>
      {Team_Member ? (
        <Grid
          container
          mt={1}
          sx={{ backgroundColor: "#f2f4f6", minHeight: 70 }}
        >
          <Grid item sm={3}>
            <SelectBox
              label="Period"
              name="period"
              value={period}
              onChange={handleChange}
              options={filter.map((level) => ({
                id: level.id,
                value: level.name,
                label: level.name,
              }))}
              error={false}
              touched={false}
              ariaLabel="period"
            />
          </Grid>
        </Grid>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexWrap: isMobile ? "wrap" : "nowrap",
            justifyContent: "space-between",
            p: 2,
            backgroundColor: "#fff",
            borderRadius: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: isMobile ? "wrap" : "nowrap",
              justifyContent: "space-between",
            }}
          >
            <TextField
              select
              label="Feedback type"
              value={feedbackType}
              onChange={handleFeedbackTypeChange}
              variant="outlined"
              size="medium"
              disabled={annaulFeedbackFlag}
              sx={isMobile ? { width: "100%" } : { minWidth: 190 }}
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={1}>Monthly feedback</MenuItem>
              <MenuItem value={2}>Reward</MenuItem>
              <MenuItem value={3}>Immediate Action</MenuItem>
            </TextField>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Feedback from"
                disabled={annaulFeedbackFlag}
                value={feedbackFromDate}
                onChange={handleFromDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="medium"
                    sx={isMobile ? { width: "100%" } : {}}
                  />
                )}
              />

              <DatePicker
                label="Feedback to"
                value={feedbackToDate}
                onChange={handleToDateChange}
                disabled={annaulFeedbackFlag}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    size="medium"
                    sx={isMobile ? { width: "100%" } : {}}
                  />
                )}
              />
            </LocalizationProvider>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mt: isMobile ? 2 : 0,
            }}
          >
            <Button
              variant="contained"
              onClick={handleFilter}
              disabled={annaulFeedbackFlag}
              sx={isMobile ? { width: "100%" } : {}}
            >
              Filter
            </Button>

            {!isMobile && (
              <IconButton
                sx={{ marginLeft: 3 }}
                disabled={annaulFeedbackFlag}
                onClick={handleReset}
              >
                <img
                  src={
                    annaulFeedbackFlag
                      ? "/images/reset-disabled.png"
                      : "/images/reset.png"
                  }
                  alt="reset"
                  width={24}
                  height={24}
                />
              </IconButton>
            )}

            {isMobile && (
              <Button
                variant="outlined"
                onClick={handleReset}
                sx={{ width: "100%" }}
                disabled={annaulFeedbackFlag}
              >
                Reset
              </Button>
            )}
            {isMobile && (
              <Box className="filter-switch-performance-details">
                <IOSSwitch
                  label="Annual Feedback"
                  checked={annaulFeedbackFlag}
                  onChange={handleSwithControl}
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default PerformanceFilter;
