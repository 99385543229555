
import { Box, Fade, Grid } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { useState } from 'react';
import TaskAccordionDetails from './TaskAccordionDetails';
import TaskRowHeaderDetails from './TaskRowHeaderDetails';



const TaskAccordion = ({ task, refetchTasks }) => {
    const [expanded, setExpanded] = useState(false)
    return (

        <Grid container item sx={{
            boxShadow: "0px 4px 8px 0px #0000000D"

        }}>
            <Fade in={expanded}>
                <Box sx={{
                    borderLeft: "2px solid #A16AE8"
                }}>

                </Box>
            </Fade>
            <Accordion
                TransitionProps={{
                    unmountOnExit: true
                }}
                onChange={(_, expanded) => setExpanded(expanded)}
                disableGutters
                square
                sx={{
                    borderRadius: "0px",
                    flex: 1
                }}>
                <AccordionSummary sx={{
                    flexDirection: 'row-reverse',
                    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
                        transform: 'rotate(90deg)'
                    },
                    minHeight: "82px",
                    borderRadius: 0,

                }}

                    expandIcon={
                        <img src="/images/down-arrow.svg" alt="" width={20} height={20}></img>
                    }
                >
                    <TaskRowHeaderDetails refetchTasks={refetchTasks} task={task}></TaskRowHeaderDetails>
                </AccordionSummary>
                <AccordionDetails sx={{
                    borderRadius: 0,
                }}
                >
                    <TaskAccordionDetails task={task}></TaskAccordionDetails>
                </AccordionDetails>

            </Accordion>
        </Grid>
    )


}

export default TaskAccordion