import { useState } from "react";
import { useSelector } from "react-redux";
import { clientAdmin, patonaAdmin, rowPerPage, teamMember } from "../../Constants";
import { useGetFilteredUsersQuery } from "../../redux/User/userApi";
import DataGrid from "../../shared/components/DataGrid/DataGrid";
import { TabPanel } from "../../shared/components/TabPanel/TabPanel";
import { UserData } from "../../Types/Users/User";
import { EmployerRoles, TeamifiedRoles, userAccess } from "../../utils/CommonFunction";
import { usePermissions } from "../../utils/permission";
import { userGridHeader, userGridRow } from "./User.helper";

export const UserListData = ({ tabValue,
    setResendData,
    setShowReInviteModal,
    disableTeamMember,
    enableTeamMember,
    enableDisableRowMember,
    getClientID,
    userAction,
    userFilterAction,
    searchVal = '',
    type = clientAdmin,
    tabIndex = 0,
}) => {
    const [pageNumber, setPageNumber] = useState<number>(0)
    const {Client} = usePermissions()
    const {clientCode} = useSelector(
        //@ts-ignore
        (state) => state.client
      );

    let roles = EmployerRoles

    if(type === patonaAdmin){
        roles = TeamifiedRoles
    }

    if (type === teamMember){
        roles = [teamMember]
    }
    

    const { User_AddNewInvitation, User_DisableUser } = usePermissions();
    const [headerSortAction, setHeaderSortAction] = useState(userFilterAction[0].action)

    const { data: userList } = useGetFilteredUsersQuery({
        clientId:  Client? clientCode: userAccess().ClientId,
        disabled: headerSortAction === 'getAllUsers',
        pageNumber: pageNumber,
        pageSize: rowPerPage,
        roles: roles,
        searchValue: searchVal
    }, {
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

      const page = Math.ceil(
        (userList?.data?.paginationFilter.count || 0) / (userList?.data?.paginationFilter.pageSize || 0)
      );
    return <TabPanel value={tabValue} index={tabIndex}>
        <DataGrid
            header={userGridHeader(type)}
            row={userGridRow((userList?.data.users || []) as UserData[], type, User_AddNewInvitation)}
            headerFilterActionList={userFilterAction}
            actionList={User_DisableUser ? userAction : []}
            getDetails={getClientID}
            showPaging={true}
            page={page}
            getPageList={(page) => {
                setPageNumber(page)
            }}
              headerSort={(action) => {
                console.log(action)
                setHeaderSortAction(action);
              }}
            cellClick={(data) => {
                setResendData(data);
                setShowReInviteModal(true);
            }}
            rowPerPage={(page) => {
                setPageNumber(page)
            }}
            disableTeamMember={disableTeamMember}
            enableTeamMember={enableTeamMember}
            enableDisableRow={enableDisableRowMember}
            defaultRowPerPageCount={rowPerPage}
            currentPage={userList?.data.paginationFilter.pageNumber}
        />
    </TabPanel>
}