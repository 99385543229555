import { Button, Collapse, Grid, IconButton, Typography } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";
import { CloseIcon } from "../../shared/components/Icons";
import Search from "../../shared/components/Search/Search";
import { usePermissions } from "../../utils/permission";
import AddNewTaskModal from "./AddNewTaskModal";
import TaskHeaderFilterMenu from "./TaskHeaderFilterMenu";

export type MenuOptions = {
    id: string
    title: string
    options?: Array<MenuOptions>
}
const MENU_OPTION: Array<MenuOptions> = [
    {
        id: 'status',
        title: 'Task Status',
        options: [{
            id: 'complete',
            title: 'Complete'
        }, {
            id: 'pending',
            title: 'Pending'
        }],
    },
    {
        id: 'priority',
        title: 'Priority',
        options: [{
            id: 'low',
            title: 'Low'
        }, {
            id: 'medium',
            title: 'Medium'
        }, {
            id: 'urgent',
            title: 'Urgent'
        }]
    }
]

const TasksHeader = ({ setSearchText, filters, setFilters }) => {
    const { Task_AddTask } = usePermissions()
    const [showAddTask, setShowAddTask] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)


    const closeAddTask = useCallback(() => {
        setShowAddTask(false)
    }, [])
    const openAddTask = useCallback(() => {
        setShowAddTask(true)
    }, [])

    const showMenu = Boolean(anchorEl)
    const closeMenu = useCallback(() => {
        setAnchorEl(null)
    }, [])

    const handleFilterClick = useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        },
        []
    );

    const getSearch = (searchText) => {
        setSearchText(searchText)
    }

    const filteredFilters = useMemo(() => {return Object.entries(filters).filter(el => el[1] !== '')},[filters])
    return (
        <Grid
            container
            flexDirection={'column'}
            gap={1}
        >
            <Grid container justifyContent={'space-between'} alignItems={'center'}>
                <Grid item xs={6}>
                    <Search
                        placeHolder="Search by title or description"
                        getSearch={getSearch}
                    />
                </Grid>
                <Grid container justifyContent={'flex-end'} gap={2} xs={6}>
                    <Grid item>
                        <Button onClick={handleFilterClick} variant="outlined" sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '20px'
                        }}>
                            Filter
                        </Button>
                        <TaskHeaderFilterMenu 
                        anchorEl={anchorEl} 
                        handleClose={closeMenu} 
                        open={showMenu} 
                        options={MENU_OPTION} 
                        changeOption={setFilters}
                        filters={filters}></TaskHeaderFilterMenu>
                    </Grid>
                    <Grid item>
                        {Task_AddTask && <Button onClick={openAddTask} disabled={showAddTask} variant="contained" sx={{
                            fontWeight: 500,
                            fontSize: '14px',
                            lineHeight: '20px'
                        }}>
                            Add New Task
                        </Button>}
                    </Grid>



                </Grid>

            </Grid>


            <Grid container item xs={12} md={12} sx={{ display: "flex" }}>
                <Collapse in={filteredFilters.length > 0}>
                        <Grid container flexWrap={'nowrap'} gap={2}>
                        {filteredFilters.map((el) => (<Grid container item gap={1} alignItems={'center'} flexWrap={'nowrap'} sx={{
                            bgcolor: '#F0E9FC',
                            px:2,
                            py: 1,
                        }} > 
                            <Typography noWrap variant="body2" textTransform={'capitalize'}>{el[0]}: {el[1]}</Typography>
                            <IconButton onClick={() => {
                                setFilters({[el[0]] : ''})
                            }}><CloseIcon width={14} height={14}></CloseIcon></IconButton></Grid>))}

                        </Grid>
                </Collapse>
                <Collapse in={showAddTask} sx={{
                    width: '100%',
                    boxShadow: '0px 4px 8px 0px #0000000D',
                    bgcolor: '#fff',
                    borderLeft: '2px solid #A16AE8',
                    px: 2,
                    py: 1

                }}>
                    <Typography fontSize={'24px'} lineHeight={'32px'} fontWeight={700}>Add new task</Typography>
                    <AddNewTaskModal closeModal={closeAddTask}></AddNewTaskModal>
                </Collapse>
            </Grid>

        </Grid>
    )

}

export default memo(TasksHeader)