import { Button, Grid } from "@mui/material"
import { FC, memo, PropsWithChildren, ReactNode } from "react"
import { Action } from "./TaskAccordionDetails"




type TaskDetailsPageProps = {
    title: ReactNode
    actions: Array<Action>

}

const TaskDetailsPage: FC<PropsWithChildren<TaskDetailsPageProps>> = ({ title, actions, children }) => {
    return (
        <Grid container direction={'column'} >
            <Grid container item justifyContent={'space-between'} alignItems={'center'}>
                <Grid xs={5}>
                    {title}
                </Grid>
                <Grid container item justifyContent={"flex-end"} xs={7}>
                    {actions.map((action) =>
                        <Button
                            disabled={action.disabled}
                            variant="text"
                            color={action.color ? action.color : 'primary'}
                            onClick={action.onClick}>{action.title}
                        </Button>)}
                </Grid>
            </Grid>
            <Grid container item sx={{
                transition: 'all .5s ease-in-out'
            }}>{children}</Grid>
        </Grid>
    )

}

export default memo(TaskDetailsPage)