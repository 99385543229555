import { Dispatch } from "@reduxjs/toolkit";
import { clientAdmin, rowPerPage } from "../../Constants";
import { UserData } from "../../Types/Users/User";
import data from "../../data/address.json";
import { clientUserAddedSuccessfully } from "../../redux/User/userSlice";
import { sagaActions } from "../../redux/sagaActions";
import { userAccess } from "../../utils/CommonFunction";

export const getCompanyDetails = async (dispatch: Dispatch<any>) => {
  try {
    const payload = {
      Id: userAccess().companyId,
    };
    dispatch({ type: sagaActions.COMPANY_DETAILS, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getClients = async (dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.COMPANY_LIST });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getAllWorkClients = (dispatch: Dispatch<any>) => {
  dispatch({ type: sagaActions.GET_ALL_WORKABLE_CLIENTS });
};

export const saveSuccessFully = (dispatch: Dispatch<any>) => {
  dispatch(clientUserAddedSuccessfully());
};

export const saveClient = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.SAVE_USER_CLIENT, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const resentInvite = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.RESEND_INVITE, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const disableEnableUser = async (payload, dispatch: Dispatch<any>) => {
  try {
    dispatch({ type: sagaActions.DISABLE_ENABLE_USER, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getAllRoles = (dispatch: Dispatch<any>) => {
  dispatch({ type: sagaActions.GET_ALL_ROLES });
};

export const getUsersListList = async (
  dispatch: Dispatch<any>,
  firstLoad = false,
  page,
  role = "",
  pageSize = rowPerPage,
  isFilter = false,
  SearchValue = "",
  sort = false,
  columnName = "",
  isActive = true,
  isInActive = true,
  isCurrentMonth = false
) => {
  try {
    let payload = {
      pageNumber: page,
      pageSize,
      clientId: userAccess().clientCode,
      isFilter: isFilter,
      searchValue: SearchValue,
      isActive: isActive,
      isInActive: isInActive,
      isCurrentMonth: isCurrentMonth,
      isDesc: sort,
      columnName: columnName,
      role: role,
    };
    if (firstLoad) {
      //@ts-ignore
      payload.load = 0;
    }

    dispatch({ type: sagaActions.USERS_LIST, payload: payload });
  } catch (error: any) {
    if (error) {
      console.log("error");
    }
  }
};

export const getSearch = (
  dispatch: Dispatch<any>,
  role: string,
  isFilter: boolean,
  input: string,
  pageSize = rowPerPage
) => {
  getUsersListList(dispatch, false, 1, role, pageSize, isFilter, input);
};

export const sortMember = (
  dispatch: Dispatch<any>,
  role,
  isFilter: boolean,
  SearchValue: string,
  sort: boolean,
  key: string,
  pageSize = rowPerPage
) => {
  getUsersListList(
    dispatch,
    false,
    1,
    role,
    pageSize,
    isFilter,
    SearchValue,
    sort,
    key
  );
};

export const getList = async (
  dispatch: Dispatch<any>,
  action,
  page,
  role = "",
  pageSize = rowPerPage
) => {
  switch (action) {
    case "getAllUsers":
      getUsersListList(dispatch, false, 1, role, pageSize);
      break;
    case "getCurrentMonthUsers":
      getUsersListList(
        dispatch,
        false,
        page,
        role,
        pageSize,
        true,
        "",
        false,
        "",
        false,
        false,
        true
      );
      break;
    case "getActiveUsers":
      getUsersListList(
        dispatch,
        false,
        page,
        role,
        pageSize,
        true,
        "",
        false,
        "",
        true,
        false,
        false
      );
      break;
    case "getInactiveUsers":
      getUsersListList(
        dispatch,
        false,
        page,
        role,
        pageSize,
        true,
        "",
        false,
        "",
        false,
        true,
        false
      );
      break;
    default:
      getUsersListList(dispatch, false, page, role, pageSize);
  }
};

export const userGridHeader = (type = "") => {
  const columns = [
    {
      id: 1,
      name: "Name",
      soryKey: "Name",
      type: "heading",
      sortable: true,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 2,
      name: "Company",
      soryKey: "Company",
      type: "heading",
      sortable: false,
      display: type === clientAdmin ? true : false,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 3,
      name: "Email address",
      soryKey: "Email",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 4,
      name: "Location",
      soryKey: "Location",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 5,
      name: "",
      soryKey: "Invitation",
      type: "heading",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
    {
      id: 6,
      name: "",
      soryKey: "filter",
      type: "filter",
      sortable: false,
      display: true,
      color: "#000000",
      fontSize: 16,
      fontWeight: "500",
      textTransform: "capitalize",
    },
  ];
  return columns;
};

export const invitationStatus = (rowV) => {
  let chipColor = "info";
  let chipText = "";
  let chip = "";
  if (!rowV.isRegistered) {
    if (rowV.isInvitationNotAccepted) {
      if (rowV.isInvitationExpired) {
        chipText = "Invitation expired";
        chipColor = "error";
        chip = "chip";
      } else {
        chipText = "Invitation not accepted";
        chipColor = "warning";
        chip = "chip";
      }
    } else if (rowV.isInvitationExpired) {
      chipText = "Invitation expired";
      chipColor = "error";
      chip = "chip";
    }
  }
  return { chipColor: chipColor, chipText: chipText, chip: chip };
};

export const userGridRow = (rowValue: Array<UserData>, type = "", showResend = false) => {
  const row = rowValue?.map((rowV, index) => {
    const country = data.countries.filter((con) => {
      return rowV?.country === con.id;
    });

    let columns: any = [];
    columns.push(
      {
        id: rowV.userId,
        name: rowV.firstName ? rowV.firstName + " " + rowV.lastName : "-",
        type: "title",
        experience: false,
        avatar: false,
        editable: false,
        display: true,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.userId,
        name: rowV.companyName ? rowV.companyName : "-",
        type: "text",
        experience: false,
        editable: false,
        display: type === clientAdmin ? true : false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.userId,
        name: rowV?.email ? rowV?.email : "-",
        type: "text",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
      },
      {
        id: rowV.userId,
        name: rowV?.country
          ? country && country?.length > 0
            ? country[0].name
            : "-"
          : "-",
        type: "text",
        display: true,
        experience: false,
        editable: false,
        editableType: "number",
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      {
        id: rowV.userId,
        name: invitationStatus(rowV).chipText,
        type: invitationStatus(rowV).chip,
        experience: false,
        display: true,
        editable: false,
        color: invitationStatus(rowV).chipColor,
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
      },
      
    );
    if(showResend){
      columns.push({
        id: rowV.tokenId,
        email: rowV.email,
        companyName: rowV.companyName,
        actionType: "resend_invite",
        name: "Resend invite",
        type: rowV.isRegistered ? "action" : "outlined_button",
        experience: false,
        display: true,
        editable: false,
        color: "#000000",
        fontSize: 12,
        fontWeight: "400",
        textTransform: "capitalize",
        peopleId: rowV.userId,
        disable: rowV?.isActive && showResend,
      })
    }else{
      columns.push({
        id: rowV.tokenId,
        email: rowV.email,
        companyName: rowV.companyName,
        actionType: "",
        name: "",
        display: true,
        editable: false,
      })
    }
    const isDisabled = rowV?.isActive
      ? false
      : rowV?.userId === null
      ? false
      : true;
    return { ...rowV, isDisabled: isDisabled, columns };
  });

  return row;
};
