import { Box, Skeleton, Typography } from "@mui/material";
import { memo, useEffect } from "react";
import { useAlert } from "react-alert";
import { useGetProfileQuery } from "../../../redux/User/userApi";

const AssigneeUser = ({ assigneeUser, handleReporterClick = () => {} }) => {

    const alert = useAlert()

    const {data: profile, isLoading,isError } = useGetProfileQuery({
        userId: assigneeUser
    }, {
        skip: !assigneeUser
    })


    useEffect(() => {
        if (isError) {
            alert.error('Something went wrong fetching User')
        }
    }, [alert, isError])


    return (
        <Box>


            {assigneeUser && isLoading && <Skeleton variant="rectangular" sx={{
                width: '100px',
                height: '20px',
            }}></Skeleton>}
            {assigneeUser && !isLoading && <Typography variant="body2"> {profile?.data?.firstName} {profile?.data?.lastName}</Typography>}
            {!assigneeUser && <Typography sx={{
                fontWeight: 500,
                backgroundColor: '#F7EBFC',
                borderRadius: '10px',
                ":hover": {
                    backgroundColor: '#F7EBFC',
                },
                padding: 1,
                lineHeight: '20px',
                fontSize: '14px',
                textTransform: 'none',
                color: '#8026A1',
                wordSpacing: '2px',

            }}>Yet to assign</Typography>
           }

        </Box>)
}

export default memo(AssigneeUser,(prevProps, nextProps) => {
    return prevProps.assigneeUser === nextProps.assigneeUser
})