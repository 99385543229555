import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { CloseIcon, FileIcon } from "../../shared/components/Icons";

const SelectFile = ({
  id,
  label,
  handleFileChange,
  selectedFile,
  handleClear,
}) => {
  const handleClick = () => {
    const fileInput = document.getElementById(id) as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <input
        type="file"
        accept=".pdf,.doc,.docx"
        id={id}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
      <TextField
        label={label}
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: selectedFile ? (
            <InputAdornment position="end">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: "lightgray",
                  borderRadius: "4px",
                  padding: "2px 8px",
                }}
              >
                <span style={{ marginRight: "8px", color: "#000" }}>
                  {selectedFile.name}
                </span>
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClear();
                  }}
                  sx={{ padding: 0 }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
            </InputAdornment>
          ) : null,
          endAdornment: (
            <InputAdornment position="start">
              <IconButton
                aria-label="upload"
                component="span"
                onClick={handleClick}
              >
                <FileIcon />
              </IconButton>
            </InputAdornment>
          ),
          readOnly: true,
        }}
        onClick={handleClick}
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "transparent",
          },
        }}
      />
    </Box>
  );
};

export default SelectFile;
