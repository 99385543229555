import { CircularProgress, Grid } from "@mui/material";
import { memo, useEffect } from "react";
import { useAlert } from "react-alert";
import { useGetTaskByCreatorIdQuery } from "../../redux/Task/TaskApi";
import { userAccess } from "../../utils/CommonFunction";
import TabPanel from "../Hire/common/TabPanel";
import TaskTable from "./TaskTable";
import { useFilteredTaskData } from "./task-helper";

const CreatedTaskList = ({ tabValue, filters, searchText }) => {

    const { data, isLoading, refetch, isError, error } = useGetTaskByCreatorIdQuery({
        createdBy: userAccess().userId
    })
    const alert = useAlert()

    useEffect(() => {
        if (isError) {
            alert.error('Something went wrong: Created Tasks')
        }
    }, [alert, isError])


    const filteredData = useFilteredTaskData(data?.data || [], filters, searchText)

    return (
        <TabPanel tabValue={tabValue} index={0}>
            {isLoading ? <Grid container item justifyContent={'center'} alignItems={'center'} height={'100%'}>
                <CircularProgress size={36}></CircularProgress>
            </Grid> : <TaskTable tasksList={filteredData} refetchTasks={refetch} />}
        </TabPanel>
    )
}

export default memo(CreatedTaskList)