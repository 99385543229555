import { BaseQueryFn } from "@reduxjs/toolkit/dist/query"
import { AxiosError, AxiosRequestConfig } from "axios"
import api from "./BaseApi"

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string
      method?: AxiosRequestConfig['method']
      body?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      headers?: AxiosRequestConfig['headers']
    },
    unknown,
    unknown
  > =>
  async ({ url, method, body, params, headers }) => {
    try {
      const result = await api({
        url: baseUrl + url,
        method,
        data: body,
        params,
        headers,
      })
      return  {data: result}
    } catch (axiosError) {
      const err = axiosError as AxiosError
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

  export default axiosBaseQuery