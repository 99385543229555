import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";

const SelectBox = ({
  label,
  name,
  value,
  onChange,
  options,
  error,
  touched,
  ariaLabel,
  displayEmpty = false,
  fullWidth = true,
  ...props
}) => {
  return (
    <FormControl
      fullWidth={fullWidth}
      error={touched && Boolean(error)}
      {...props}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        label={label}
        labelId={`${name}-label`}
        name={name}
        value={value}
        onChange={onChange}
        displayEmpty={displayEmpty}
        inputProps={{ "aria-label": ariaLabel || label }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {touched && error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default SelectBox;
