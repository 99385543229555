/* eslint-disable jsx-a11y/alt-text */
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { City, Country, State } from "country-state-city";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { leaveTypesArray } from "../../Constants";
import { STRINGS } from "../../Strings";
import { memberAddedUpdateError } from "../../redux/People/peopleSlice";
import { editMemberSchema } from "../../schemas/edit-member";
import { getResumeByUrl, uploadProfilePhoto } from "../../services/People";
import DataGrid from "../../shared/components/DataGrid/DataGrid";
import MobileSkeleton from "../../shared/components/Skeletons/MobileSkeleton";
import TableSkeleton from "../../shared/components/Skeletons/TableSkeleton";
import Skills from "../../shared/components/Skills/Skills";
import { TabPanel } from "../../shared/components/TabPanel/TabPanel";
import { getCurrentFiscalYear, userAccess } from "../../utils/CommonFunction";
import { leaveSummaryIcons } from "../Approvals/ApprovalDetails";
import { getBase64File, imageUpload } from "../Client/UserDetails/User.helper";
import {
  getInvoiceDownloadingById,
  memberInvoiceHeader,
  memberInvoicesRow,
} from "../Invoice/Invoice.helper";
import {
  MemberDetailTimesheetColumns,
  memberDetailTimesheetColumnsRow,
} from "../TimeSheets/Timesheet.helper";
import { experienceArray } from "./AddMember";
import MemberTimesheetDetail from "./MemberTimesheetDetail";
import {
  getLeaveRecord,
  getMemberInvoiceRecord,
  getMemberTimesheetRecord,
  getZohoDesignations,
  imageReturnFromZoho,
  saveSuccessFully,
  updateKeys,
  updateMember,
} from "./People.helper";
import { styles } from "./PeopleStyle";
import ViewMember from "./ViewMember";

let initialValues = {
  JobTitle: "",
  Designation: "",
  Experience: "",
  SalaryPA: "",
  ClientId: userAccess().clientCode,
  SkillSet: [],
  JoiningDate: "",
  CompanyName: "",
  FirstName: "",
  LastName: "",
  EmailID: "",
  EmailAddress: "",
  EmployeeId: "",
  IsResumeEdited: false,
  isPhotoEdited: false,
  employeeType: "",
  TeamMemberId: 0,
  zohoId: 0,
  updatationKeyValuesList: [],
  IsPatonaTeamMember: false,
  Mobile: "",
  resumeFileName: "",
  resumeFile: "",
  profilePhotoFileName: "",
  profilePhotoFile: "",
  Other_Email: "",
  Permanent_Address: "",
  LinkedIn_URL: "",
  CountryCode: "",
  StateCode: "",
  PinCode: "",
  City: "",
  permanentAddress: {},
  tabularData: {},
};

const pendingInvoiceAction = [
  { name: "View", action: "getDetails", display: true },
  {
    name: "Download",
    action: "download",
    display: true,
  },
];
const EditMemberForm: React.FunctionComponent<any> = (props) => {
  const navigate = useNavigate();
  const classes = styles();
  const {
    headerColor = "#fffaea",
    editForm = false,
    setEditForms = () => null,
  } = props;

  const {
    isLeaveRecordPending,
    leaveRecords,
    leaveRecordError,
    isMemLoading,
    isMemberAdded,
    details,
    memberAddUpdateError,
    teamMemberDesignations,
    isMemberTimesheetLoading,
    membersTimesheet,
    isMemberInvoiceLoading,
    membersInvoives,
  } = useSelector(
    //@ts-ignore
    (state) => state.people
  );

  const alert = useAlert();
  const isMobile = useMediaQuery(`(max-width: 760px)`);
  const skeleton = isMobile ? <MobileSkeleton /> : <TableSkeleton />;
  const [tabViewMemberValue, setTabViewMemberValue] = useState(0);
  const dispatch = useDispatch();
  const [addMemberLoading, setAddMemberLoading] =
    React.useState<boolean>(false);
  const [leaveType, setLeaveType] = React.useState<string[]>(leaveTypesArray);
  const [leavePeriod, setLeavePeriod] = React.useState<string>("All");
  const [leaveStatus, setLeaveStatus] = React.useState<string>("All");
  const [searched, setSearched] = useState<string>("");
  const [emergencyContact, setArrEmergencyContact] = useState(
    details?.emergencyContactDetails || []
  );
  const [deleteEmergencyContact, setArrDeleteEmergencyContact] = useState([]);

  const [workHistory, setArrWorkHistory] = useState(
    details?.workExperiences || []
  );
  const [deleteWorkHistory, setArrDeleteWorkHistory] = useState([]);

  const [photoName, setPhotoName] = React.useState<any>("");
  const [dlResume, setDlResume] = React.useState<any>("");
  const [selectPhoto, setPhoto] = React.useState<any>("");
  const [uploadPhoto, setUploadPhoto] = React.useState<any>("");
  const [isPhotoUpdated, setIsPhotoUpdated] = React.useState<boolean>(false);
  const [isResumeUpdated, setIsResumeUpdated] = React.useState<boolean>(false);
  const [editPhotoFile, setEditPhotoFile] = React.useState<any>("");
  const [selectedCountry, setSelectedCountry] = React.useState("");
  const [selectedState, setSelectedState] = React.useState("");
  const [selectedCity, setSelectedCity] = React.useState("");
  const [availableCountries, setAvailableCountries] = React.useState<any>([]);
  const [availableState, setAvailableState] = React.useState<any>([]);
  const [availableCities, setAvailableCities] = React.useState<any>([]);
  const [updatationKeyValuesList, setUpdatationKeyValuesList] =
    React.useState<any>([]);

  const [skills, setSkills] = React.useState<any>([]);
  const [viewSkills, setViewSkills] = React.useState<any>("");
  let [formValues, setFormValues] = useState(null);
  const [fileName, setFileName] = React.useState<any>("");
  const [selectFile, setFile] = React.useState<any>("");
  const [selectedClients, setSelectedClients] = React.useState<any>("");
  const [selectedExperience, setSelectedExperience] = React.useState<any>("");
  const [selectedDesignation, setSelectedDesignation] = React.useState<any>("");
  const [companyName, setCompanyName] = React.useState<any>("");
  const [dojValue, setDOJValue] = React.useState<any>(new Date());
  const [value, setValue] = React.useState(0);
  const [showTimesheetDetail, setShowTimesheetDetail] =
    React.useState<boolean>(false);
  const [timesheetDetailId, setTimesheetDetailId] = React.useState<number>(0);
  const [selectedEmploymentType, setSelectedEmploymentType] =
    React.useState<any>("Permanent");

  const loadCountries = () => {
    setAvailableCountries(Country.getAllCountries());
    setAvailableState(null);
    setAvailableCities(null);
  };

  const loadStates = async (contry) => {
    let states = await State.getStatesOfCountry(contry);
    setAvailableState(states);
    setAvailableCities(null);
  };

  const loadCities = async (country = "", state) => {
    let selectedCountryCode = selectedCountry ? selectedCountry : country;
    let cities = await City.getCitiesOfState(selectedCountryCode, state);
    // console.log(selectedCountryCode, state, "++++++state");
    setAvailableCities(cities);
  };

  useEffect(() => {
    loadCountries();
    setUpdatationKeyValuesList([]);
    getZohoDesignations(dispatch);
    return () => {
      setArrEmergencyContact([]);
      setArrWorkHistory([]);
    };
  }, []);

  const addEmergencyContactFormField = () => {
    setArrEmergencyContact([
      ...emergencyContact,
      {
        contact_Number: null,
        emergency_Contact_Name: null,
        relationship: null,
      },
    ]);
  };

  const removeEmergencyContactFormFields = (index) => {
    const rows = [...emergencyContact];
    const copyDeleteEmergencyContact: any = [...deleteEmergencyContact];
    if (!_.isEmpty(rows[index]?.tabularRowId)) {
      const id = rows[index]?.tabularRowId;
      copyDeleteEmergencyContact.push(id);
      setArrDeleteEmergencyContact(copyDeleteEmergencyContact);
    }
    rows.splice(index, 1);
    setArrEmergencyContact(rows);
  };

  const handleEmergencyContactFormChange = (index, e) => {
    e.preventDefault();
    const arrCopy = [...emergencyContact];
    let updateEmergencyContact = emergencyContact[index];
    let copy = { ...updateEmergencyContact };
    copy[e.target.name] = e.target.value;
    arrCopy[index] = copy;
    setArrEmergencyContact(arrCopy);
  };

  const addWorkHistoryFormField = () => {
    setArrWorkHistory([
      ...workHistory,
      {
        employer: null,
        fromDate: null,
        jobtitle: null,
        todate: null,
      },
    ]);
  };

  const removeWorkHistoryFormFields = (index) => {
    const rows = [...workHistory];
    const copyDeleteWorkHistory: any = [...deleteWorkHistory];
    if (!_.isEmpty(rows[index]?.tabularRowId)) {
      const id = rows[index]?.tabularRowId;
      copyDeleteWorkHistory.push(id);
      setArrDeleteWorkHistory(copyDeleteWorkHistory);
    }
    rows.splice(index, 1);
    setArrWorkHistory(rows);
  };

  const handleWorkHistoryFormChange = (index, e) => {
    e.preventDefault();
    const arrCopy = [...workHistory];
    let updateWorkHistory = workHistory[index];
    let copy = { ...updateWorkHistory };
    copy[e.target.name] = e.target.value;
    arrCopy[index] = copy;
    setArrWorkHistory(arrCopy);
  };

  const handleDateChange = (newValue) => {
    setDOJValue(newValue);
  };
  const handleChangeTabs = async (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setEditForms(false);
    setTabViewMemberValue(newValue);
    if (newValue === 1 && !_.isEmpty(details.resumeURL)) {
      const payload = {
        token: details.zohoAccessToken,
        url: details.resumeURL,
      };
      const data = await getResumeByUrl(payload);
      let base64String;

      let reader = new FileReader();
      //@ts-ignore
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        base64String = reader.result;
        setDlResume(base64String.substr(base64String.indexOf(",") + 1));
      };
    }
    if (newValue === 2) {
      const payload = { email: details.emailAddress, zohoId: details.zoho_ID };
      getLeaveRecord(payload, dispatch);
    }
  };

  useEffect(() => {
    if (memberAddUpdateError) {
      alert.error(memberAddUpdateError);
      setAddMemberLoading(false);
      dispatch(memberAddedUpdateError(false));
    }
  }, [memberAddUpdateError]);

  useEffect(() => {
    if (isMemberAdded) {
      saveSuccessFully(dispatch);
      setAddMemberLoading(false);
      setEditForms(false);
      alert.success(STRINGS.MemberUpdateSuccess);
    }
  }, [isMemberAdded]);

  useEffect(() => {
    if (!_.isEmpty(details)) {
      let state = "";
      if (
        !_.isNull(details.permanentAddress?.stateCode) &&
        details.permanentAddress?.stateCode.includes("-")
      ) {
        state = details.permanentAddress?.stateCode.split("-");
        state = state[1];
      } else {
        state = details.permanentAddress?.stateCode;
      }
      let city = details.permanentAddress?.city
        ? details.permanentAddress?.city
        : "";
      initialValues = {
        FirstName: details.firstName ? details.firstName : "",
        LastName: details.lastName ? details.lastName : "",
        JobTitle: details.jobTitle ? details.jobTitle : "",
        Designation: "",
        Experience: details.experience ? details.experience : "",
        SalaryPA: details.salaryPA ? details.salaryPA : "",
        ClientId: details.clientId,
        TeamMemberId: details.teamMemberId,
        zohoId: details.zoho_ID,
        updatationKeyValuesList: [],
        SkillSet: details.skillSet,
        resumeFileName: details.resumeURL,
        JoiningDate: details.joiningDate ? details.joiningDate : "",
        CompanyName: details.companyName ? details.companyName : "",
        EmailID: details.emailAddress ? details.emailAddress : "",
        EmailAddress: details.emailAddress ? details.emailAddress : "",
        EmployeeId: details.employeeId,
        Other_Email: details.personalEmailID,
        Permanent_Address: details?.permanentAddress?.address1
          ? details?.permanentAddress?.address1
          : "",
        permanentAddress: details.permanentAddress,
        CountryCode: details.permanentAddress?.countryCode,
        StateCode: state,
        City: city,
        PinCode: details.permanentAddress?.pinCode
          ? details.permanentAddress?.pinCode
          : "",
        LinkedIn_URL: details.linkedin ? details.linkedin : "",
        IsResumeEdited: false,
        isPhotoEdited: false,
        employeeType: details.employeeType,
        IsPatonaTeamMember: details.isPatonaTeamMember
          ? details.isPatonaTeamMember
          : "",
        Mobile: details.personalMobileNumber
          ? details.personalMobileNumber
          : "",
        resumeFile: "",
        profilePhotoFileName: details.profilePhotoURL
          ? details.profilePhotoURL
          : "",
        profilePhotoFile: "",
        tabularData: {},
      };
      setFieldValue("Permanent_Address", details?.permanentAddress?.address1);
      setFieldValue("Designation", details.designationId);

      setSelectedEmploymentType(details.employeeType);
      setSelectedExperience(details.experience);
      setSelectedDesignation(details.designationId);
      handleDateChange(details.joiningDate);
      setSelectedClients(details.clientId);
      setFileName(details.resumeURL);
      setFile(details.file);
      setSkills(details.skillSet);
      setCompanyName(details.companyName);
      setEditPhotoFile(details.profilePhotoURL);
      if (details?.skillSet?.length > 1) {
        const skills = details?.skillSet.reduce(function (a, b) {
          return (a.skill || a) + ", " + b.skill;
        });
        setViewSkills(skills);
      } else if (details?.skillSet?.length === 1) {
        const skills = details?.skillSet[0].skill;
        setViewSkills(skills);
      } else {
        setViewSkills("");
      }
      setFieldValue("CountryCode", details.permanentAddress?.countryCode);
      setSelectedCountry(details.permanentAddress?.countryCode);

      loadStates(details.permanentAddress?.countryCode);
      loadCities(details.permanentAddress?.countryCode, state);
      setFieldValue("StateCode", state);
      setSelectedState(state);
      setFieldValue("City", city);
      setSelectedCity(city);
      setArrWorkHistory(details?.workExperiences || [])
      setArrEmergencyContact(details?.emergencyContactDetails || [])

      //@ts-ignore
      setFormValues(initialValues);
    }
  }, [details]);

  const {
    values,
    errors,
    dirty,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: formValues || initialValues,
    validationSchema: editMemberSchema,
    enableReinitialize: true,
    onSubmit: (values, action) => {
      //console.log(values, "+++++values");
      const updateKeyValuePair: any = [];
      values.TeamMemberId = details.teamMemberId;
      values.zohoId = details.zoho_ID;
      values.SkillSet = skills;
      if (updatationKeyValuesList.length > 0) {
        updatationKeyValuesList?.map((val, index) => {
          updateKeyValuePair.push({ key: val, value: values[`${val}`] });
        });
      }
      values.updatationKeyValuesList = updateKeyValuePair;
      //values.FileName = fileName;
      values.Designation = selectedDesignation;
      values.JoiningDate = dojValue;
      values.CompanyName = companyName;
      values.Experience = selectedExperience;
      values.employeeType = selectedEmploymentType;
      values.resumeFile = selectFile;
      values.resumeFileName = fileName;
      values.IsPatonaTeamMember = details.isPatonaTeamMember;
      values.profilePhotoFileName = photoName;
      values.profilePhotoFile = uploadPhoto;
      values.isPhotoEdited = isPhotoUpdated;
      values.IsResumeEdited = isResumeUpdated;
      const address = {
        address1: values?.Permanent_Address,
        city: values?.City,
        countryCode: values?.CountryCode,
        stateCode: values?.StateCode,
        pinCode: values?.PinCode,
      };
      values.permanentAddress = { ...values.permanentAddress, ...address };
      let emergencyTabular = {};
      let employmentTabular = {};
      if (emergencyContact?.length > 0) {
        let addedEmergencyContact = emergencyContact.filter((contacts) =>
          _.isUndefined(contacts?.tabularRowId)
        );

        if (
          !_.isEmpty(addedEmergencyContact) &&
          addedEmergencyContact?.length > 0
        ) {
          addedEmergencyContact = addedEmergencyContact?.map((contacts) => {
            return {
              Emergency_Contact_Name: contacts.emergency_Contact_Name,
              Relationship: contacts.relationship,
              Contact_Number: contacts.contact_Number,
            };
          });
        }

        let updated = emergencyContact.filter(
          (contacts) => !_.isUndefined(contacts?.tabularRowId)
        );

        let updatedKeys = updated?.map((contacts) => {
          return {
            Emergency_Contact_Name: contacts.emergency_Contact_Name,
            Relationship: contacts.relationship,
            Contact_Number: contacts.contact_Number,
          };
        });

        let tabularEmergencyUpdateObj = {};
        for (let k = 0; k < updated?.length; k++) {
          tabularEmergencyUpdateObj[updated[k]?.tabularRowId] = updatedKeys[k];
        }
        const scID = details?.emergencyContactDetailsFormData?.sectionId;

        emergencyTabular[scID] = {
          add: addedEmergencyContact,
          update: tabularEmergencyUpdateObj,
          delete: deleteEmergencyContact,
        };
        if (addedEmergencyContact?.length === 0) {
          delete emergencyTabular[scID].add;
        }
        if (deleteEmergencyContact?.length === 0) {
          delete emergencyTabular[scID].delete;
        }
      }

      if (workHistory?.length > 0) {
        let addedWorkHistory = workHistory.filter((contacts) =>
          _.isUndefined(contacts?.tabularRowId)
        );
        if (!_.isEmpty(addedWorkHistory) && addedWorkHistory?.length > 0) {
          addedWorkHistory = addedWorkHistory?.map((contacts) => {
            return {
              Jobtitle: contacts.jobtitle,
              Employer: contacts.employer,
              FromDate: moment(new Date(contacts.fromDate)).format(
                "DD-MM-YYYY"
              ),
              Todate: moment(new Date(contacts.todate)).format("DD-MM-YYYY"),
            };
          });
        }
        let updatedWorkHistory = workHistory.filter(
          (contacts) => !_.isUndefined(contacts?.tabularRowId)
        );

        let updatedKeysWorkHistory = updatedWorkHistory?.map((contacts) => {
          return {
            Jobtitle: contacts.jobtitle,
            Employer: contacts.employer,
            FromDate: moment(contacts.fromDate).format("DD-MM-YYYY"),
            Todate: moment(contacts.todate).format("DD-MM-YYYY"),
          };
        });

        //console.log(updatedKeysWorkHistory, "++++++values");

        let tabularWorkHistoryObj = {};
        for (let k = 0; k < updatedWorkHistory?.length; k++) {
          tabularWorkHistoryObj[updatedWorkHistory[k]?.tabularRowId] =
            updatedKeysWorkHistory[k];
        }
        const scID = details?.workExperienceFormData?.sectionId;
        employmentTabular[scID] = {
          add: addedWorkHistory,
          update: tabularWorkHistoryObj,
          delete: deleteWorkHistory,
        };
        if (addedWorkHistory?.length === 0) {
          delete employmentTabular[scID].add;
        }
        if (deleteWorkHistory?.length === 0) {
          delete employmentTabular[scID].delete;
        }
      }
      values.tabularData = { ...emergencyTabular, ...employmentTabular };
      // console.log(values, "++++++values");
      //values.file = selectFile;
      setAddMemberLoading(true);
      if (!_.isEmpty(photoName)) {
        const uploadPhotoPayload = {
          recordId: details.zoho_ID,
          fileName: photoName,
          file: uploadPhoto,
        };
        uploadProfilePhoto(uploadPhotoPayload);
      }
      updateMember(values, dispatch);
      action.resetForm();
    },
  });

  function showPhoto() {
    if (photoName) {
      return (
        <img
          className={classes.user_pic}
          src={getBase64File(photoName, uploadPhoto)}
          alt={photoName}
        />
      );
    } else if (editPhotoFile) {
      return (
        <img
          className={classes.user_pic}
          src={imageReturnFromZoho(editPhotoFile)}
          alt={""}
        />
      );
    } else {
      return (
        <Typography variant="h6" className={classes.companyLogoText}>
          {STRINGS.Logo}
        </Typography>
      );
    }
  }

  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  const handleVerticalTabChange = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setShowTimesheetDetail(false);
    setTimesheetDetailId(0);
    setValue(newValue);
    if (newValue === 0) {
      const payload = { email: details.emailAddress, zohoId: details.zoho_ID };
      getLeaveRecord(payload, dispatch);
    }
    if (newValue === 1) {
      const payload = { zohoID: details.zoho_ID };
      getMemberTimesheetRecord(payload, dispatch);
    }
    if (newValue === 2) {
      const payload = {
        clientId: details?.clientId,
        employeeID: details?.employeeId,
        dateFrom: getCurrentFiscalYear()?.startDate,
        dateTo: getCurrentFiscalYear()?.endDate,
      };
      getMemberInvoiceRecord(payload, dispatch);
    }
  };

  const backButtonPress = () => {
    setShowTimesheetDetail(false);
    setTimesheetDetailId(0);
  };

  return (
    <Card
      className="tbl-card"
      sx={{
        minWidth: 275,
        marginBottom: 3,
        marginTop: 2,
        boxShadow: 10,
      }}
    >
      <Box sx={{ width: "97%", mb: 2 }}>
        <Tabs
          value={tabViewMemberValue}
          onChange={handleChangeTabs}
          variant="fullWidth"
        >
          <Tab label={"Information"} />
          <Tab label={"Resume"} />
          {details.isPatonaTeamMember && <Tab label={"Other"} />}
        </Tabs>
      </Box>
      <TabPanel value={tabViewMemberValue} index={0}>
        {(userAccess().client && details.isPatonaTeamMember) ||
        (!userAccess().client && !details.isPatonaTeamMember) ? (
          <ViewMember
            isMemLoading={isMemLoading}
            editForm={false}
            setEditForms={setEditForms}
          />
        ) : (
          <Box
            sx={{
              overflowY: "scroll",
              bgcolor: "background.paper",
              borderRadius: "8px",
              boxShadow: 24,
              p: 2,
              pointerEvents: addMemberLoading ? "none" : "auto",
            }}
            justifyContent={"center"}
            display={"block"}
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
          >
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <Typography
                variant="h5"
                sx={{ display: "flex", textTransform: "capitalize" }}
              >
                {STRINGS.PersonalInformation}
              </Typography>
              <Box>
                {!userAccess().client && isMobile && (
                  <Grid
                    container
                    xs={12}
                    spacing={2}
                    sx={{
                      mt: 1,
                      ml: 0,
                      backgroundColor: "#FFFAEA",
                    }}
                  >
                    <Grid item xs={12} sm={12} md={12}>
                      <Box
                        sx={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ mr: 1 }}
                        >{`Job request:`}</Typography>
                        <Typography variant="caption">{`(${details.teamMemberId})${details.designation}(${details.employeeType})`}</Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ mr: 1 }}
                          >{`Client:`}</Typography>
                          <Typography variant="caption">{`${details.companyName}`}</Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ mr: 2 }}
                        >{`Start date:`}</Typography>
                        <Typography variant="caption">{`${
                          details.joiningDate
                            ? moment(details.joiningDate).format("DD-MM-YYYY")
                            : ""
                        }`}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                )}
                <Grid
                  container
                  spacing={2}
                  sx={{
                    mt: 1,
                    backgroundColor:
                      !userAccess().client && !isMobile ? "#FFFAEA" : "#FFFFFF",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      pr: 2,
                    }}
                  >
                    <Box display="flex" justifyContent="flex-start">
                      <Box className={classes.companyLogo}>{showPhoto()}</Box>
                      <Grid ml={2}>
                        <Typography variant="h6">{"Employee photo"}</Typography>
                        <Typography variant="caption">
                          ({STRINGS.ImageSize})
                        </Typography>
                        <Box
                          display={"flex"}
                          sx={{ mt: 2 }}
                          className="upload-btn-wrapper"
                        >
                          <button className="profile_btn">
                            <FileUploadOutlinedIcon
                              sx={{
                                mr: 1,
                                width: 18,
                                height: 18,
                              }}
                            ></FileUploadOutlinedIcon>
                            <Typography variant="caption">
                              {"Upload"}
                            </Typography>
                          </button>
                          <input
                            type="file"
                            name="myfile"
                            onChange={(e) =>
                              imageUpload(
                                e,
                                setPhotoName,
                                setPhoto,
                                alert,
                                setIsPhotoUpdated,
                                setUploadPhoto
                              )
                            }
                          />
                        </Box>
                      </Grid>
                    </Box>
                    {!userAccess().client && !isMobile && (
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{ mr: 2 }}
                          >{`Job request:    `}</Typography>
                          <Typography variant="caption">{`    (${details.teamMemberId})${details.designation}(${details.employeeType})`}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ mr: 2 }}
                            >{`Client:`}</Typography>
                            <Typography variant="caption">{`${details.companyName}`}</Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{ mr: 2 }}
                            >{`Start date:`}</Typography>
                            <Typography variant="caption">{`${
                              details.joiningDate
                                ? moment(details.joiningDate).format(
                                    "DD-MM-YYYY"
                                  )
                                : ""
                            }`}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      error={Boolean(touched.FirstName && errors.FirstName)}
                      helperText={touched.FirstName && errors.FirstName}
                      margin="normal"
                      fullWidth
                      id="FirstName"
                      label={`${STRINGS.FirstName}*`}
                      name="FirstName"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.FirstName}
                      onChange={(e) => {
                        handleChange(e);
                        updateKeys(
                          e.target.name,
                          updatationKeyValuesList,
                          setUpdatationKeyValuesList
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      error={Boolean(touched.LastName && errors.LastName)}
                      helperText={touched.LastName && errors.LastName}
                      margin="normal"
                      fullWidth
                      id="LastName"
                      label={`${STRINGS.LastName}*`}
                      name="LastName"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.LastName}
                      onChange={(e) => {
                        handleChange(e);
                        updateKeys(
                          e.target.name,
                          updatationKeyValuesList,
                          setUpdatationKeyValuesList
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={-3}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      error={Boolean(touched.Mobile && errors.Mobile)}
                      helperText={touched.Mobile && errors.Mobile}
                      margin="normal"
                      type={"number"}
                      fullWidth
                      id="Mobile"
                      label={`${STRINGS.PersonalNumber}*`}
                      name="Mobile"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.Mobile}
                      onChange={(e) => {
                        handleChange(e);
                        updateKeys(
                          e.target.name,
                          updatationKeyValuesList,
                          setUpdatationKeyValuesList
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      error={Boolean(touched.Other_Email && errors.Other_Email)}
                      helperText={touched.Other_Email && errors.Other_Email}
                      margin="normal"
                      fullWidth
                      id="Other_Email"
                      label={`${STRINGS.PersonalEmailId}*`}
                      name="Other_Email"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 50 }}
                      value={values.Other_Email}
                      onChange={(e) => {
                        handleChange(e);
                        updateKeys(
                          e.target.name,
                          updatationKeyValuesList,
                          setUpdatationKeyValuesList
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.Permanent_Address && errors.Permanent_Address
                      )}
                      helperText={
                        touched.Permanent_Address && errors.Permanent_Address
                      }
                      margin="normal"
                      fullWidth
                      id="Permanent_Address"
                      label={`${STRINGS.Address}*`}
                      name="Permanent_Address"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 50 }}
                      value={values.Permanent_Address}
                      onChange={(e) => {
                        handleChange(e);
                        updateKeys(
                          e.target.name,
                          updatationKeyValuesList,
                          setUpdatationKeyValuesList
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} mt={0.1}>
                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>Country</InputLabel>
                      <Select
                        id="CountryCode"
                        name="CountryCode"
                        label={STRINGS.Country}
                        value={selectedCountry}
                        onChange={(e) => {
                          setSelectedCountry(e.target.value);
                          loadStates(e.target.value);
                          setFieldValue("CountryCode", e.target.value);
                          updateKeys(
                            "permanentAddress",
                            updatationKeyValuesList,
                            setUpdatationKeyValuesList
                          );
                        }}
                      >
                        {availableCountries?.map((value, key) => {
                          return (
                            <MenuItem value={value.isoCode} key={key}>
                              {value.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>State</InputLabel>
                      <Select
                        id="StateCode"
                        name="StateCode"
                        label={STRINGS.State}
                        value={selectedState}
                        onChange={(e) => {
                          setSelectedState(e.target.value);
                          setFieldValue("StateCode", e.target.value);
                          loadCities("", e.target.value);
                          updateKeys(
                            "permanentAddress",
                            updatationKeyValuesList,
                            setUpdatationKeyValuesList
                          );
                        }}
                      >
                        {availableState?.map((e, key) => {
                          return (
                            <MenuItem value={e.isoCode} key={key}>
                              {e.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1, mb: 1 }}>
                  <Grid item xs={6} sm={6} md={6}>
                    <FormControl fullWidth>
                      <InputLabel>City</InputLabel>
                      <Select
                        id="City"
                        name="City"
                        label={STRINGS.City}
                        value={selectedCity}
                        onChange={(e) => {
                          setSelectedCity(e.target.value);
                          setFieldValue("City", e.target.value);
                          updateKeys(
                            "permanentAddress",
                            updatationKeyValuesList,
                            setUpdatationKeyValuesList
                          );
                        }}
                      >
                        {availableCities?.map((e, key) => {
                          return (
                            <MenuItem value={e.name} key={key}>
                              {e.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <TextField
                      error={Boolean(touched.PinCode && errors.PinCode)}
                      helperText={touched.PinCode && errors.PinCode}
                      fullWidth
                      id="PinCode"
                      label={STRINGS.PostalCode}
                      name="PinCode"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 8 }}
                      value={values.PinCode}
                      onChange={(e) => {
                        handleChange(e);
                        updateKeys(
                          "permanentAddress",
                          updatationKeyValuesList,
                          setUpdatationKeyValuesList
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(
                        touched.LinkedIn_URL && errors.LinkedIn_URL
                      )}
                      helperText={touched.LinkedIn_URL && errors.LinkedIn_URL}
                      margin="normal"
                      fullWidth
                      id="LinkedIn_URL"
                      label={`${STRINGS.LinkedIn}*`}
                      name="LinkedIn_URL"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 50 }}
                      value={values.LinkedIn_URL}
                      onChange={(e) => {
                        handleChange(e);
                        updateKeys(
                          e.target.name,
                          updatationKeyValuesList,
                          setUpdatationKeyValuesList
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>
              </Box>
              {!userAccess().client && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    mt: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      display: "flex",
                      textTransform: "capitalize",
                    }}
                  >
                    {STRINGS.EmergencyContact}
                  </Typography>
                  <Button onClick={addEmergencyContactFormField}>
                    <img
                      src="/images/add_input.png"
                      style={{ height: 25, width: 25 }}
                      alt="reset"
                    />
                  </Button>
                </Box>
              )}
              {!userAccess().client &&
                emergencyContact?.map((contact, index) => {
                  return (
                    <>
                      <Box sx={{ display: "flex", mt: 2 }}>
                        <Grid container spacing={2} xs={12}>
                          <Grid item xs={12} sm={4} md={4}>
                            <TextField
                              // error={Boolean(touched.JobTitle && errors.JobTitle)}
                              // helperText={touched.JobTitle && errors.JobTitle}
                              margin="normal"
                              fullWidth
                              id="emergency_Contact_Name"
                              label={`Contact name`}
                              name="emergency_Contact_Name"
                              variant="outlined"
                              autoComplete="off"
                              inputProps={{ maxLength: 50 }}
                              value={contact.emergency_Contact_Name}
                              onChange={(e) => {
                                handleEmergencyContactFormChange(index, e);
                              }}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4}>
                            <TextField
                              // error={Boolean(
                              //   touched.EmployeeId && errors.EmployeeId
                              // )}
                              // helperText={touched.EmployeeId && errors.EmployeeId}
                              margin="normal"
                              type="number"
                              fullWidth
                              id="contact_Number"
                              label={`Contact number`}
                              name="contact_Number"
                              variant="outlined"
                              autoComplete="off"
                              inputProps={{ maxLength: 25 }}
                              value={contact.contact_Number}
                              onChange={(e) => {
                                handleEmergencyContactFormChange(index, e);
                              }}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4} md={4} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                              <InputLabel>{"Relationship"}</InputLabel>
                              <Select
                                id="relationship"
                                name="relationship"
                                label={"Relationship"}
                                value={contact.relationship}
                                onChange={(e) => {
                                  handleEmergencyContactFormChange(index, e);
                                }}
                              >
                                {details?.emergencyContactDetailsFormData?.relationData?.map(
                                  (e, key) => {
                                    return (
                                      <MenuItem value={e.name} key={key}>
                                        {e.name}
                                      </MenuItem>
                                    );
                                  }
                                )}
                              </Select>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            alignContent: "center",
                            alignSelf: "center",
                            mt: 2,
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              display: "flex",
                              textTransform: "capitalize",
                            }}
                          >
                            {""}
                          </Typography>
                          <Button
                            onClick={() =>
                              removeEmergencyContactFormFields(index)
                            }
                          >
                            <RemoveCircleRoundedIcon
                              sx={{
                                height: 25,
                                width: 25,
                                color: "#002DFF",
                              }}
                            ></RemoveCircleRoundedIcon>
                          </Button>
                        </Box>
                      </Box>
                    </>
                  );
                })}
              <Typography
                variant="h5"
                sx={{
                  display: "flex",
                  textTransform: "capitalize",
                  mt: 2,
                }}
              >
                {STRINGS.WorkInformation}
              </Typography>
              <Box>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  {!userAccess().client ? (
                    <Grid item xs={12} sm={12} md={12}>
                      <FormControl fullWidth>
                        <InputLabel>{"Designation"}</InputLabel>
                        <Select
                          id="Designation"
                          name="Designation"
                          label={"Designation"}
                          value={
                            Number.isInteger(parseInt(selectedDesignation))
                              ? selectedDesignation
                              : ""
                          }
                          onChange={(e) => {
                            setSelectedDesignation(e.target.value);
                          }}
                        >
                          {teamMemberDesignations?.map((e, key) => {
                            return (
                              e.name && (
                                <MenuItem value={e.id} key={key}>
                                  {e.name}
                                </MenuItem>
                              )
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} sm={12} md={12}>
                      <TextField
                        error={Boolean(touched.JobTitle && errors.JobTitle)}
                        helperText={touched.JobTitle && errors.JobTitle}
                        margin="normal"
                        fullWidth
                        id="JobTitle"
                        label={`${STRINGS.JobTitle}*`}
                        name="JobTitle"
                        variant="outlined"
                        autoComplete="off"
                        inputProps={{ maxLength: 50 }}
                        value={values.JobTitle}
                        onChange={(e) => {
                          handleChange(e);
                          updateKeys(
                            e.target.name,
                            updatationKeyValuesList,
                            setUpdatationKeyValuesList
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      error={Boolean(touched.EmployeeId && errors.EmployeeId)}
                      helperText={touched.EmployeeId && errors.EmployeeId}
                      margin="normal"
                      fullWidth
                      id="EmployeeId"
                      label={`${STRINGS.EmployeeId}*`}
                      name="EmployeeId"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 25 }}
                      value={values.EmployeeId}
                      onChange={(e) => {
                        handleChange(e);
                        updateKeys(
                          e.target.name,
                          updatationKeyValuesList,
                          setUpdatationKeyValuesList
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel>{STRINGS.Experience}</InputLabel>
                      <Select
                        id="Experience"
                        name="Experience"
                        label={STRINGS.Experience}
                        value={selectedExperience}
                        onChange={(e) => {
                          setSelectedExperience(e.target.value);
                          updateKeys(
                            e.target.name,
                            updatationKeyValuesList,
                            setUpdatationKeyValuesList
                          );
                        }}
                      >
                        {experienceArray?.map((e, key) => {
                          return (
                            <MenuItem value={e.name} key={key}>
                              {e.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <InputLabel>{STRINGS.EmploymentType}</InputLabel>
                      <Select
                        id="EmploymentType"
                        name="EmploymentType"
                        label={STRINGS.EmploymentType}
                        value={selectedEmploymentType}
                        onChange={(e) => {
                          setSelectedEmploymentType(e.target.value);
                          updateKeys(
                            e.target.name,
                            updatationKeyValuesList,
                            setUpdatationKeyValuesList
                          );
                        }}
                      >
                        <MenuItem value={"On Contract"}>
                          {"On Contract"}
                        </MenuItem>
                        <MenuItem value={"Permanent"}>{"Permanent"}</MenuItem>
                        <MenuItem value={"Probation"}>{"Probation"}</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                          label={STRINGS.JoiningDate}
                          inputFormat="MM/DD/YYYY"
                          value={dojValue}
                          onChange={(e) => {
                            handleDateChange(e);
                            updateKeys(
                              STRINGS.JoiningDate,
                              updatationKeyValuesList,
                              setUpdatationKeyValuesList
                            );
                          }}
                          renderInput={(params) => <TextField {...params} />}
                        />
                      </LocalizationProvider>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6} md={6} sx={{ marginTop: -2 }}>
                    <TextField
                      error={Boolean(
                        touched.EmailAddress && errors.EmailAddress
                      )}
                      helperText={touched.EmailAddress && errors.EmailAddress}
                      margin="normal"
                      fullWidth
                      id="EmailAddress"
                      label={`${STRINGS.WorkEmailId}*`}
                      name="EmailAddress"
                      variant="outlined"
                      autoComplete="off"
                      inputProps={{ maxLength: 50 }}
                      value={values.EmailAddress}
                      onChange={(e) => {
                        handleChange(e);
                        updateKeys(
                          e.target.name,
                          updatationKeyValuesList,
                          setUpdatationKeyValuesList
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Skills setSkill={setSkills} editSkill={details.skillSet} />
                  </Grid>
                </Grid>
              </Box>

              {!userAccess().client && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    alignContent: "center",
                    alignSelf: "center",
                    mt: 2,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      display: "flex",
                      textTransform: "capitalize",
                    }}
                  >
                    {STRINGS.EmploymentHistory}
                  </Typography>
                  <Button onClick={addWorkHistoryFormField}>
                    <img
                      src="/images/add_input.png"
                      style={{ height: 25, width: 25 }}
                      alt="reset"
                    />
                  </Button>
                </Box>
              )}
              {!userAccess().client &&
                workHistory?.map((work, index) => {
                  return (
                    <>
                      {index ? (
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            alignContent: "center",
                            alignSelf: "center",
                            mt: 2,
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              display: "flex",
                              textTransform: "capitalize",
                            }}
                          >
                            {""}
                          </Typography>
                          <Button
                            onClick={() => removeWorkHistoryFormFields(index)}
                          >
                            <RemoveCircleRoundedIcon
                              sx={{
                                height: 25,
                                width: 25,
                                color: "#002DFF",
                              }}
                            ></RemoveCircleRoundedIcon>
                          </Button>
                        </Box>
                      ) : null}
                      <Box>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              // error={Boolean(
                              //   touched.EmployeeId && errors.EmployeeId
                              // )}
                              // helperText={touched.EmployeeId && errors.EmployeeId}
                              margin="normal"
                              fullWidth
                              id="jobtitle"
                              label={`Job title`}
                              name="jobtitle"
                              variant="outlined"
                              autoComplete="off"
                              inputProps={{ maxLength: 25 }}
                              value={work?.jobtitle}
                              onChange={(e) => {
                                handleWorkHistoryFormChange(index, e);
                              }}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={6}>
                            <TextField
                              // error={Boolean(
                              //   touched.EmployeeId && errors.EmployeeId
                              // )}
                              // helperText={touched.EmployeeId && errors.EmployeeId}
                              margin="normal"
                              fullWidth
                              id="employer"
                              label={`Company`}
                              name="employer"
                              variant="outlined"
                              autoComplete="off"
                              inputProps={{ maxLength: 25 }}
                              value={work?.employer}
                              onChange={(e) => {
                                handleWorkHistoryFormChange(index, e);
                              }}
                              onBlur={handleBlur}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  label={"Joining date"}
                                  inputFormat="MM/DD/YYYY"
                                  value={work?.fromDate}
                                  onChange={(e) => {
                                    const arrCopy = [...workHistory];
                                    let updateWorkHistory = workHistory[index];
                                    let copy = { ...updateWorkHistory };
                                    copy["fromDate"] = e;
                                    arrCopy[index] = copy;
                                    setArrWorkHistory(arrCopy);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6} sx={{ mt: 2 }}>
                            <FormControl fullWidth>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DesktopDatePicker
                                  label={"End date"}
                                  inputFormat="MM/DD/YYYY"
                                  value={work?.todate}
                                  onChange={(e) => {
                                    const arrCopy = [...workHistory];
                                    let updateWorkHistory = workHistory[index];
                                    let copy = { ...updateWorkHistory };
                                    copy["todate"] = e;
                                    arrCopy[index] = copy;
                                    setArrWorkHistory(arrCopy);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} />
                                  )}
                                />
                              </LocalizationProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  );
                })}
              <Box display="flex" justifyContent="flex-end">
                {/* <Button
                  variant="text"
                  onClick={() => setEditForms(false)}
                  sx={{ mt: 2 }}>
                  {STRINGS.Cancel}
                </Button> */}

                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                  {addMemberLoading && (
                    <CircularProgress size={18} color="warning" />
                  )}
                  {!addMemberLoading && STRINGS.Update}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={tabViewMemberValue} index={1}>
        <>
          {dlResume ? (
            <object
              data={`data:application/pdf;base64,${dlResume}`}
              // type="application/pdf"
              height="600px"
              width="100%"
            ></object>
          ) : (
            <Typography
              variant="body2"
              textAlign={"center"}
              height={50}
              minHeight={200}
            >{`Resume is not uploaded`}</Typography>
          )}
        </>
      </TabPanel>
      <TabPanel value={tabViewMemberValue} index={2}>
        <Grid
          container
          xs={12}
          spacing={2}
          sx={{
            bgcolor: "background.paper",
            display: "flex",
            paddingBottom: 2,
          }}
        >
          <Grid item xs={12} sm={6} md={2}>
            <Card sx={{ boxShadow: 10 }}>
              <CardContent sx={{ ml: 1, p: 1 }}>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleVerticalTabChange}
                  aria-label="Vertical tabs example"
                  sx={{ 
                    borderColor: "divider",
                  '.MuiTabs-flexContainerVertical': {
                    alignItems: 'flex-start',
                  },
                  '.MuiTab-root': {
                    alignItems: 'flex-start',
                  } 
                }}
                >
                  <Tab label="Leave record" {...a11yProps(0)} />
                  <Tab label="Timesheet" {...a11yProps(1)} />
                  <Tab label="Billing" {...a11yProps(2)} />
                </Tabs>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Card sx={{ boxShadow: 10 }}>
              <CardContent sx={{ ml: -2, p: 2 }}>
                <TabPanel value={value} index={0}>
                  {isLeaveRecordPending ? (
                    skeleton
                  ) : (
                    <Grid
                      xs={12}
                      container
                      spacing={isMobile ? 0 : 2}
                      rowSpacing={isMobile ? 2 : 0}
                      ml={1}
                    >
                      {leaveRecords?.map((leaves, index) => {
                        return (
                          <Grid item xs={12} md={6} key={index}>
                            <Card
                              sx={{
                                boxShadow: 10,
                              }}
                            >
                              <CardContent>
                                <Grid
                                  container
                                  spacing={2}
                                  sx={{ mt: -3, mb: 3 }}
                                >
                                  <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                      {leaves.name}
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={3}
                                    justifyContent={"flex-end"}
                                    display={"flex"}
                                  >
                                    <div
                                      style={{
                                        background: leaves.color,
                                        height: 32,
                                        width: 32,
                                        borderRadius: "50%",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      {leaveSummaryIcons(leaves.name)}
                                    </div>
                                  </Grid>
                                </Grid>

                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      Credited Balance
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    justifyContent={"flex-end"}
                                    display={"flex"}
                                  >
                                    <Typography>
                                      {leaves.creditedBalance}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      Leave taken
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    justifyContent={"flex-end"}
                                    display={"flex"}
                                  >
                                    <Typography>
                                      {leaves.leavesTaken}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      Closing balance
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    justifyContent={"flex-end"}
                                    display={"flex"}
                                  >
                                    <Typography>
                                      {leaves.closingBalance}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Typography variant="body2">
                                      Currently booked
                                    </Typography>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    justifyContent={"flex-end"}
                                    display={"flex"}
                                  >
                                    <Typography>
                                      {leaves.currentlyBooked}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                      {leaveRecords?.length === 0 && (
                        <Typography
                          variant="body2"
                          sx={{
                            justifyContent: "center",
                            textAlign: "center",
                            width: "100%",
                            pt: 5,
                          }}
                        >
                          {"No Leave record"}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </TabPanel>
                <TabPanel value={value} index={1}>
                  {isMemberTimesheetLoading ? (
                    skeleton
                  ) : (
                    <>
                      {!showTimesheetDetail && (
                        <DataGrid
                          header={MemberDetailTimesheetColumns}
                          row={memberDetailTimesheetColumnsRow(
                            membersTimesheet
                          )}
                          getDetails={(id) => {
                            setTimesheetDetailId(id);
                            setShowTimesheetDetail(true);
                          }}
                          // showPaging={isPaging}
                          // page={page}
                          // defaultRowPerPageCount={timesheetPageSize}
                          // currentPage={timesheetCurrentPage}
                          rowPerPage={(row) => {
                            const payload = { zohoID: details.zoho_ID };
                            //getMemberTimesheetRecord(dispatch, payload);
                          }}
                          getPageList={(page) => {
                            const payload = { zohoID: details.zoho_ID };
                            // getMemberTimesheetRecord(dispatch, payload);
                          }}
                          sortTable={(sort, key) => {
                            const payload = { zohoID: details.zoho_ID };
                            // getMemberTimesheetRecord(dispatch, payload);
                          }}
                          search={(value) => {
                            const payload = { zohoID: details.zoho_ID };
                            //getMemberTimesheetRecord(dispatch, payload);
                          }}
                        />
                      )}
                      {showTimesheetDetail && timesheetDetailId > 0 && (
                        <MemberTimesheetDetail
                          id={timesheetDetailId}
                          back={backButtonPress}
                        />
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel value={value} index={2}>
                  {isMemberInvoiceLoading ? (
                    skeleton
                  ) : (
                    <DataGrid
                      header={memberInvoiceHeader(userAccess().client)}
                      row={memberInvoicesRow(
                        membersInvoives,
                        userAccess().client
                      )}
                      getDetails={() => null}
                      getDownload={(id) => {
                        getInvoiceDownloadingById(id, dispatch);
                      }}
                      actionList={pendingInvoiceAction}
                      // showPaging={isPaging}
                      // page={pendingPage}
                      // getPageList={getPendingPageList}
                      sortTable={(value, key) => {
                        //getPendingSortRowList(key, value, dispatch);
                      }}
                      rowPerPage={(row) => {
                        //getPendingSelectPageRowList(row, dispatch);
                      }}
                      // defaultRowPerPageCount={pendingPageSize}
                      // currentPage={pendingCurrentPage}
                      search={(value) => {
                        const searchText = value;
                        // getPendingSearchList(searchText, dispatch);
                      }}
                    />
                  )}
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </TabPanel>
    </Card>
  );
};

export default EditMemberForm;
