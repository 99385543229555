import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, CircularProgress, Grid, IconButton, Paper, Popover, Typography } from "@mui/material";
import { Country } from "country-state-city";
import { FieldArray, Form, Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reactQuillFormats, reactQuillModules } from "../../../Constants";
import FormikInput from "../../../shared/components/FormikInput";
import { AdminRole, TeamifiedRoles, userAccess } from "../../../utils/CommonFunction";
import { generateChatGptJobDescription, postJob } from "../../Hire/Hire.helper";
import GenerateJobDescriptionModal from "./GenerateJobDescriptionModal";

import _ from "lodash";
import { useAlert } from "react-alert";
import * as yup from "yup";
import { useGetJobTitlesQuery } from "../../../redux/Hire/hireApi";
import { useGetFilteredUsersQuery } from "../../../redux/User/userApi";
import { usePermissions } from "../../../utils/permission";
import SalaryMatrix from "./SalaryMatrix";

const validationSchema = yup.object({
  client: (userAccess().role === AdminRole.PatonaAdmin || userAccess().role === AdminRole.TMFAdmin) ? yup.string().required("Client is required") : yup.string().notRequired(),
  title: yup.string().required("Job title is required"),
  experienceLevel: yup.string().required("Experience level is required"),
  employeeType: yup.string().required("Employment type is required"),
  workSetupPreference: yup.string().required("Work Setup Preference is required"),
  expectedJoiningDate: yup
    .date()
    .nullable()
    .required("Expected joining date is required"),
  aboutTheRole: yup.string().required("Description is required"),
  location: yup.string().required("Location is required"),
});



const NewJobForm = ({
  jobDetails
}) => {
  const [showAiGenerateJob, setShowAiGenerateJob] = useState(false);

  const alert = useAlert()
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { allWorkableClients, isLoading, isJobAdded, jobAddUpdateError } = useSelector(
    //@ts-ignore
    (state) => state.hire
  );

  const [salaryData, setSalaryData] = useState<any>(null);

  const { Hire_ChangeClient, Hire_ChangeRecruiter } = usePermissions()


  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    setTimeout(handleClose, 5000)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const open = Boolean(anchorEl);



  const locations = useMemo(() => {
    return [{ name: "India", value: "IN" }, {
      name: "Philippines", value: "PH"
    }, {
      name: "Sri Lanka", value: "LK"
    }].concat(Country.getAllCountries()?.map((item) => {
      return { name: item.name, value: item.isoCode };
    }))
  }, [])


  const { data: recruiterList } = useGetFilteredUsersQuery({
    clientId: 999,
    pageNumber: 0,
    pageSize: 50,
    roles: TeamifiedRoles,
    disabled: false,
    searchValue: ""
  })



  const [initialValues, setInitialValues] = useState({
    clientId: userAccess().ClientId,
    client: "",
    title: "",
    experienceLevel: "",
    employeeType: "",
    workSetupPreference: "",
    expectedJoiningDate: null,
    location: "",
    aboutTheRole: "",
    otherRequirement: "",
    jobInterviewQuestions: [{
      interviewQuestionId: 0,
      question: "",
      hint: "",
      jobRequestRefId: 0
    }]
  });

  const { data: jobTitleData } = useGetJobTitlesQuery({
    titleSearch: ""

  }, {
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
  })


  const memoizedOptions = useMemo(() => {
    if (jobTitleData?.data) {
      return jobTitleData?.data.map(el => ({
        label: el.jobTitle
      }))

    }
    return []
  }, [jobTitleData])


  useEffect(() => {
    if (isLoading) {
      navigate("/hire");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  useEffect(() => {
    if (isJobAdded) {
      setLoading(false)
      alert.success('Job Added Successfully')
    }
  }, [isJobAdded])

  useEffect(() => {
    if (jobAddUpdateError) {
      setLoading(false)
      alert.error('Error adding Job')
    }
  }, [jobAddUpdateError])

  useEffect(() => {
    if (!_.isEmpty(jobDetails)) {
      setInitialValues({
        clientId: jobDetails?.clientId,
        client: "",
        title: jobDetails?.title,
        experienceLevel: jobDetails?.experienceLevel,
        employeeType: jobDetails?.employeeType,
        workSetupPreference: jobDetails?.workSetupPreference,
        expectedJoiningDate: jobDetails?.expectedJoiningDate,
        location: jobDetails?.location,
        aboutTheRole: jobDetails?.description,
        otherRequirement: jobDetails?.otherRequirement,
        jobInterviewQuestions: jobDetails?.jobInterviewQuestions
      });
    }
  }, []);

  const jobPostFormSubmit = (data) => {
    setLoading(true)
    data.expectedSalaryFrom = salaryData?.minSalary || 0
    data.expectedSalaryTo = salaryData?.maxSalary || 0
    data.client = allWorkableClients.find(el => el.id === data.client).name
    data.accountManager = ""
    postJob(data, dispatch);
  };



  return (
    <Paper style={{ padding: 16 }}>
      <Box sx={{ mt: 1 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize
          onSubmit={(data) => jobPostFormSubmit(data)}
        >
          {(formikProps) => {
            return (
              <Form>
                <Grid container spacing={2}>

                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Job title*"
                      name="title"
                      inputType="autocomplete"
                      formikProps={formikProps}
                      schemaChange
                      schemaOnChangeFun={(value) => {
                        formikProps.setFieldValue("title", value?.label || '');
                      }}
                      options={memoizedOptions}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Experience level*"
                      name="experienceLevel"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={[
                        { name: "Junior", value: 1 },
                        { name: "Mid Level", value: 2 },
                        { name: "Senior", value: 3 },
                        { name: "Lead", value: 4 },

                      ]}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Employment type*"
                      name="employeeType"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={[
                        { name: "On Contract", value: "On Contract" },
                        { name: "Permanent", value: "Permanent" },
                      ]}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Location*"
                      name="location"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={locations}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>



                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      inputType="date-picker"
                      label="Expected Joining date"
                      inputFormat="MM/DD/YYYY"
                      formikProps={formikProps}
                      name="expectedJoiningDate"
                      options={undefined}
                      disablePast={true}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Work set up preference*"
                      name="workSetupPreference"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={[
                        { name: "Hybrid", value: "Hybrid" },
                        { name: "Office", value: "Office" },
                        { name: "Remote", value: "Remote" },
                      ]}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>

                  {Hire_ChangeClient && <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Client*"
                      name="clientId"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={allWorkableClients.map((item) => {
                        return { name: item.name, value: item.id };
                      })}
                      
                      inputType="select"
                      fullWidth
                    />
                  </Grid>}
                  {Hire_ChangeRecruiter && <Grid item xs={12} sm={6}>
                    <FormikInput
                      label="Recruiter"
                      name="recruiter"
                      className="common-select-dropdown"
                      formikProps={formikProps}
                      options={recruiterList?.data?.users.map(recruiter => ({
                        name: recruiter.userName,
                        value: recruiter.userId
                      })) || []}
                      inputType="select"
                      fullWidth
                    />
                  </Grid>}
                </Grid>
                <SalaryMatrix storeSalaryData={setSalaryData} />


                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    sx={{
                      display: "flex",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      disabled={
                        !!(
                          formikProps?.values?.title === "" ||
                          formikProps?.values?.clientId === 0
                        )
                      }
                      onClick={() => {
                        const payload = {
                          jobTitle: formikProps?.values?.title,
                          clientId: formikProps?.values?.clientId,
                          expLevel: formikProps?.values?.experienceLevel,
                          employeeType: formikProps?.values?.employeeType,
                          salaryFrom: salaryData?.minSalary || 0,
                          salaryTo: salaryData?.maxSalary || 0,
                          location: formikProps?.values?.location,
                          noticePeriod: "",
                        };
                        generateChatGptJobDescription(payload, dispatch);
                        setShowAiGenerateJob(true);
                      }}
                      variant="contained"
                      sx={{ mt: 2 }}
                    >
                      {"AI Generated job description"}
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <InfoOutlinedIcon sx={{ ml: 1 }} />
                      <Typography
                        variant="caption"
                        sx={{ pl: 1, color: "#aeaeae" }}
                      >
                        {!userAccess().client
                          ? `Client and Job title is required to generate AI job description`
                          : `Job title is required to generate AI job description`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <ReactQuill
                      theme="snow"
                      id="aboutTheRole"
                      modules={reactQuillModules}
                      formats={reactQuillFormats}
                      placeholder={`Description`}
                      value={formikProps.values.aboutTheRole}
                      onChange={(e) => {
                        formikProps.setFieldValue("aboutTheRole", e);
                      }}
                      style={{
                        height: 250,
                        marginBottom: 50,
                        marginTop: 20,
                      }}
                    />
                    <Box className="formik-input-div">
                      {formikProps.errors.aboutTheRole && (
                        <span className="error">
                          {formikProps.errors.aboutTheRole}
                        </span>
                      )}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12}>
                    <FormikInput
                      label="Other requirement*"
                      name="otherRequirement"
                      formikProps={formikProps}
                      options={undefined}
                      multiline
                      fullWidth
                      rows="5"
                    />
                  </Grid>
                </Grid>


                {
                  <FieldArray name="jobInterviewQuestions" render={(arrayHelpers) => {
                    const questions = formikProps.values.jobInterviewQuestions
                    return <Grid>
                      <Grid container justifyContent={'space-between'} mb={2}>
                        <Box display={'flex'} gap={1}>

                          <Typography variant="h6">Interview Questions</Typography>
                          <Typography variant="h6"
                            aria-owns={open ? 'interview-questions-info' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handleClick}
                            
                            >
                            <InfoOutlinedIcon />
                          </Typography>

                          <Popover
                            id="interview-questions-info"
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            disableRestoreFocus
                          >
                            <Typography variant="body2" px={1}>You can add your questions and expected answer. Our recruiters will ask the questions to the candidate while interviewing.</Typography>
                          </Popover>
                        </Box>

                        <IconButton onClick={() => {
                          arrayHelpers.push({
                            interviewQuestionId: 0,
                            question: "",
                            hint: "",
                            jobRequestRefId: 0
                          })
                        }}>
                          <img
                            width={24}
                            height={24}
                            src="/images/plus-purple.svg"
                            alt="icon"
                          />
                        </IconButton>
                      </Grid>
                      {questions.map((q, index) => (
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <FormikInput
                              label="Required Question"
                              name={`jobInterviewQuestions.${index}.question`}
                              formikProps={formikProps}
                              options={undefined}
                              fullWidth

                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <FormikInput
                              label="Hint for the question"
                              name={`jobInterviewQuestions.${index}.hint`}
                              formikProps={formikProps}
                              options={undefined}
                              fullWidth
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>

                  }}></FieldArray>
                }



                <GenerateJobDescriptionModal
                  formikProps={formikProps}
                  showAiGenerateJob={showAiGenerateJob}
                  setShowAiGenerateJob={setShowAiGenerateJob}
                />

                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ mt: 3, mb: 2 }}
                >
                  <Button
                    variant="text"
                    sx={{ mt: 2 }}
                    disabled={loading}
                    onClick={() => navigate("/hire")}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    disabled={loading}
                    variant="contained"
                    sx={{ mt: 2 }}
                  >
                    {loading ? <CircularProgress color="inherit" size={20}></CircularProgress> : 'Submit'}
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Paper>
  );
};

export default NewJobForm;
