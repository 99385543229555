import { Box, Button } from "@mui/material";
import { useState } from "react";
import ImmediateActionModal from "./modal/giveFeedback/ImmediateActionModal";
import MonthlyFeedbackModal from "./modal/giveFeedback/MonthlyFeedbackModal";
import RewardModal from "./modal/giveFeedback/RewardModal";
import { userAccess } from "../../../utils/CommonFunction";
import { teamMember } from "../../../Constants";
import { usePermissions } from "../../../utils/permission";

const FeedbackButtons = ({ searchable = false, onSubmit, tabValue = 1 }) => {
  const [feedbackModalType, setFeedbackModalType] = useState("");
  const { Performance_AddReward_Feedback_Action } = usePermissions()

  const handleOnSubmit = () => {
    onSubmit();
    setFeedbackModalType("");
  };

  return (
    <>
      { Performance_AddReward_Feedback_Action && <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="outlined"
          color="primary"
          sx={{ height: 40 }}
          onClick={() => setFeedbackModalType("reward")}
        >
          Reward
        </Button>

        <Button
          variant="outlined"
          color="primary"
          sx={{ height: 40 }}
          onClick={() => setFeedbackModalType("immediateAction")}
        >
          Immediate Action
        </Button>

        <Button
          variant="outlined"
          color="primary"
          sx={{ height: 40 }}
          disabled={tabValue === 0 ? true : false}
          onClick={() => setFeedbackModalType("monthly")}
        >
          Give feedback
        </Button>
      </Box>}
      {feedbackModalType === "monthly" && (
        <MonthlyFeedbackModal
          showModal={true}
          onSubmit={handleOnSubmit}
          onCancel={() => setFeedbackModalType("")}
          searchable={searchable}
        />
      )}

      {feedbackModalType === "reward" && (
        <RewardModal
          showModal={true}
          onSubmit={handleOnSubmit}
          onCancel={() => setFeedbackModalType("")}
          searchable={searchable}
        />
      )}

      {feedbackModalType === "immediateAction" && (
        <ImmediateActionModal
          showModal={true}
          onSubmit={handleOnSubmit}
          onCancel={() => setFeedbackModalType("")}
          searchable={searchable}
        />
      )}
    </>
  );
};

export default FeedbackButtons;
