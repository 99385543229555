import { memo, useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import Layout from "../../shared/components/LayoutV2";
import "./CandiateList.scss";
import CandidateListBody from "./CandidateListBody";
import CandidateListHeader from "./CandidateListHeader";
import CandidateListStageHeader from "./CandidateListStageHeader";
import CandiateDetailsTab from "./common/CandiateDetailsTab";
import TabPanel from "./common/TabPanel";
const CandidateListV2 = () => {
  const [selectedStage, setSelectedStage] = useState<any>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const stageCandidateList = useCallback((stage) => {
    if (searchParams.get('stage') !== stage?.code?.toLowerCase() || '') {
      setSearchParams((params: any) => {
        params.set('stage', stage?.code?.toLowerCase() || '')
        return params;
      }, { replace: true })
    }
    setSelectedStage(stage);
  }, [])

  const params = useParams();

  const validTabs = [0, 1, 2]
  const urlActiveTab = parseInt(searchParams.get('activeView') || '');
  const [tabValue, setTabValue] = useState<number>(validTabs.includes(urlActiveTab) ? urlActiveTab : 0);

  useEffect(() => {
    setTabValue(validTabs.includes(urlActiveTab) ? urlActiveTab : 0)
  }, [urlActiveTab])

  const handleTabChange = (
    event: React.SyntheticEvent,
    newTabValue: number
  ) => {
    setSearchParams((params: any) => {
      params.set('activeView', newTabValue)
      return params;
    }, { replace: true })
    setTabValue(newTabValue);
  };

  return (
    <Layout
      header={<CandidateListHeader jobId={params.id} />}
      subHeader={
        <>
          <CandidateListStageHeader
            initialStage={
              selectedStage === null
                ? searchParams.get("stage")
                : selectedStage?.code
            }
            jobId={params.id}
            selectedStage={selectedStage}
            stageCandidateList={stageCandidateList}
          />
          {selectedStage?.code !== 'APPLIED' && <CandiateDetailsTab
            handleTabChange={handleTabChange}
            tabValue={tabValue}
          />}
        </>
      }
      body={
        <>
          <TabPanel tabValue={tabValue} index={0}>
            <CandidateListBody
              jobId={params.id}
              selectedStage={selectedStage}
              setSelectedStage={setSelectedStage}
              tabValue={tabValue}
            />
          </TabPanel>
          {selectedStage?.code !== 'APPLIED' && <TabPanel tabValue={tabValue} index={1}>
            <CandidateListBody
              jobId={params.id}
              selectedStage={selectedStage}
              setSelectedStage={setSelectedStage}
              tabValue={tabValue}
            />
          </TabPanel>
          }
          <TabPanel tabValue={tabValue} index={2}>
            <CandidateListBody
              jobId={params.id}
              selectedStage={selectedStage}
              setSelectedStage={setSelectedStage}
              tabValue={tabValue}
            />
          </TabPanel>
        </>
      }
    />
  );
};

export default memo(CandidateListV2);
