import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { DefaultLayout } from "../../shared/components/Layouts/Partials/defaultlayout";
import NotFound from "../../shared/components/NotFound/NotFound";

export const PrivateRoute = ({ children, allowedRoute = true }) => {
  //@ts-ignore
  const { isAuth } = useSelector((state) => state.login);

  if (!isAuth) {
    return (
      <Navigate to="/" replace state={{ from: window.location.pathname }} />
    );
  }

  return allowedRoute ? (
    <DefaultLayout>{children}</DefaultLayout>
  ) : (
    <NotFound />
  );

};
